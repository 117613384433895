















































import Vue from 'vue';
import { mapMutations } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';
import { ITextValue } from '@/types/common';
import { Nullable } from '@/utils/types';
import { IParsedVCardData, IParsedVCardDataResponse } from '@/bundles/Contact/interfaces/IVCardParsedObj';
import { convertFromVCF } from '@/bundles/Contact/helpers/vcard/parseVCF';

import VCardImportUserInfo from '@/bundles/Contact/components/vcard/VCardImportUserInfo.vue';
import VCardImportPhoneInfo from '@/bundles/Contact/components/vcard/VCardImportPhoneInfo.vue';
import VCardImportHomeInfo from '@/bundles/Contact/components/vcard/VCardImportHomeInfo.vue';
import VCardImportOfficeInfo from '@/bundles/Contact/components/vcard/VCardImportOfficeInfo.vue';

interface IVCardImportDialog {
  parsedData: Nullable<IParsedVCardData>;
  items: ITextValue<string>[];
}

export default Vue.extend({
  name: 'VCardImportDialog',

  components: {
    VCardImportOfficeInfo,
    VCardImportHomeInfo,
    VCardImportPhoneInfo,
    VCardImportUserInfo
  },

  props: {
    uploadedFile: {
      type: File,
      required: true,
    }
  },

  data: (): IVCardImportDialog => ({
    parsedData: null,
    items: [],
  }),

  mounted () {
    this.getFileData();
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    getFileData () {
      const reader = new FileReader();

      reader.onload = () => {
        convertFromVCF(reader.result as string, (error: Nullable<Error>, response: Nullable<IParsedVCardDataResponse>) => {
          if (error) {
            this.addNotification({
              message: 'Error while parsing file',
              title: 'Error',
              status: 0
            });
            this.$emit('close');
            return;
          }

          const { items, ...parsedData } = response as IParsedVCardDataResponse;

          this.items = items;
          this.parsedData = parsedData;
        });
      }

      reader.onerror = () => {
        this.addNotification({
          message: 'Error while reading file',
          title: 'Error',
          status: 0
        });
        this.$emit('close');
      }

      reader.readAsText(this.uploadedFile);
    },
  }
});
