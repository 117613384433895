<template>
  <v-card :elevation="0">
    <v-stepper v-model="e1" class="elevation-0">
      <v-stepper-header>
        <v-stepper-step
          v-if="sourceType !== 'newContact' && sourceType !== 'ContactSelect'"
          editable
          :complete="e1 > 1"
          step="1"
        >
          Search for a contact
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          v-if="sourceType !== 'company'"
          editable
          :complete="e1 > 2"
          :step="stepTwo"
        >
          Search for a company
        </v-stepper-step>
        <v-divider v-if="sourceType !== 'company'" />
        <v-stepper-step
          editable
          :complete="e1 > 3"
          :step="stepThree"
        >
          Enter Contact Information
        </v-stepper-step>
        <v-divider />
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
          v-if="sourceType !== 'newContact' && sourceType !== 'ContactSelect'"
          step="1"
        >
          <v-card class="mb-5">
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <h3>Contacts</h3>
                    <v-text-field
                      ref="searchField"
                      v-model="contactKeyword"
                      label="Search by name or email"
                      solo
                      clearable
                      hint="At least 3 characters"
                      :loading="loadingContacts"
                      :rules="[rules.required, rules.min]"
                      autocomplete="off"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="contacts.length && hasKeyword && !loadingContacts">
                  <v-col cols="12">
                    <v-list subheader three-line>
                      <v-subheader>
                        Results({{ contacts.length }} Contacts)
                      </v-subheader>
                      <v-list-item
                        v-for="(tempContact) in paginatedContacts"
                        :key="tempContact._key"
                        ripple
                        @click="toggleContact(tempContact._key)"
                      >
                        <v-list-item-action>
                          <v-checkbox
                            :value="selected.some(item => item === tempContact._key)"
                            @click.stop="toggleContact(tempContact._key)"
                          />
                        </v-list-item-action>

                        <v-list-item-avatar class="mr-2">
                          <img :src="tempContact.img || contactPlaceholder" alt="">
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ tempContact.first_name }}
                            {{ tempContact.last_name }} <span v-if="tempContact.nickname">({{ tempContact.nickname }})</span>
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="tempContact.company">
                            {{ tempContact.company.name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{ tempContact.title }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-pagination
                        v-model="currentContactsPage"
                        :length="contactsPaginationLength"
                        :total-visible="paginationTotalVisible"
                        circle
                      />
                    </v-list>
                    <div v-if="!selected.length">
                      Can't find the contact you're looking for?
                      <a @click="e1 = 2">Create a new contact</a>
                    </div>
                  </v-col>
                  <v-col v-if="roleAlert" cols="12">
                    <v-alert id="roleAlert" color="red" dark>
                      {{ roleMessage }}
                    </v-alert>
                  </v-col>
                  <v-col v-if="isShowContactRoles" cols="12">
                    <v-divider />
                    <v-select
                      v-model="role"
                      :items="contactRoles"
                      label="Contact Role"
                      filled
                      :rules="[rules.required]"
                      autocomplete="off"
                    />
                  </v-col>
                  <v-col v-if="(sourceType === 'pipeline' || sourceType === 'pipeline_primary_client' ) && selected.length" cols="12">
                    <v-divider />
                    <v-select
                      v-model="pipelineRole"
                      :items="contactRoles"
                      label="Contact Role"
                      filled
                      :rules="[rules.required]"
                      autocomplete="off"
                    />
                  </v-col>
                  <v-col v-if="sourceType === 'occupier' && selected.length" cols="12">
                    <v-divider />
                    <v-select
                      v-model="role"
                      :items="contactRoles"
                      label="Contact Role"
                      filled
                      :rules="[rules.required]"
                      autocomplete="off"
                    />
                  </v-col>
                </v-row>
                <v-row v-if="hasKeyword && !contacts.length && !loadingContacts">
                  <v-col cols="12" class="text-center">
                    No results found for <strong>{{ contactKeyword }}</strong>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn @click="e1 = 2">Create New Contact</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <v-card-actions>
              <v-spacer />
              <div v-if="selected.length">
                <v-btn
                  v-if="sourceType === 'property'"
                  color="primary"
                  :loading="saving"
                  @click="addToProperty"
                >
                  Save to Property
                </v-btn>
                <v-btn
                  v-else-if="sourceType === 'company'"
                  color="primary"
                  :loading="saving"
                  @click="addToCompany"
                >
                  Save to Company
                </v-btn>
                <v-btn
                  v-else-if="sourceType === 'availability'"
                  color="primary"
                  :loading="saving"
                  @click="addToListing"
                >
                  Save to Availability
                </v-btn>
                <v-btn
                  v-else-if="sourceType === 'pipeline' || sourceType === 'pipeline_primary_client'"
                  color="primary"
                  :loading="saving"
                  @click="addToPipeline"
                >
                  Save to Pipeline
                </v-btn>
                <v-btn
                  v-else-if="sourceType === 'occupier'"
                  color="primary"
                  :loading="saving"
                  @click="addToOccupier"
                >
                  Save to Occupier
                </v-btn>
                <v-btn
                  v-else-if="sourceType === 'comparable'"
                  color="primary"
                  :loading="saving"
                  @click="addToComparable"
                >
                  Save to Comparable
                </v-btn>
                <v-btn
                  v-else-if="sourceType === 'requirement'"
                  color="primary"
                  :loading="saving"
                  @click="addToRequirement"
                >
                  Save to Requirement
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  :loading="saving"
                  @click="saveContactHandler"
                >
                  Save <span class="hidden-xs-only">&nbsp;Contact</span>
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content :step="stepTwo">
          <v-card v-if="sourceType !== 'company'">
            <v-form ref="companyForm" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <h3>Companies</h3>
                  <v-text-field
                    v-model="companyKeyword"
                    label="Search by company name or url"
                    solo
                    clearable
                    :loading="loadingCompanies"
                    hint="At least 3 characters"
                    :rules="[rules.min]"
                    autocomplete="off"
                  />
                </v-col>
              </v-row>
              <v-row v-if="companies.length && hasCompanyKeyword && !loadingCompanies">
                <v-col cols="12">
                  <v-list subheader two-line>
                    <v-subheader>
                      Results({{ companies.length }} Companies)
                    </v-subheader>
                    <v-list-item
                      v-for="company in paginatedCompanies"
                      :key="company._key"
                    >
                      <v-list-item-avatar>
                        <img :src="company.logo || $placeholderCompanyImg" alt="">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ company.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ company.address.street }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn class="primary" @click="addContactWithCompany(company)">Select</v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-pagination
                      v-model="currentCompaniesPage"
                      :length="companiesPaginationLength"
                      :total-visible="paginationTotalVisible"
                      circle
                    />
                  </v-list>
                </v-col>
              </v-row>
              <v-row v-if="hasCompanyKeyword && !companies.length && !loadingCompanies ">
                <v-col cols="12" class="text-center">
                  No results found for <strong>{{ companyKeyword }}</strong>
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-btn @click="companyDialog = true">Create New Company</v-btn>
                </v-col>
              </v-row>
              <v-row v-else-if="hasCompanyKeyword && !loadingCompanies">
                <v-col cols="12" class="text-center">
                  Not finding the company you are looking for?
                  <a @click="companyDialog = true">Create a new one.</a>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="skipAddContact">Skip</v-btn>
                <div v-if="selectedCompany">
                  <v-btn
                    v-if="sourceType !== 'company'"
                    color="primary"
                    @click="addContactWithCompany"
                  >
                    Next
                  </v-btn>
                </div>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>

        <!-- CONTACT FORM -->
        <v-stepper-content :step="stepThree" class="px-0">
          <v-card flat>
            <v-form ref="contactForm" lazy-validation>
              <v-container v-if="e1 === stepThree" grid-list-lg class="px-4">
                <div class="mb-3">
                  <div class="d-flex align-center justify-space-between">
                    <ImportFromVCard
                      class="d-inline-block mr-3"
                      @parsingFinished="onVCardParsingFinished"
                    />
                    <Upload
                      v-model="uploadPhotoDialog"
                      :options="uploadOptions"
                      source-type="contacts"
                      @fileUploaded="uploadSuccess"
                    >
                      <v-btn @click="uploadPhotoDialog = true">Upload Photo</v-btn>
                    </Upload>
                  </div>
                  <v-img
                    v-if="contact.img"
                    :src="contact.img"
                    height="200px"
                    class="mt-4"
                    contain
                    aspect-ratio="1"
                  />
                </div>
                <div>
                  <v-row v-if="contactCompany">
                    <v-col cols="12">
                      <h3>
                        <v-avatar>
                          <img :src="contactCompany.logo" alt="">
                        </v-avatar>
                        {{ contactCompany.name }}
                      </h3>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="contact.first_name"
                        label="First Name"
                        :rules="defaultRules"
                        required
                        autocomplete="off"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="contact.last_name"
                        label="Last Name"
                        :rules="defaultRules"
                        required
                        autocomplete="off"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="contact.nickname"
                        label="Nickname"
                        autocomplete="off"
                      />
                    </v-col>
                    <v-col v-if="source === 'company'" cols="12">
                      <!-- eslint-disable -->
                      <v-text-field
                        v-model="source.name"
                        label="Company Name"
                        autocomplete="off"
                        disabled
                      />
                      <!-- eslint-enable -->
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="contact.title"
                        label="Title"
                        autocomplete="off"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <CommonPhonePicker
                        show-extension
                        :phone-country-code="contact.phone.direct_country_code"
                        :phone-number="contact.phone.direct"
                        :phone-extension="contact.phone.direct_ext"
                        @save:number="contact.phone.direct = $event"
                        @save:countryCode="contact.phone.direct_country_code = $event"
                        @save:extension="contact.phone.direct_ext = $event"
                      >
                        <template #default="{ on, attrs }">
                          <v-text-field
                            v-model="contact.phone.direct"
                            v-facade="getCountryMask(contact.phone.direct_country_code)"
                            label="Direct Phone"
                            :suffix="contact.phone.direct_ext ? `ext: ${contact.phone.direct_ext}` : null"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                      </CommonPhonePicker>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <CommonPhonePicker
                        show-extension
                        :phone-country-code="contact.phone.mobile_country_code"
                        :phone-number="contact.phone.mobile"
                        :phone-extension="contact.phone.mobile_ext"
                        @save:number="contact.phone.mobile = $event"
                        @save:countryCode="contact.phone.mobile_country_code = $event"
                        @save:extension="contact.phone.mobile_ext = $event"
                      >
                        <template #default="{ on, attrs }">
                          <v-text-field
                            v-model="contact.phone.mobile"
                            v-facade="getCountryMask(contact.phone.mobile_country_code)"
                            label="Mobile Phone"
                            :suffix="contact.phone.mobile_ext ? `ext: ${contact.phone.mobile_ext}` : contact.phone.mobile_ext"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                      </CommonPhonePicker>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <CommonPhonePicker
                        show-extension
                        :phone-country-code="contact.phone.office_country_code"
                        :phone-number="contact.phone.office"
                        :phone-extension="contact.phone.office_ext"
                        @save:number="contact.phone.office = $event"
                        @save:countryCode="contact.phone.office_country_code = $event"
                        @save:extension="contact.phone.office_ext = $event"
                      >
                        <template #default="{ on, attrs }">
                          <v-text-field
                            v-model="contact.phone.office"
                            v-facade="getCountryMask(contact.phone.office_country_code)"
                            :disabled="contact.office_address_same_as_company"
                            label="Office Phone"
                            :suffix="contact.phone.office_ext ? `ext: ${contact.phone.office_ext}` : contact.phone.office_ext"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                      </CommonPhonePicker>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <CommonPhonePicker
                        show-extension
                        :phone-country-code="contact.phone.fax_country_code"
                        :phone-number="contact.phone.fax"
                        :phone-extension="contact.phone.fax_ext"
                        @save:number="contact.phone.fax = $event"
                        @save:countryCode="contact.phone.fax_country_code = $event"
                        @save:extension="contact.phone.fax_ext = $event"
                      >
                        <template #default="{ on, attrs }">
                          <v-text-field
                            v-model="contact.phone.fax"
                            v-facade="getCountryMask(contact.phone.fax_country_code)"
                            label="Fax"
                            v-bind="attrs"
                            :suffix="contact.phone.fax_ext ? `ext: ${contact.phone.fax_ext}` : contact.phone.fax_ext"
                            :disabled="contact.office_address_same_as_company"
                            v-on="on"
                          />
                        </template>
                      </CommonPhonePicker>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        ref="contactEmail"
                        v-model="contact.email"
                        label="Email Address"
                        autocomplete="off"
                      />
                    </v-col>
                    <v-col cols="12" class="contact-label-container">
                      <v-label>Office Address</v-label>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="contact.office_address_same_as_company"
                        label="Office address same as company address"
                        :disabled="!contactCompany"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-row class="pt-0">
                        <v-col cols="12" class="pt-0">
                          <v-text-field
                            v-model="contact.office_address.street"
                            class="pt-0"
                            :disabled="contact.office_address_same_as_company"
                            label="Address"
                            autocomplete="off"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="pt-0">
                        <v-col cols="12" class="pt-0">
                          <v-text-field
                            v-model="contact.office_address.street2"
                            class="pt-0"
                            :disabled="contact.office_address_same_as_company"
                            label="Address 2"
                            autocomplete="off"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            v-model="contact.office_address.city"
                            :disabled="contact.office_address_same_as_company"
                            label="City"
                            autocomplete="off"
                          />
                        </v-col>
                        <v-col cols="12" sm="5">
                          <StateSelect
                            v-model="contact.office_address.state"
                            :country="contact.office_address.country"
                            :disabled="contact.office_address_same_as_company"
                          />
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field
                            v-model="contact.office_address.postal"
                            :disabled="contact.office_address_same_as_company"
                            label="Postal"
                            autocomplete="off"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="contact.office_address.country"
                            :disabled="contact.office_address_same_as_company"
                            :items="countries"
                            label="Country"
                            item-text="name"
                            item-value="code"
                            @change="contact.office_address.state = null;"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="contact-label-container">
                      <v-label>Home Contact Information</v-label>
                    </v-col>
                    <v-col cols="12">
                      <v-row class="pt-0">
                        <v-col cols="12" class="pt-0">
                          <v-text-field
                            v-model="contact.home_address.street"
                            class="pt-0"
                            label="Address"
                            autocomplete="off"
                          />
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            v-model="contact.home_address.city"
                            label="City"
                            autocomplete="off"
                          />
                        </v-col>
                        <v-col cols="12" sm="5">
                          <StateSelect
                            v-model="contact.home_address.state"
                            :country="contact.home_address.country"
                          />
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field
                            v-model="contact.home_address.postal"
                            label="Postal"
                            autocomplete="off"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="contact.home_address.country"
                            :items="countries"
                            label="Country"
                            item-text="name"
                            item-value="code"
                            @change="contact.home_address.state = null;"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <CommonPhonePicker
                        :phone-number="contact.home_phone"
                        @save:number="contact.home_phone = $event"
                      >
                        <template #default="{ on, attrs }">
                          <v-text-field
                            v-model="contact.home_phone"
                            v-facade="getCountryMask(null)"
                            label="Home Phone"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                      </CommonPhonePicker>
                    </v-col>
                    <v-col v-if="isShowContactRolesContactSelect" cols="12">
                      <v-divider />
                      <v-select
                        v-model="role"
                        :items="contactRoles"
                        label="Contact Role"
                        :rules="[rules.required]"
                        autocomplete="off"
                      />
                    </v-col>
                    <v-col cols="12 pa-0">
                      <AppPrivacy v-model="contact.permissions" />
                    </v-col>
                  </v-row>
                </div>
              </v-container>

              <v-card-actions class="sc-navigation-drawer-actions">
                <v-btn :small="$vuetify.breakpoint.smAndDown" text @click="goBack">Back</v-btn>
                <v-spacer />
                <v-btn :small="$vuetify.breakpoint.smAndDown" text @click="cancelContact">Cancel</v-btn>
                <v-btn
                  :small="$vuetify.breakpoint.smAndDown"
                  color="primary"
                  :loading="saving"
                  class="px-4"
                  @click="saveContactHandler"
                >
                  Save
                  <span v-if="sourceType !== 'newContact' && sourceType !== 'ContactSelect'" class="hidden-xs-only">
                    &nbsp; & Add to <span class="text-uppercase"> {{ sourceType }}</span>
                  </span>
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-navigation-drawer
      v-model="duplicateContactDialog"
      class="task-form-drawer"
      :width="600"
      light
      right
      app
      temporary
      touchless
    >
      <v-card flat>
        <v-toolbar tile dark class="panel-header mb-3">
          <v-toolbar-title>We found a match</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div class="mb-5">The email address, {{ dupContact.email }}, already exists</div>
          <ContactCard
            :item="dupContact"
            :show-company="true"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="primary" @click="handleDuplicateFormSubmit(dupContact)">Add Contact</v-btn>
          <v-btn text class="cancel" @click="cancelDuplicateContact">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="companyDialog"
      class="task-form-drawer sc-navigation-drawer"
      :width="600"
      light
      right
      app
      temporary
      touchless
    >
      <v-card>
        <CompanyForm
          source-type="newContact"
          @companyAdded="useCompany"
          @closeDialog="closeCompany"
          @cancelCompany="closeCompany"
        />
      </v-card>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { ServiceFactory } from '@/services/ServiceFactory';
import { mapGetters, mapState } from 'vuex';
import { debounce } from 'lodash';
import { ContactRolesEnum, getContactRoles, arrToSnakeCase } from '@/bundles/Contact/enums/contactRoles';
import { getDefaultContact } from '@/bundles/Contact/helpers/getDefaultContact';
import { DefaultPermissionsMixin } from '@/bundles/App/mixins/DefaultPermissionsMixin';
import { contactPipelinePrimaryClientRoles, getPipelineContactRoles } from '@/bundles/Contact/helpers/cards/contactsCard';
import { AppFormMixin } from '@/bundles/App/mixins/forms/AppFormMixin';
import { CountriesMasksMixin } from '@/bundles/Common/mixins/CountriesMasksMixin';

import CompanyForm from '../companies/CompanyForm';
import ImportFromVCard from '@/components/common-components/ImportFromVCard';
import ContactCard from '@/components/common-components/recordCards/ContactCard';
import AppPrivacy from '@/bundles/App/components/AppPrivacy';
import Upload from '@/bundles/Upload/components/Upload';
import CommonPhonePicker from '@/bundles/Common/components/CommonPhonePicker.vue';
import StateSelect from '@/bundles/App/components/inputs/StateSelect.vue';

const ContactService = ServiceFactory.get('contact');
const CompanyService = ServiceFactory.get('company');
const PropertyService = ServiceFactory.get('property');
const AvailabilityService = ServiceFactory.get('availability');
const OccupierService = ServiceFactory.get('occupier');
const RequirementService = ServiceFactory.get('requirement');
const PipelineService = ServiceFactory.get('pipeline');
const ComparableService = ServiceFactory.get('comparable');

const getRecordServiceBySourceType = (sourceType) => {
  return ServiceFactory.get(sourceType);
}

export default {
  name: 'ContactAddStepper',

  components: {
    StateSelect,
    CommonPhonePicker,
    Upload,
    CompanyForm,
    ImportFromVCard,
    ContactCard,
    AppPrivacy
  },

  mixins: [DefaultPermissionsMixin, AppFormMixin, CountriesMasksMixin],

  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
    sourceType: {
      type: String,
      default: 'newContact',
    },
    sourceId: {
      type: [String, Number],
      default: '',
    },
    hasRoles: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    contact: getDefaultContact(),
    companyDialog: false,
    e1: 1,
    loadingContacts: false,
    contactKeyword: '',
    contacts: [],
    selected: [],
    loadingCompanies: false,
    contactCompany: '',
    companies: [],
    companyKeyword: '',
    selectedCompany: '',
    pipelineRole: ContactRolesEnum.ultimate_decision_maker,
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => (v && v.length >= 3) || 'Min 3 characters',
    },
    duplicateContactDialog: false,
    dupContact: {},
    defaultRules: [(v) => !!v || 'Item is required'],
    saving: false,
    roleAlert: false,
    roleMessage: '',
    currentContactsPage: 1,
    currentCompaniesPage: 1,
    paginationSize: 5,
    paginationTotalVisible: 7,
    role: null,
    uploadPhotoDialog: false,
    sourceRecordService: null
  }),

  computed: {
    ...mapState({
      token: (state) => state.idToken,
    }),
    ...mapGetters('Admin', {
      getListByCategory: 'getListByCategory'
    }),
    ...mapGetters(['countries']),
    isShowContactRoles: function () {
      const arr = ['property', 'newContact', 'availability', 'company', 'comparable', 'requirement'];

      return arr.some(type => type === this.sourceType) && this.selected.length;
    },
    isShowContactRolesContactSelect: function () {
      const arr = ['property', 'availability', 'company', 'newContact', 'occupier', 'comparable', 'pipeline', 'pipeline_primary_client', 'requirement'];

      return arr.some(type => type === this.sourceType);
    },
    contactRoles () {
      const sourcesArr = ['company', 'property', 'pipeline', 'occupier', 'availability', 'comparable', 'deal', 'newContact', 'requirement', 'pipeline_primary_client'];
      const sourcesArrContactSelect = [{ 'newContact': 'company', pipeline_primary_client: 'pipeline' }];

      if (!sourcesArr.some(source => source === this.sourceType)) {
        return [];
      }

      const differentSourceType = sourcesArrContactSelect.find(source => source[this.sourceType]);
      const source = differentSourceType?.[this.sourceType] || this.sourceType;
      const roles = arrToSnakeCase(this.getListByCategory(`${source}_roles`)?.items || []);

      if (this.sourceType === 'pipeline') {
        return getPipelineContactRoles(roles);
      }

      if (this.sourceType === 'pipeline_primary_client') {
        return getContactRoles(contactPipelinePrimaryClientRoles);
      }

      return getContactRoles(roles);
    },
    stepTwo () {
      if (
        this.sourceType === 'newContact' ||
        this.sourceType === 'ContactSelect'
      ) {
        return 1;
      } else {
        return 2;
      }
    },
    stepThree () {
      if (
        this.sourceType === 'newContact' ||
        this.sourceType === 'company' ||
        this.sourceType === 'ContactSelect'
      ) {
        return 2;
      } else {
        return 3;
      }
    },
    hasKeyword () {
      return this.contactKeyword?.length >= 3;
    },
    hasCompanyKeyword () {
      return this.companyKeyword?.length >= 3;
    },
    officePhone: {
      get () {
        if (this.contact.phone.office !== '') {
          return this.contact.phone.office;
        } else if (this.sourceType === 'company') {
          this.setOfficePhone(this.source.phone.office);
          return this.source.phone.office;
        } else if (this.sourceType === 'property' && this.contactCompany._key) {
          this.setOfficePhone(this.contactCompany.phone.office);
          return this.contactCompany.phone.office;
        }
        return '';
      },
      set (value) {
        this.contact.phone.office = value;
      },
    },
    contactsPaginationLength () {
      return Math.ceil(this.contacts.length / this.paginationSize);
    },
    companiesPaginationLength () {
      return Math.ceil(this.companies.length / this.paginationSize);
    },
    paginatedContacts () {
      const startPaginationIndex = (this.currentContactsPage - 1) * this.paginationSize;
      const endPaginationIndex = this.currentContactsPage * this.paginationSize;
      return this.contacts.slice(startPaginationIndex, endPaginationIndex);
    },
    paginatedCompanies () {
      const startPaginationIndex = (this.currentCompaniesPage - 1) * this.paginationSize;
      const endPaginationIndex = this.currentCompaniesPage * this.paginationSize;
      return this.companies.slice(startPaginationIndex, endPaginationIndex);
    },
    uploadOptions () {
      return { accept: 'image/*', aspectRatio: 1 };
    },
    selectedRole () {
      return this.role ? [this.role] : [];
    },
  },

  watch: {
    dupContact (newValue) {
      if (newValue._key) {
        this.duplicateContactDialog = true;
      }
    },
    contactKeyword (value) {
      if (value?.length >= 3) {
        this.searchHandlerDebounced(this, 'searchContacts');
        return;
      }

      this.selected = [];
    },
    companyKeyword (value) {
      if (value?.length >= 3) {
        this.searchHandlerDebounced(this, 'searchCompanies');
      }
    },
    selectedCompany (value) {
      if (value === null) {
        this.selectedCompany = {};
      }
    },
    sourceType: {
      immediate: true,
      handler (newValue) {
        this.checkType();
        this.setDefaultPipelineRole();

        if (newValue === 'ContactSelect' || newValue === 'newContact') {
          return;
        }

        this.sourceRecordService = getRecordServiceBySourceType(newValue);
      }
    },
    'contact.office_address_same_as_company' (newVal) {
      if (newVal && this.contactCompany) {
        const { city, postal, state, street, street2, timezone_name, country } = this.contactCompany.address;
        const { office, office_country_code, fax, fax_country_code } = this.contactCompany.phone;

        this.contact.office_address = { city, postal, state, street, street2, country, timezone_name };
        this.contact.phone = {
          ...this.contact.phone,
          office,
          office_country_code,
          fax,
          fax_country_code,
        };
      }
    },
    source: {
      handler (newVal) {
        this.contactCompany = newVal;
      },
      deep: true,
    },
    'contactCompany' (value) {
      if (value) {
        this.role = value.isBrokerage ? ContactRolesEnum.agent : ContactRolesEnum.employee;
      }
    },
  },

  mounted () {
    this.setDefaultRole();
    this.contact.permissions = this.defaultPermissions;

    setTimeout(() => { // nextTick doesn't work here
      this.$refs.searchField?.focus();
    }, 100);
  },

  created () {
    if (this.sourceType === 'company') {
      this.setCompany();
    }
  },

  methods: {
    searchHandlerDebounced: debounce(function (vm, eventName) {
      vm[eventName]();
    }, 400),
    cancelDuplicateContact () {
      this.duplicateContactDialog = false;
      this.dupContact = {};
      this.contact.email = '';
      this.$refs.contactEmail.focus();
    },
    goBack () {
      this.selectedCompany = '';
      this.e1 = 1;
      this.contactCompany = '';
    },
    closeCompany () {
      this.companyDialog = false;
    },
    useCompany (value) {
      this.companyDialog = false;
      this.addContactWithCompany(value);
    },
    setOfficePhone (value) {
      this.contact.phone.office = value;
    },
    handleDuplicateFormSubmit: async function (contact) {
      if (!contact || !this.sourceRecordService) {
        return;
      }

      try {
        this.contactAddLoading = true;
        await this.sourceRecordService.addContact({
          payload: {
            contacts: [contact._key],
            roles: this.selectedRole,
          },
          token: this.token,
          id: this.sourceId,
        });

        this.$emit('contactAdded', contact);
        this.resetContactForm();
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.contactAddLoading = false;
      }
    },
    saveContactHandler: async function () {
      if (!this.$refs.contactForm.validate()) {
        this.showFormValidationErrorNotification();
        return;
      }

      this.saving = true;

      try {
        const submitDate = Date.now();
        const payload = {
          date_insert: submitDate,
          date_update: submitDate,
          first_name: this.contact.first_name,
          last_name: this.contact.last_name,
          nickname: this.contact.nickname,
          email: this.contact.email,
          title: this.contact.title,
          img: this.contact.img,
          permissions: this.contact.permissions,
          company: this.selectedCompany._key,
          phone: { ...this.contact.phone },
          home_address: this.contact.home_address,
          office_address: this.contact.office_address,
          home_phone: this.contact.home_phone,
          office_address_same_as_company: this.contact.office_address_same_as_company,
        };

        const shouldUseSelectedRole = this.sourceType === 'company' || this.sourceType === 'newContact' || this.sourceType === 'ContactSelect';

        payload.roles = this.selectedRole.length && shouldUseSelectedRole ? this.selectedRole : [ContactRolesEnum.employee];

        const { data } = await ContactService.create({
          payload,
          token: this.token,
        });

        if (this.sourceType === 'newContact' || this.sourceType === 'ContactSelect') {
          this.$emit('contactAdded', data);
          this.cancelContact();
          return;
        }

        if (data.duplicate) {
          this.dupContact = data.contact;
          return;
        }

        if (!this.sourceRecordService) {
          return;
        }

        await this.sourceRecordService.addContact({
          payload: {
            contacts: [data._key],
            roles: this.selectedRole,
          },
          token: this.token,
          id: this.sourceId,
        });
        this.$emit('contactAdded', data);
        this.resetContactForm();
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.saving = false;
      }
    },
    cancelContact () {
      this.$emit('cancelContact');
      this.resetContactForm();
    },
    addContactWithCompany (company) {
      this.contactCompany = company;
      this.selectedCompany = company;
      this.e1 = this.stepThree;
      this.contact.office_address_same_as_company = true;
    },
    skipAddContact: function () {
      this.e1 = this.stepThree;
    },
    searchContacts: async function () {
      this.currentContactsPage = 1;
      try {
        this.loadingContacts = true;
        const params = [
          { name: 'keyword', value: this.contactKeyword },
          { name: 'pageOffset', value: 0 },
          { name: 'pageLimit', value: 50 },
          { name: 'sort', value: 'last_name' },
        ];

        const { data } = await ContactService.list({ token: this.token, params });

        this.contacts = data.result;
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.loadingContacts = false;
      }
    },
    uploadSuccess ({ files }) {
      this.contact.img = files[0].url;
    },
    async searchCompanies () {
      this.currentCompaniesPage = 1;
      try {
        this.loadingCompanies = true;
        const params = [
          { name: 'keyword', value: this.companyKeyword },
          { name: 'pageLimit', value: 50 }
        ];

        const { data } = await CompanyService.list({ token: this.token, params });
        const { count, result: companies } = data;

        this.count = count;
        this.companies = companies;
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.loadingCompanies = false;
      }
    },
    setCompany () {
      this.contactCompany = this.source;
    },
    async addToCompany () {
      if (!this.$refs.form.validate()) {
        this.showFormValidationErrorNotification();
        return;
      }

      const payload = {
        contacts: this.selected,
        roles: this.selectedRole,
      };

      try {
        this.saving = true;

        const { data } = await CompanyService.addContact({
          payload,
          token: this.token,
          id: this.sourceId,
        });
        this.$refs.form.reset();
        this.$emit('contactAdded');
        this.e1 = 1;
        this.contactKeyword = '';
        this.contacts = [];
        return data;
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.saving = false;
      }
    },
    async addToPipeline () {
      if (!this.$refs.form.validate()) {
        this.showFormValidationErrorNotification();
        return;
      }

      const payload = {
        contacts: this.selected,
        roles: [this.pipelineRole],
      };

      try {
        this.saving = true;

        await PipelineService.addContact({
          id: this.sourceId,
          payload,
          token: this.token,
        });

        this.$emit('contactAdded');
        this.$refs.form.reset();
        this.e1 = 1;
        this.contactKeyword = '';
        this.contacts = [];
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.saving = false;
      }
    },
    async addToProperty () {
      if (!this.$refs.form.validate()) {
        this.showFormValidationErrorNotification();
        return;
      }

      const payload = {
        contacts: this.selected,
        roles: this.selectedRole,
      };

      try {
        this.saving = true;
        await PropertyService.addContact({
          id: this.sourceId,
          payload,
          token: this.token,
        });
        this.resetContactForm();
        this.$emit('contactAdded');
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.saving = false;
      }
    },
    async addToListing () {
      if (!this.$refs.form.validate()) {
        this.showFormValidationErrorNotification();
        return;
      }

      const payload = {
        contacts: this.selected,
        roles: this.selectedRole,
      };

      try {
        this.saving = true;

        await AvailabilityService.addContact({
          id: this.sourceId,
          payload,
          token: this.token,
        });
        this.e1 = 1;
        this.contactKeyword = '';
        this.contacts = [];
        this.$emit('contactAdded');
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.saving = false;
      }
    },

    async addToOccupier () {
      if (this.role === ContactRolesEnum.employee) {
        this.roleAlert = true;
        this.roleMessage = 'Please select a role';
      }

      if (!this.$refs.form.validate()) {
        this.showFormValidationErrorNotification();
        return;
      }

      if (this.role !== ContactRolesEnum.employee) {
        const payload = {
          contacts: this.selected,
          roles: this.selectedRole,
        };

        try {
          this.saving = true;

          await OccupierService.addContact({
            id: this.sourceId,
            token: this.token,
            payload,
          });
          this.e1 = 1;
          this.contactKeyword = '';
          this.contacts = [];
          this.$emit('contactAdded');
        } catch (error) {
          this.addNotification({ ...error });
        } finally {
          this.saving = false;
        }
      }
    },
    async addToComparable () {
      if (!this.$refs.form.validate()) {
        this.showFormValidationErrorNotification();
        return;
      }

      const payload = {
        contacts: this.selected,
        role: this.selectedRole[0],
      };

      try {
        this.saving = true;

        await ComparableService.addContact({
          id: this.sourceId,
          payload,
          token: this.token,
        });

        this.$refs.form.reset();
        this.$emit('contactAdded');
        this.e1 = 1;
        this.contactKeyword = '';
        this.contacts = [];
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.saving = false;
      }
    },
    async addToRequirement () {
      if (!this.$refs.form.validate()) {
        this.showFormValidationErrorNotification();
        return;
      }

      this.saving = true;

      try {
        await RequirementService.addContact({
          payload: {
            contacts: this.selected,
            roles: this.selectedRole,
          },
          token: this.token,
          id: this.sourceId,
        });

        this.$refs.form.reset();
        this.$emit('contactAdded');
        this.e1 = 1;
        this.contactKeyword = '';
        this.contacts = [];
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.saving = false;
      }
    },
    checkType () {
      if (this.sourceType === 'newContact' && this.e1 === 1) {
        this.e1 = 2;
      }
    },
    setDefaultPipelineRole () {
      this.pipelineRole = this.sourceType === 'pipeline_primary_client' ? ContactRolesEnum.ultimate_decision_maker : ContactRolesEnum.seller_broker;
    },
    resetContactForm () {
      this.e1 = 1;
      this.contactKeyword = '';
      this.companyKeyword = '';
      this.selectedCompany = '';
      this.contactCompany = '';
      this.companies = [];
      this.selected = [];
      this.contacts = [];
      this.setDefaultRole();
      this.setDefaultPipelineRole();
      this.contact = getDefaultContact();
      this.contact.permissions = this.defaultPermissions;
    },
    onVCardParsingFinished (parsedData) {
      this.contact = Object.assign(this.contact, parsedData);
    },
    toggleContact: function (key) {
      const selectedIndex = this.selected.indexOf(key);

      selectedIndex === -1 ? this.selected.push(key) : this.selected.splice(selectedIndex, 1);
    },
    setDefaultRole: function () {
      switch (this.sourceType) {
        case 'property':
        case 'availability':
        case 'requirement':
          this.role = ContactRolesEnum.listing_agent;
          break;
        case 'occupier':
          this.role = ContactRolesEnum.procuring_broker;
          break;
        case 'company':
          this.role = this.source.isBrokerage ? ContactRolesEnum.agent : ContactRolesEnum.employee;
          break;
        case 'newContact':
          this.role = ContactRolesEnum.employee;
          break;
        default:
          this.role = '';
      };
    },
  },
};
</script>
