import { Nullable } from '@/utils/types';
import Vue from 'vue';

export const RequestAbortMixin = Vue.extend({
  data: (): { abortController: Nullable<AbortController> } => ({
    abortController: null,
  }),

  methods: {
    handleRequestAbort: function () {
      if (!this.abortController) {
        this.abortController = new AbortController();
        return;
      }

      this.abortController.abort();
      this.abortController = new AbortController();
    },
  },
});
