import {
  FilterAllExcludeFilterEnum,
  FilterAllExcludeFilterLabels
} from '@/bundles/Filters/enums/transactionType/FilterAllExcludeFilterEnum';

export const FilterConsultingButtonItems = [
  {
    text: FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.exclude],
    value: FilterAllExcludeFilterEnum.exclude,
    btnText: `Exclude \n Consulting`,
  },
  {
    text: FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.all],
    value: FilterAllExcludeFilterEnum.all,
  },
  {
    text: FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.filter],
    value: FilterAllExcludeFilterEnum.filter,
    btnText: `Consulting \n Only`,
  }
];
