import {
  FilterAllExcludeFilterEnum,
  FilterAllExcludeFilterLabels
} from '@/bundles/Filters/enums/transactionType/FilterAllExcludeFilterEnum';

export const FilterInvestmentSaleButtonItems = [
  {
    text: FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.exclude],
    value: FilterAllExcludeFilterEnum.exclude,
    btnText: `Exclude \n Investment`,
  },
  {
    text: FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.all],
    value: FilterAllExcludeFilterEnum.all,
  },
  {
    text: FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.filter],
    value: FilterAllExcludeFilterEnum.filter,
    btnText: `Investment \n Only`,
  }
];
