import Vue from 'vue';
import { mapState } from 'vuex';
import { IPermissions } from '../interfaces/IPermissions';

export const DefaultPermissionsMixin = Vue.extend({
  computed: {
    ...mapState({
      defaultPermissions: (state: any): IPermissions => state.accountSettings.permissions
    })
  }
});
