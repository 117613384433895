import { httpV2 } from '@/bundles/Http/factory/httpFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import AddressService from '@/bundles/Address/services/addressService';

function addressServiceFactory () {
  return new AddressService({
    transport: httpV2,
    errorMapper: new ErrorMapper(),
  });
}

export const addressService = addressServiceFactory();
