import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import AccountToMapper from '@/bundles/Account/mappers/AccountToMapper';
import Account from '@/bundles/Account/models/Account';
import UpdateAccountDto from '@/bundles/Account/dto/update-account';
import { Nullable } from '@/utils/types';
import { IGetAccountListItem } from '@/bundles/Account/types';
import AccountPreferences from '@/bundles/Account/models/AccountPreferences';

export default class AccountService {
  private readonly transport: HttpTransport;
  private readonly errorMapper: ErrorMapper;
  private readonly accountMapper: AccountToMapper;

  constructor ({
    transport,
    errorMapper,
    accountMapper,
  }: { transport: HttpTransport; errorMapper: ErrorMapper; accountMapper: AccountToMapper }) {
    this.transport = transport;
    this.errorMapper = errorMapper;
    this.accountMapper = accountMapper;
  }

  async getOne (id: number): Promise<Account> {
    try {
      const response = await this.transport.get(`/account/${ id }`);
      return this.accountMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async update (id: number, payload: UpdateAccountDto): Promise<Account> {
    try {
      const response = await this.transport.put(
        `/account/${ id }`,
        payload,
      );
      return this.accountMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async updateEmailDetails (id: number, payload : { email_sender: string|null; email_footer: string|null; }): Promise<Account> {
    try {
      const response = await this.transport.patch(
        `/account/${ id }/email-details`,
        payload,
      );
      return this.accountMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async updateLogo (id: number, payload : {
    logo?: Nullable<string>;
    square_logo?: Nullable<string>;
    alt_text?: Nullable<string>;
    email_logo?: Nullable<string>;
    watermark?: Nullable<string>;
    public_logo?: Nullable<string>;
    contact_placeholder?: Nullable<string>;
  }): Promise<Account> {
    try {
      const response = await this.transport.patch(
        `/account/${ id }/logo`,
        payload,
      );
      return this.accountMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async getAllAccounts (): Promise<IGetAccountListItem[]> {
    try {
      return await this.transport.get('/account/accounts-list');
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async getPreferences (): Promise<AccountPreferences> {
    try {
      const response = await this.transport.get(`/account/preferences`);

      return new AccountPreferences(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
