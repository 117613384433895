





























































































import { PropType } from 'vue';
import mixins from 'vue-typed-mixins';
import { IHeader, ITableOptions } from '@/bundles/BaseTable/interfaces';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';

import Label from '@/bundles/Common/components/Label.vue';
import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';
import OpenInvoiceMobileCard from '@/bundles/Dashboard/components/OpenInvoiceMobileCard.vue';

interface IOpenInvoicesTableData {
  headers?: IHeader[];
}

const companyNameSortFunc = (a: { name: string }[], b: { name: string }[]) => {
  const aJoin = a.map(item => item.name).join(', ');
  const bJoin = b.map(item => item.name).join(', ');

  if (aJoin && bJoin) {
    return aJoin.localeCompare(bJoin);
  }

  return 0;
};

const headers = [
  { text: '#', value: '#', sortable: false },
  { text: 'Invoice No.', value: 'invoice_number', sortable: true, class: '', icon: 'number' },
  { text: 'Deal Name', value: 'deal.name', sortable: true, class: '', icon: 'string' },
  { text: 'Broker(s)', value: 'broker', sortable: false, class: '', icon: 'string' },
  { text: 'Buyer/Tenant', value: 'buyer_tenant', sortable: true, class: '', icon: 'string', sort: companyNameSortFunc },
  { text: 'Seller/Landlord', value: 'seller_landlord', sortable: true, class: '', icon: 'string', sort: companyNameSortFunc },
  { text: 'Due Date', value: 'due_date', sortable: true, class: '', icon: 'date' },
  { text: 'Invoice Amount ($)', value: 'amount', sortable: true, class: '', icon: 'number' },
  { text: 'Amount Paid ($)', value: 'amount_paid', sortable: true, class: '', icon: 'number' },
  { text: 'Outstanding ($)', value: 'amount_outstanding', sortable: true, class: '', icon: 'number' },
  { text: 'Payment Status', value: 'status', sortable: true, class: '', icon: 'string' },
  { text: 'Aging', value: 'diffLabel', sortable: true, class: '', icon: 'number' },
  { text: '# Days Aged', value: 'daysDiff', sortable: true, class: '', icon: 'number' }
]

export default mixins(AccountLayoutMixin).extend({
  name: 'OpenInvoicesTable',

  components: {
    OpenInvoiceMobileCard,
    CommonChip,
    Label
  },

  props: {
    value: {
      type: Object as PropType<ITableOptions>,
      required: true
    },
    items: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    filterLoading: Boolean,
    selectedKeys: {
      type: Array as PropType<string[]>,
      default: [],
    },
    serverItemsLength: {
      type: Number,
      default: -1,
    }
  },

  data: (): IOpenInvoicesTableData => ({}),

  created () {
    this.headers = headers;
  },

  methods: {
    getCompanyNames (contacts: { name: string }[]): string {
      return contacts.map(item => item.name).join(', ') || '';
    },
    getBrokers (deal) {
      return deal.brokers.map(item => `${item.first_name} ${item.last_name}`).join(', ');
    },
    updateModel (model: Partial<ITableOptions>) {
      this.$emit('input', { ...this.value, ...model });
    },
    changeSelectHandler (key: string) {
      const selectedKeys = this.selectedKeys;

      const payload = selectedKeys.includes(key) ? selectedKeys.filter(item => item !== key) : [...selectedKeys, key];
      this.$emit('update:selectedKeys', payload);
    },
    onToggleSelectAll ({ value }: { value: boolean }) {
      const selectedKeys = value ? this.items.map(item => item._key) : [];
      this.$emit('update:selectedKeys', selectedKeys);
    }
  }
})
