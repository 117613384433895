import { IHeader } from '@/bundles/BaseTable/interfaces';
import { OpexDisplayEnum } from '@/bundles/Members/enums';
import { getOpexDisplayLabel } from '@/bundles/Members/helpers';

export const CUSTOM_SORT = 'custom_sort';

export const availabilitiesAdminHeadersData: IHeader[] = [
  { text: 'Photo', sortable: false },
  {
    text: 'Address',
    value: 'address, street_name',
    sortable: false,
    icon: 'string',
    multi: [
      {
        text: 'Address',
        value: 'address',
        sortable: true,
        class: '',
        icon: 'string',
      },
      {
        text: 'Street Name',
        value: 'street_name',
        sortable: true,
        class: '',
        icon: 'string'
      },
    ]
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Direct Available Space',
        value: 'available_space',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Divisible',
        value: 'divisible_space',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Office Space',
        value: 'office_space',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Rentable Building Area',
        value: 'rentable_building_area',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Market / Submarket',
        value: 'market',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Construction Status',
        value: 'construction_status',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Building Dimensions',
        value: 'dimensions',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Skylights',
        value: 'skylights',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Sprinklers',
        value: 'sprinkler',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Min Clear Height',
        value: 'min_clear_height',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Max Clear Height',
        value: 'max_clear_height',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Column Spacing',
        value: 'column_spacing',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [{ text: 'GL/DID', value: 'gl', class: '', sortable: true }],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [{ text: 'DH', value: 'dh', class: '', sortable: true }],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Rail Status',
        value: 'rail_status',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Rail Lines',
        value: 'rail_line',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Car Parks', value: 'parking', class: '', sortable: true },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Property Comments',
        value: 'property_comments',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Listing Comments',
        value: 'listing_comments',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Listing Type',
        value: 'listing_type',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Agency Type',
        value: 'agency_type',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Deal Status',
        value: 'deal_status',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Asking Rate',
        value: 'lease_asking_rate',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Type', value: 'lease_type', class: '', sortable: true },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Opex/SF', value: 'lease_opex', class: '', sortable: true },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'TI/SF', value: 'lease_tt', class: '', sortable: true },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Asking Price / SF',
        value: 'sale_asking_price',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Inventory',
        value: 'inventory',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Company', value: 'company', class: '', sortable: true },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Landlord', value: 'landlord', class: '', sortable: true },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'On Market',
        value: 'on_market',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Available',
        value: 'available',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Last Update',
        value: 'last_update',
        class: '',
        sortable: true,
      },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      { text: 'Updated', value: 'updated.date', sortable: true },
      { text: 'Created', value: 'created.date', sortable: true },
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [{ text: 'Views', value: 'views', class: '', sortable: true }],
  },
];

export const getAvailabilitiesCardHeadersData = (sourceType: string, opexDisplay: OpexDisplayEnum): IHeader[] => [
  { text: '#', value: 'number', sortable: false },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Property Name',
        value: 'name',
        class: '',
        sortable: sourceType !== 'property',
        icon: 'string'
      },
      {
        text: 'more address',
        value: 'citystatezip',
        sortable: false,
        class: '',
        icon: 'string',
        multi: [
          {
            text: 'Address',
            value: 'address',
            class: '',
            sortable: sourceType !== 'property',
            icon: 'string',
          },
          {
            text: 'Address 2',
            value: 'address2',
            class: '',
            sortable: true,
            icon: 'string',
          },
        ]
      },
      {
        text: 'Property Type',
        value: 'propertyType',
        class: '',
        sortable: sourceType !== 'property',
        icon: 'string'
      }
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Available Space',
        value: 'available_space',
        class: '',
        sortable: true,
        icon: 'number',
      },
      {
        text: 'Divisible Space',
        value: 'divisible_space',
        class: '',
        sortable: true,
        icon: 'number',
      }
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Building Size',
        value: 'building_size',
        class: '',
        sortable: true,
        icon: 'number',
      },
      {
        text: 'Construction Status',
        value: 'property.construction_status',
        class: '',
        sortable: sourceType !== 'property',
        icon: 'string',
      },
      {
        text: 'Vacancy Status',
        value: 'property.vacancy_status',
        class: '',
        sortable: true,
        icon: 'string',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Land Size',
        value: 'land_size',
        class: '',
        sortable: true,
        icon: 'number',
      },
      {
        text: 'Divisible Land Size',
        value: 'divisible_land_size',
        class: '',
        sortable: true,
        icon: 'number',
      }
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Office Space',
        value: 'office_space',
        class: '',
        sortable: true,
        icon: 'number',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Clear Height',
        value: 'min_clear_height',
        sortable: true,
        icon: 'string',
      },
      {
        text: 'Dock Doors',
        value: 'dock_doors',
        sortable: true,
        icon: 'string',
      },
      {
        text: '# Drive-In Doors',
        value: 'ramp_grade',
        class: '',
        sortable: true,
        icon: 'string',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Sprinklers',
        value: 'sprinklers',
        class: '',
        sortable: true,
        icon: 'string',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Price',
        value: 'sale_price',
        class: '',
        sortable: true,
        icon: 'number',
      },
      {
        text: 'Per SF',
        value: 'sale_price_sf',
        class: '',
        sortable: true,
        icon: 'number',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Lease Rate',
        value: 'lease_rate',
        class: '',
        sortable: true,
        icon: 'number',
      },
      {
        text: 'Type',
        value: 'lease_type',
        class: '',
        sortable: false,
        icon: 'string',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: getOpexDisplayLabel(opexDisplay),
        value: 'operation_expenses',
        class: '',
        sortable: true,
        icon: 'number',
      },
      {
        text: 'TI Allowance',
        value: 'ti_allowance',
        class: '',
        sortable: true,
        icon: 'number',
      },
    ],
  },
  {
    text: 'Available',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Available',
        value: 'available_date',
        class: '',
        sortable: true,
        icon: 'date',
      },
      {
        text: 'On Market (months)',
        value: 'date_on_market',
        class: '',
        sortable: true,
        icon: 'date',
      },
    ],
  },
  { text: 'True Owner', value: 'true_owner', class: '', sortable: false },
  { text: 'Brokers', value: 'broker', class: '', sortable: false },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Updated',
        value: 'updated.date',
        sortable: true,
        icon: 'date',
      },
      {
        text: 'Created',
        value: 'created.date',
        sortable: true,
        icon: 'date',
      },
      {
        text: 'Status',
        value: 'status',
        class: '',
        sortable: true,
        icon: 'string',
      },
      {
        text: 'Validation',
        value: 'verification',
        class: '',
        sortable: false,
        icon: 'string',
      },
    ],
  },
];

export const getAvailabilityClientHeadersData = (opexDisplay: OpexDisplayEnum, { isStaticSetSelected, isCustomSortApplied }: {
  isStaticSetSelected?: boolean;
  isCustomSortApplied?: boolean;
} = {
  isStaticSetSelected: false,
  isCustomSortApplied: false,
}): IHeader[] => {
  const customSortHeader = {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Custom Sort',
        value: CUSTOM_SORT,
        class: '',
        sortable: true,
      },
    ],
  };

  const isReplaceImageHeader = isStaticSetSelected && !isCustomSortApplied;

  const headers = [
    isReplaceImageHeader ? customSortHeader : { text: '', value: 'image', sortable: false },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Property Name',
          value: 'name',
          class: '',
          sortable: true,
          icon: 'string'
        },
        {
          text: 'Address',
          value: 'address, street_name',
          sortable: false,
          icon: 'string',
          multi: [
            {
              text: 'Address',
              value: 'address',
              sortable: true,
              class: '',
              icon: 'string',
            },
            {
              text: 'Street Name',
              value: 'street_name',
              sortable: true,
              class: '',
              icon: 'string'
            },
          ]
        },
        {
          text: 'Property Type',
          value: 'propertyType',
          class: '',
          sortable: true,
          icon: 'string'
        }
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Available Space',
          value: 'available_space',
          class: '',
          sortable: true,
          icon: 'number',
        },
        {
          text: 'Divisible Space',
          value: 'divisible_space',
          class: '',
          sortable: true,
          icon: 'number',
        }
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Building Size',
          value: 'building_size',
          class: '',
          sortable: true,
          icon: 'number',
        },
        {
          text: 'Multi',
          value: 'Multi',
          multi: [
            {
              text: 'Construction Status,',
              value: 'property.construction_status',
              class: '',
              sortable: true,
              icon: 'string',
            },
            {
              text: 'Yr Built',
              value: 'year_built',
              class: '',
              sortable: true,
              icon: 'number',
            },
          ]
        },
        {
          text: 'Vacancy Status',
          value: 'property.vacancy_status',
          class: '',
          sortable: true,
          icon: 'string',
        },
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Land Size',
          value: 'land_size',
          class: '',
          sortable: true,
          icon: 'number',
        },
        {
          text: 'Divisible Land Size',
          value: 'divisible_land_size',
          class: '',
          sortable: true,
          icon: 'number',
        }
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Office Space',
          value: 'office_space',
          class: '',
          sortable: true,
          icon: 'number',
        },
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Clear Height',
          value: 'min_clear_height',
          sortable: true,
          icon: 'string',
        },
        {
          text: 'Dock Doors',
          value: 'dock_doors',
          sortable: true,
          icon: 'string',
        },
        {
          text: '# Drive-In Doors',
          value: 'ramp_grade',
          class: '',
          sortable: true,
          icon: 'string',
        },
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Sprinklers',
          value: 'sprinklers',
          class: '',
          sortable: true,
          icon: 'string',
        },
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        { text: 'Price', value: 'sale_price', class: '', sortable: true, icon: 'number' },
        { text: 'Per SF', value: 'sale_price_sf', class: '', sortable: true, icon: 'number' },
        { text: 'Cap Rate', value: 'cap_rate', class: '', sortable: true, icon: 'number' },
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Lease Rate',
          value: 'lease_rate',
          class: '',
          sortable: true,
          icon: 'number',
        },
        {
          text: 'Type',
          value: 'lease_type',
          class: '',
          sortable: false,
          icon: 'string',
        },
      ],
    },
    {
      text: 'Multi',
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: getOpexDisplayLabel(opexDisplay),
          value: 'operation_expenses',
          class: '',
          sortable: true,
          icon: 'number',
        },
        {
          text: 'TI Allowance',
          value: 'ti_allowance',
          class: '',
          sortable: true,
          icon: 'number',
        },
      ],
    },
    {
      text: 'Available',
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Available',
          value: 'available_date',
          class: '',
          sortable: true,
          icon: 'date',
        },
        {
          text: 'On Market (months)',
          value: 'date_on_market',
          class: '',
          sortable: true,
          icon: 'date',
        },
      ],
    },
    { text: 'True Owner', value: 'true_owner', class: '', sortable: false },
    {
      value: 'multi',
      sortable: false,
      multi: [
        { text: 'Listing Company', value: 'availability_company', class: '', sortable: true, icon: 'string' },
        { text: 'Listing Agents', value: 'broker', class: '', sortable: false }
      ]
    },

    {
      value: 'multi',
      sortable: false,
      multi: [
        {
          text: 'Updated',
          value: 'updated.date',
          sortable: true,
          icon: 'date',
        },
        {
          text: 'Created',
          value: 'created.date',
          sortable: true,
          icon: 'date',
        },
        {
          text: 'Status',
          value: 'status',
          class: '',
          sortable: true,
          icon: 'string',
        },
        {
          text: 'Validation',
          value: 'verification',
          class: '',
          sortable: false,
          icon: 'string',
        },
      ],
    },
  ];

  return isStaticSetSelected && isCustomSortApplied ? [customSortHeader, ...headers] : headers;
};

export const availabilityWorkflowHeadersData: IHeader[] = [
  { text: '', value: 'image', sortable: false },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Property Name',
        value: 'name',
        class: '',
        sortable: true,
        icon: 'string',
      },
      {
        text: 'Address',
        value: 'address, street_name',
        sortable: false,
        icon: 'string',
        multi: [
          {
            text: 'Address',
            value: 'address',
            sortable: true,
            class: '',
            icon: 'string',
          },
          {
            text: 'Street Name',
            value: 'street_name',
            sortable: true,
            class: '',
            icon: 'string'
          },
        ]
      },
      {
        text: 'Property Type',
        value: 'propertyType',
        class: '',
        sortable: true,
        icon: 'string'
      }
    ],
  },
  {
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Available Space',
        value: 'available_space',
        class: '',
        sortable: true,
        icon: 'number',
      },
      {
        text: 'Divisible Space',
        value: 'divisible_space',
        class: '',
        sortable: true,
        icon: 'number',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Building Size',
        value: 'building_size',
        class: '',
        sortable: true,
        icon: 'number',
      },
      {
        text: 'Year Built',
        value: 'year_built',
        class: '',
        sortable: true,
        icon: 'number',
      },
      {
        text: 'Construction Status',
        value: 'property.construction_status',
        class: '',
        sortable: true,
        icon: 'string',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Marketing Push',
        value: 'marketing_push',
        class: 'text-center',
        sortable: true,
        icon: 'number',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Published to Web',
        value: 'website_push',
        class: 'text-center',
        sortable: true,
        icon: 'number',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Listing Agreement',
        value: 'listing_agreement',
        class: 'text-center',
        sortable: true,
        icon: 'string',
      },
      {
        text: 'Expiration Date',
        value: 'listing_agreement.expiration_date',
        class: 'text-center',
        sortable: true,
        icon: 'date',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Active\n In House Exclusive',
        class: 'text-center white-space--pre-line',
        sortable: true,
        icon: 'string',
        value: 'active_inhouse_exclusive'
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Property Sign',
        value: 'sign',
        class: 'text-center',
        sortable: true,
        center: true,
        icon: 'string',
      },
      {
        text: 'Install Date',
        value: 'sign.install_date',
        class: 'last-date-label text-center',
        sortable: true,
        icon: 'date',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Flyer (90 days)',
        value: 'property_flyer',
        class: 'text-center',
        sortable: true,
        icon: 'string',
      },
      {
        text: 'Last Updated',
        value: 'property_flyer.date',
        class: 'last-date-label text-center',
        sortable: true,
        icon: 'date',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Eblast (5 weeks)',
        value: 'has_eblast',
        class: 'text-center',
        sortable: true,
        icon: 'string',
      },
      {
        text: 'Last Sent',
        value: 'eblast',
        class: 'last-date-label text-center',
        sortable: true,
        icon: 'date',
      },
    ],
  },
  {
    text: 'Multi',
    value: 'multi',
    sortable: false,
    multi: [
      {
        text: 'Syndication (30 days)',
        value: 'has_syndication',
        class: 'text-center',
        sortable: true,
        icon: 'string',
      },
      {
        text: 'Last Update',
        value: 'eblast_last_sent',
        class: 'last-date text-center',
        sortable: true,
        icon: 'date',
      },
    ],
  },
];
