import instance from '../instance';
import { generateObjectParams, generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/contact';

export default {
  create: async function ({ payload, token }) {
    try {
      return await instance.post(`${resource}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  list: async function ({ params = [], token, onDownloadEventHandler = null, controller = null }) {
    try {
      const queryParams = generateObjectParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: queryParams
      };
      if (onDownloadEventHandler) {
        config = Object.assign(config, {
          onDownloadProgress: onDownloadEventHandler,
        });
      }
      if (controller) {
        config = { ...config, signal: controller.signal };
      }
      return await instance.get(`${resource}/list`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  merge: async function ({ id, token, payload }) {
    try {
      return await instance.post(`${resource}/${id}/merge`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getMarketing: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/marketing`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getInstallers: async function ({ token }) {
    try {
      return await instance.get(`${resource}/installers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  listShared: async function (params = []) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/shared/list${paramsString}`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getQuickList: async function ({ token }) {
    try {
      return await instance.get(`${resource}/quicklist`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateRoles: async function ({ id, token, type, payload }) {
    try {
      const sourcesWithNewName = ['requirement'];
      const isNewName = sourcesWithNewName.includes(type);
      const apiUrl = `${type}/${id}/${isNewName ? 'contact-roles' : 'contactRoles'}`;

      return await instance.put(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeContact: async function ({ id, token, type, payload }) {
    try {
      let apiUrl = type + '/removeContact/' + id;
      return await instance.put(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  unarchive: async function ({ token, id }) {
    try {
      return await instance.patch(`${resource}/${id}/unarchive`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeCompany: async function ({ id, token }) {
    try {
      return await instance.delete(`${resource}/${id}/company`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updatePhoto: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/photo/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addCompany: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/Company/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateAvailabilityContactOrder: async function ({ token, payload }) {
    try {
      return await instance.patch(`${resource}/availability/order`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeItem: async function ({ token, setId, contactId }) {
    try {
      return await instance.delete(`savedSet/${setId}${resource}/${contactId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  share: async function ({ token, id, payload }) {
    try {
      return await instance.post(`/contact/${id}/share`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getActivityList: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/activity`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  import: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/import`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getCollectionContacts: async function ({ token, collection, id }) {
    try {
      return await instance.get(`${resource}/${collection}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getOccupiers: async function ({ id, token, params }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/${id}/occupiers${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getAvailabilities: async function ({ token, id, params }) {
    const paramsString = generateParams(params);
    try {
      return await instance.get(`${resource}/${id}/availabilities${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getCompanies: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/companies`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRequirements: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/requirements`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getProperties: async function ({ token, id, params = [] }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/${id}/properties${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getComparables: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/comparables`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getPipelines: async function ({ token, id, params = [] }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/${id}/pipelines${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getProjects: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/projects`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getDeals: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/deals`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  exportVCard: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/vcard`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  directMessage: async function ({ token, id, payload }) {
    try {
      return await instance.post(`/messaging/${ id }/direct`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getContactNames: async function ({ token, params }) {
    const queryParams = generateObjectParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: queryParams
      };
    try {
      return await instance.get(`${resource}/names`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  }
};
