// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!./vcard-parser-worker.js';

export function convertFromVCF (vcf: string, callback: (err: Error | null, data: any) => void) {
  const worker = new Worker();
  worker.postMessage(vcf);

  worker.onmessage = (e) => {
    callback(null, e.data);
  }

  worker.onerror = (e) => {
    callback(e.message, null);
  }
}
