var render = function (_h,_vm) {var _c=_vm._c;return _c('div',[(_vm.props.showLabel)?_c('strong',[_vm._v("Price: ")]):_vm._e(),(_vm.props.availability.will_not_sell)?_c('span',[_vm._v(" WNS ")]):(_vm.props.availability.sale)?_c('span',[(_vm.props.availability.sale.asking_price)?_c('span',[(_vm.props.showPrice)?_c('span',[_vm._v(_vm._s(_vm._f("formatCurrencyNoDecimal")(_vm.props.availability.sale.asking_price))+" ")]):_vm._e(),(_vm.props.showPrice && _vm.props.showPsf)?_c('span',[_vm._v("("+_vm._s(_vm.$options.methods.totalPerSF(_vm.props.availability))+" PSF) ")]):(_vm.props.showPsf)?_c('span',[_vm._v(_vm._s(_vm.$options.methods.totalPerSF(_vm.props.availability))+" PSF ")]):_vm._e(),(_vm.props.availability.sale.private === true && !_vm.props.hideIcons)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"red","size":"16"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text text-caption"},[_vm._v("P")])])]}}],null,false,2006643753)},[_c('span',[_vm._v("The Sales Price is Private")])]):_vm._e(),(_vm.props.availability.sale.guidance_price === true && !_vm.props.hideIcons)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"blue","size":"16"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text text-caption"},[_vm._v("G")])])]}}],null,false,3195064432)},[_c('span',[_vm._v("The Sales Price is a guidance price")])]):_vm._e()],1):(_vm.props.availability.sale.asking_price_sf)?_c('span',[(_vm.props.showPrice)?_c('span',[_vm._v(_vm._s(_vm._f("formatCurrencyNoDecimal")((_vm.props.availability.sale.asking_price_sf * _vm.props.availability.available_space)))+" ")]):_vm._e(),(_vm.props.showPrice && _vm.props.showPsf)?_c('span',[_vm._v("("+_vm._s(_vm.$options.methods.totalPerSF(_vm.props.availability))+" PSF) ")]):(_vm.props.showPsf)?_c('span',[_vm._v(_vm._s(_vm.$options.methods.totalPerSF(_vm.props.availability))+" PSF ")]):_vm._e(),(_vm.props.availability.sale && _vm.props.availability.sale.private === true && !_vm.props.hideIcons)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"red","size":"16"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text text-caption"},[_vm._v("P")])])]}}],null,false,2006643753)},[_c('span',[_vm._v("The Sales Price is Private")])]):_vm._e(),(_vm.props.availability.sale && _vm.props.availability.sale.guidance_price === true && !_vm.props.hideIcons)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"blue","size":"16"}},'v-avatar',attrs,false),on),[_c('span',{staticClass:"white--text text-caption"},[_vm._v("G")])])]}}],null,false,3195064432)},[_c('span',[_vm._v("The Sales Price is a guidance price")])]):_vm._e()],1):_c(_vm.$options.components.Label,{tag:"component"})],1):_c(_vm.$options.components.Label,{tag:"component"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }