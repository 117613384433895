







































































































































































































import { mapState } from 'vuex';
import { UserWidgetsEnum, UserWidgetsLabels } from '@/bundles/Settings/enums/userLayoutEnum';
import { Nullable, ValueOf } from '@/utils/types';
import { ServiceFactory } from '@/services/ServiceFactory';
import { ITableOptions } from '@/bundles/BaseTable/interfaces';
import { getInvoiceAging, getInvoiceStatusProps } from '@/bundles/Deal/helpers';
import { ExportRecordType, ExportType } from '@/bundles/Common/types';
import Member from '@/bundles/Members/models/Member';
import { memberService } from '@/bundles/Members/factory/memberServiceFactory';
import { getTableOptions } from '@/bundles/BaseTable/helpers/consts';
import { debounce } from 'lodash';
import { INameValue } from '@/types/common';
import mixins from 'vue-typed-mixins';
import { RequestAbortMixin } from '@/bundles/App/mixins/RequestAbortMixin';
import { REQUEST_CANCELLED_STATUS } from '@/bundles/Http/mappers/ErrorMapper';

import DashboardCard from '@/bundles/Dashboard/components/DashboardCard.vue';
import OpenInvoicesTable from '@/bundles/Invoice/components/OpenInvoicesTable.vue';
import OpenInvoicesDateFilter from '@/bundles/Dashboard/components/filters/OpenInvoicesDateFilter.vue';
import SendInvoiceForm from '@/components/invoice/SendInvoiceForm.vue';
import AppTooltip from '@/bundles/App/components/common/AppTooltip.vue';

type MemberWithFullName = Member & { full_name: string };

interface IDashboardOpenInvoicesCardData {
  items: any[];
  loading: boolean;
  filterLoading: boolean;
  widgetType?: UserWidgetsEnum;
  exportLoading: boolean;
  date_start: Nullable<string>;
  date_end: Nullable<string>;
  title?: ValueOf<keyof typeof UserWidgetsLabels>;
  members: MemberWithFullName[];
  memberId: Nullable<string>;
  filtersMenu: boolean;
  tableOptions: ITableOptions;
  selectedKeys: string[];
  keyword: string;
  excludePaid: boolean;
  sendInvoiceDrawer: boolean;
  selectedInvoice: Nullable<Record<string, any>>;
  totalRows: number;
}

const InvoiceService = ServiceFactory.get('invoice');
const SavedSetService = ServiceFactory.get('savedSets');

export default mixins(RequestAbortMixin).extend({
  name: 'DashboardOpenInvoicesCard',

  components: {
    AppTooltip,
    SendInvoiceForm,
    OpenInvoicesDateFilter,
    DashboardCard,
    OpenInvoicesTable,
  },

  props: {
    expanded: Boolean,
  },

  data: (): IDashboardOpenInvoicesCardData => ({
    items: [],
    loading: true,
    filterLoading: false,
    exportLoading: false,
    date_start: null,
    date_end: null,
    members: [],
    memberId: null,
    filtersMenu: false,
    tableOptions: getTableOptions({
      sortBy: ['daysDiff'],
      sortDesc: [true],
      itemsPerPage: 10,
      itemsPerPageOptions: [
        { title: '10', value: 10 },
        { title: '25', value: 25 },
        { title: '50', value: 50 },
        { title: '100', value: 100 },
        { title: 'All', value: -1 }
      ]
    }),
    selectedKeys: [],
    keyword: '',
    excludePaid: true,
    sendInvoiceDrawer: false,
    selectedInvoice: null,
    totalRows: 0,
  }),

  computed: {
    ...mapState({
      token: (state: any) => state.idToken,
    }),
  },

  watch: {
    keyword (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.resetPaginationDebounced(this);
      }
    },
    excludePaid () {
      this.resetPaginationDebounced(this);
    },
    tableOptions: {
      handler () {
        this.fetchWrapper();
      },
      deep: true,
    },
    'tableOptions.page' () {
      this.selectedKeys = [];
    }
  },

  created () {
    this.widgetType = UserWidgetsEnum.open_invoices;
    this.title = UserWidgetsLabels[UserWidgetsEnum.open_invoices];

    this.fetchWrapper();

    // @ts-ignore
    if (this.isAdmin) {
      this.getMembers();
    }
  },

  methods: {
    resetPaginationDebounced: debounce(function (vm: any) {
      vm.resetPagination();
    }, 300),
    async fetchWrapper () {
      try {
        this.filterLoading = true;

        const params: INameValue<any>[] = [
          { name: 'exclude_paid', value: this.excludePaid },
          { name: 'pageOffset', value: this.tableOptions.itemsPerPage * (this.tableOptions.page - 1) },
          { name: 'pageLimit', value: this.tableOptions.itemsPerPage === -1 ? this.totalRows : this.tableOptions.itemsPerPage },
          { name: 'sort', value: this.tableOptions.sortBy },
          { name: 'descending', value: this.tableOptions.sortDesc },
        ];

        if (this.date_start) {
          params.push({ name: 'date_start', value: this.date_start })
        }
        if (this.date_end) {
          params.push({ name: 'date_stop', value: this.date_end });
        }
        // @ts-ignore
        if (this.isAdmin && this.memberId) {
          params.push({ name: 'member', value: this.memberId });
        }
        if (this.keyword) {
          params.push({ name: 'keyword', value: encodeURIComponent(this.keyword) });
        }

        this.handleRequestAbort();
        const { data } = await InvoiceService.getOpenInvoices({
          token: this.token,
          params,
          controller: this.abortController,
        });

        this.items = data.data.map(item => {
          const { status, color } = getInvoiceStatusProps(item);
          const { daysDiff, diffLabel } = getInvoiceAging(item.due_date);
          return {
            ...item,
            status,
            color,
            daysDiff,
            diffLabel
          };
        });
        this.totalRows = data.total;
        this.filterLoading = false;
        this.loading = false;
      } catch (error: any) {
        this.addNotification(error);
        if (error?.status !== REQUEST_CANCELLED_STATUS) {
          this.filterLoading = false; // can't be inside finally because fetchWrapper calls several times when changing table options
          this.loading = false;
        }
      }
    },
    async exportHandler () {
      try {
        this.exportLoading = true;

        const payload = {
          record_type: ExportRecordType.open_invoices,
          type: ExportType.list_export,
          records: this.selectedKeys,
          date_start: this.date_start || null,
          date_stop: this.date_end || null,
        };

        if (this.tableOptions.sortBy?.length) {
          Object.assign(payload, { sort: this.tableOptions.sortBy[0] });
        }

        await SavedSetService.exportToExcelNew(payload);
        this.addNotification({
          status: 200,
          title: 'Success',
          message: 'Your export <b>is being generated</b>. When <b>complete</b>, a notification <b>will appear</b> at the <b>top right</b> of the screen. ' +
            'Some exports take a few minutes to generate.',
        });
      } catch (error: any) {
        this.addNotification({ ...error });
      } finally {
        this.exportLoading = false;
      }
    },
    changeUser (value) {
      this.memberId = value;
      this.fetchWrapper();
    },
    async getMembers () {
      const params = [
        { name: 'take', value: 0 },
        { name: 'status', value: 'active' },
      ]

      const { data } = await memberService.list(params);

      const members = data.map((item) => {
        return {
          ...item,
          full_name: `${item.profile.first_name} ${item.profile.last_name}`
        }
      });
      members.sort((a, b) => (a.profile.last_name > b.profile.last_name ? 1 : -1))
      this.members = members;
    },
    sendInvoiceHandler () {
      if (this.selectedKeys.length !== 1) return;

      this.selectedInvoice = this.items.find(item => item._key === this.selectedKeys[0]);
      this.sendInvoiceDrawer = true;
    },
    submitInvoiceHandler () {
      this.sendInvoiceDrawer = false;
      this.selectedInvoice = null;
    },
    resetPagination () {
      if (this.tableOptions.page === 1) {
        this.fetchWrapper();
      } else {
        this.tableOptions.page = 1;
      }
    },
  }
});
