export enum FilterTransactionTypeEnum {
  lease = 'lease',
  all = 'all',
  sale = 'sale',
}

export const FilterTransactionTypeLabels = {
  [FilterTransactionTypeEnum.lease]: 'Lease',
  [FilterTransactionTypeEnum.all]: 'All',
  [FilterTransactionTypeEnum.sale]: 'Sale',
}
