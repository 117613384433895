import CompanyService from '@/bundles/Companies/services/CompanyService';
import { httpV1 } from '@/bundles/Http/factory/httpFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import CompanyToMapper from '@/bundles/Companies/mappers/CompanyToMapper';

function companyServiceFactory () {
  return new CompanyService({
    transport: httpV1,
    errorMapper: new ErrorMapper(),
    companyMapper: new CompanyToMapper(),
  });
}

export const companyService = companyServiceFactory();
