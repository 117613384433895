export enum FilterTransactionStatusEnum {
  lease_pending = 'lease_pending',
  exclude = 'exclude',
  contract_pending = 'contract_pending',
}

export const FilterTransactionStatusLabels = {
  [FilterTransactionStatusEnum.lease_pending]: 'Lease Pending',
  [FilterTransactionStatusEnum.exclude]: `Exclude`,
  [FilterTransactionStatusEnum.contract_pending]: `Contract Pending`,
}

export const FilterTransactionStatusButtonItems = [
  {
    text: FilterTransactionStatusLabels[FilterTransactionStatusEnum.lease_pending],
    value: FilterTransactionStatusEnum.lease_pending,
    btnText: `Lease \n Pending Only`,
  },
  {
    text: FilterTransactionStatusLabels[FilterTransactionStatusEnum.exclude],
    value: FilterTransactionStatusEnum.exclude,
  },
  {
    text: FilterTransactionStatusLabels[FilterTransactionStatusEnum.contract_pending],
    value: FilterTransactionStatusEnum.contract_pending,
    btnText: `Contract \n Pending Only`,
  }
];
