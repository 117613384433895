
import Vue, { CreateElement, VNode } from 'vue'
import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';

export default Vue.extend({
  name: 'PublishedToWebChip',

  functional: true,

  render (h: CreateElement): VNode {
    return h(CommonChip, { attrs: { color: 'primary' } }, [
      h('span', 'Published to web')
    ]);
  }
})
