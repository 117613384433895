import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/invoice';
const paymentResource = '/payment';

export default {
  list: async function ({ params = [], token }) {
    try {
      let paramsString = generateParams(params);
      return await instance.get(`${resource}/list${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ payload, token }) {
    try {
      return await instance.post(`${resource}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  shared: async function (id) {
    try {
      return await instance.get(`${resource}/${id}/shared`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  createPayment: async function ({ token, payload }) {
    try {
      return await instance.post(`${paymentResource}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  paymentList: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/payments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updatePayment: async function ({ token, payload, id }) {
    try {
      return await instance.patch(`${paymentResource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  clearBalance: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/clearBalance`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archivePayment: async function ({ token, id }) {
    try {
      return await instance.delete(`${paymentResource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateInvoice: async function ({ token, payload, id }) {
    try {
      return await instance.patch(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getPayment: async function ({ token, id, paymentId }) {
    try {
      return await instance.get(`${resource}/${id}/payment/${paymentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  generatePdf: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/generate-pdf`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  send: async function ({ token, payload, id }) {
    try {
      return await instance.post(`${resource}/${ id }/send`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getOpenInvoices: async function ({ params = [], token, controller = null }) {
    try {
      const paramsString = generateParams(params);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      if (controller) {
        Object.assign(config, { signal: controller.signal });
      }
      return await instance.get(`${resource}/open-invoices${paramsString}`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  }
};
