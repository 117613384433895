import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { generateParams } from '@/utils/helpers';
import { INameValue, ITextValue } from '@/types/common';

export default class AddressService {
  private readonly transport: HttpTransport;
  private readonly errorMapper: ErrorMapper;

  constructor ({ transport, errorMapper, }: { transport: HttpTransport; errorMapper: ErrorMapper; }) {
    this.transport = transport;
    this.errorMapper = errorMapper;
  }

  async getStates (params: INameValue<string>[] = []): Promise<ITextValue<string>[]> {
    try {
      const paramsString = generateParams(params);

      return await this.transport.get(`/address/states${paramsString}`);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
