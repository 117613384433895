import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { generateParams } from '@/utils/helpers';
import CompanyToMapper from '@/bundles/Companies/mappers/CompanyToMapper';
import Company from '@/bundles/Companies/models/Company';
import { Nullable } from '@/utils/types';

interface ICompanyServiceParams {
  transport: HttpTransport;
  errorMapper: ErrorMapper;
  companyMapper: CompanyToMapper;
}

const USA_CANADA_COUNTRY_CODE = '+1';

export default class CompanyService {
  private readonly transport: HttpTransport;
  private readonly errorMapper: ErrorMapper;
  private readonly companyMapper: CompanyToMapper;

  constructor ({ transport, errorMapper, companyMapper }: ICompanyServiceParams) {
    this.transport = transport;
    this.errorMapper = errorMapper;
    this.companyMapper = companyMapper;
  }

  async getCompanyByEnrich ({ params = [], controller = null }: { params: Array<{ name: string, value: any }>; controller: Nullable<AbortController> }): Promise<Company | string> {
    try {
      const paramsString = generateParams(params);
      const response = await this.transport.get(`/enrich/company${paramsString}`, {}, controller);

      if (!response.success) {
        return response.error?.message || response.message;
      }

      if (response.phone) {
        response.phone = response.phone.replace(USA_CANADA_COUNTRY_CODE, ''); // Remove country code
      }

      return this.companyMapper.mapToModel(response);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async saveComments (id, payload) {
    try {
      return await this.transport.patch(`/company/${id}/comments`, payload);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }

  async saveKeywords (id, payload) {
    try {
      return await this.transport.patch(`/company/${id}/keywords`, payload);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
