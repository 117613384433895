import Vue from 'vue';
import { getCountries, getCountryMask } from '@/bundles/Common/helpers/getCountries';
import { ICountry } from '@/bundles/Common/interfaces/ICountries';
import { Nullable } from '@/utils/types';

const DEFAULT_COUNTRY_CODE = 'US';

interface ICountriesMasksMixin {
  countriesList: ICountry[];
}

const CountriesMasksMixin = Vue.extend({
  data: (): ICountriesMasksMixin => ({
    countriesList: [],
  }),

  created () {
    this.setCountriesList();
  },

  methods: {
    async setCountriesList () {
      this.countriesList = await getCountries();
    },
    getCountryMask (code: Nullable<string>): string {
      return getCountryMask(this.countriesList, code || DEFAULT_COUNTRY_CODE);
    }
  }
})

export { CountriesMasksMixin, DEFAULT_COUNTRY_CODE };
