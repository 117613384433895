














import Vue from 'vue';
import { debounce } from 'lodash';
import { Nullable } from '@/utils/types';

export default Vue.extend({
  name: 'AppAutocomplete',

  props: {
    value: {
      type: [String, Number, Object, Array],
      default: null,
    },
  },

  data: (): { searchInput: Nullable<string> } => ({
    searchInput: null,
  }),

  computed: {
    modelValue: {
      get (): any {
        return this.value;
      },
      set (value: any): void {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    searchInput (): void {
      this.updateSearchInputDebounced(this);
    },
  },

  methods: {
    updateSearchInputDebounced: debounce(function (vm: any) {
      vm.$emit('update:search-input-debounced', vm.searchInput);
    }, 500),
  }
});
