var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"auto":"","min-width":"400","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("default",null,{"attrs":attrs,"on":on})]}}],null,true),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',[_c('v-card-text',[_c('v-card-subtitle',{staticClass:"pa-0 pb-1 d-flex align-center justify-space-between"},[_c('span',[_vm._v("Phone number")]),_c('v-btn',{attrs:{"text":"","icon":"","small":""},on:{"click":function($event){_vm.showMenu = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-autocomplete',{staticClass:"mr-1",staticStyle:{"max-width":"90px"},attrs:{"outlined":"","item-text":"name","item-value":"iso","items":_vm.countriesList,"hide-details":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"mr-1",attrs:{"height":"22","width":"36","src":item.flagUrl}}),_c('span',[_vm._v(_vm._s(item.iso))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"mr-1",attrs:{"height":"16","width":"27","src":item.flagUrl}})]}}]),model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}}),_c('v-text-field',{directives:[{name:"facade",rawName:"v-facade",value:(_vm.phoneMask),expression:"phoneMask"}],attrs:{"top":"","autofocus":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),(_vm.showExtension)?_c('v-text-field',{staticClass:"ml-2",staticStyle:{"max-width":"105px"},attrs:{"dense":"","label":"Extension","outlined":"","hide-details":""},model:{value:(_vm.extension),callback:function ($$v) {_vm.extension=$$v},expression:"extension"}}):_vm._e()],1),_c('div',{staticClass:"d-flex align-center mt-2 gap-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"height":"30","outlined":""},on:{"click":function($event){_vm.showMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"height":"30","color":"primary"},on:{"click":_vm.savePhone}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }