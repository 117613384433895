import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/company';

export default {
  addContact: async function ({ payload, token, id }) {
    try {
      return await instance.put(`${resource}/${id}/addContact`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getNews: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/news`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  list: async function ({ params = [], token, onDownloadEventHandler = null, controller = null }) {
    try {
      const paramsString = generateParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (onDownloadEventHandler) {
        config = Object.assign(config, {
          onDownloadProgress: onDownloadEventHandler,
        });
      }
      if (controller) {
        config = { ...config, signal: controller.signal };
      }
      return await instance.get(`${resource}/list${paramsString}`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ token, payload, id }) {
    try {
      return await instance.patch(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateCompany: async function ({ token, payload, id }) {
    try {
      return await instance.put(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  merge: async function ({ id, token, payload }) {
    try {
      return await instance.post(`${resource}/${id}/merge`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getContacts: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/contacts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getOccupiers: async function ({ token, id, params }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/${id}/occupiers${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  listShared: async function (params = []) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/shared/list${paramsString}`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeContact: async function ({ token, payload, id }) {
    try {
      return await instance.put(`${resource}/removeContact/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addToCompany: async function ({ token, payload, id }) {
    try {
      return await instance.patch(`company/${id}/addCompany`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeCompany: async function ({ token, payload, id }) {
    try {
      return await instance.patch(`company/${id}/removeCompany`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeOccupier: async function ({ token, id, occupierId }) {
    try {
      return await instance.delete(`company/${id}/occupier/${occupierId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getAvailabilities: async function ({ token, id, params }) {
    const paramsString = generateParams(params);
    try {
      return await instance.get(`${resource}/${id}/availabilities${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRequirements: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/requirements`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getProperties: async function ({ token, id, params = [] }) {
    const paramsString = generateParams(params);
    try {
      return await instance.get(`${resource}/${id}/properties${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getPipelines: async function ({ token, id, params = [] }) {
    const paramsString = generateParams(params);
    try {
      return await instance.get(`${resource}/${id}/pipelines${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getComparables: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/comparables`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeParent: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}/removeParent`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeChild: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}/removeChild`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getChildren: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/children`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getParent: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/parent`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeRequirement: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/remove-requirement`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateLocation: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/location`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateLocationByAddress: async function ({ token, id }) {
    try {
      return await instance.patch(`${resource}/${id}/update-coordinates-by-address`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
