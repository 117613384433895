<template>
  <v-form
    ref="invoiceForm"
    style="position: relative"
    @submit.prevent="submitEventHandler"
  >
    <v-toolbar class="primary panel-header" dark flat>
      <v-toolbar-title>Send Invoice</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="cancel"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card v-if="isNotEmpty" flat>
      <v-card-title>
        Billing Details
        <v-spacer />
        <v-switch
          v-model="useDefaultBilling"
          :label="`Use Default`"
          dense
          on-icon="mdi-close"
          off-icon="mdi-thumb-up"
          color="green"
          hide-details
          inset
        >
          <template #label>
            <span :class="`active-card-switch-${useDefaultBilling}`">Use Default</span>
          </template>
        </v-switch>
      </v-card-title>
      <v-card-text>
        <div v-if="useDefaultBilling" class="invoice-billing-details">
          <div v-if="getDefaultBilling">
            <div><strong>{{ getDefaultBilling.name ? getDefaultBilling.name : `${getDefaultBilling.first_name} ${getDefaultBilling.last_name}` }}</strong></div>
            <div>{{ getDefaultBilling.email }}</div>
            <div v-if="getDefaultBilling.company">
              <div>{{ getDefaultBilling.company.name }}</div>
              <div><small>Attn: Accounts Payable</small></div>
              <div v-if="getDefaultBilling.company && getDefaultBilling.company.address">
                <div>{{ getDefaultBilling.company.address.street }}</div>
                <div>
                  {{ getDefaultBilling.company.address.city }},
                  {{ getDefaultBilling.company.address.state }}
                  {{ getDefaultBilling.company.address.postal }}
                </div>
              </div>
            </div>
            <div v-if="!getDefaultBilling.email" class="text-center pt-2 pt-lg-3">
              <b class="red--text">Please update billing contact's email</b>
            </div>
          </div>
          <div v-else>
            <p>There are missing billing details</p>
          </div>
        </div>
        <div v-else class="invoice-billing-details">
          <v-row v-if="!customBillingContact.email">
            <v-col cols="6">
              <div>
                <v-card flat>
                  <v-card-text>
                    <v-btn
                      dense
                      block
                      @click="sendToMe"
                    >
                      Send to me
                    </v-btn>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <v-col cols="6">
              <RoleContactsCard
                role-title=""
                button-style="default"
                contact-type-label="Contact"
                :allow-multiple="false"
                allow-save
                is-dense
                @removeHandler="removeContactHandler"
                @updateHandler="updateContactsHandler"
              />
            </v-col>
          </v-row>
          <div v-if="customBillingContact.email" class="custom-billing-contact">
            <div><strong>{{ customBillingContact.name }}</strong></div>
            <div v-if="customBillingContact.company">{{ customBillingContact.company.name }}</div>
            <div>{{ customBillingContact.email }}</div>
            <div v-if="isCustomBillingContactHasAddress">
              <div><small>Attn: Accounts Payable</small></div>
              <div>{{ customBillingContact.company.address.street }}</div>
              <div>
                {{ customBillingContact.company.address.city }},
                {{ customBillingContact.company.address.state }}
                {{ customBillingContact.company.address.postal }}
              </div>
            </div>
            <div v-if="!customBillingContact.email" class="text-center pt-2 pt-lg-3">
              <b class="red--text">Please update billing contact's email</b>
            </div>
            <div class="mt-3">
              <v-btn x-small @click="resetCustomBilling">Reset Contact</v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-title>Add to Copy</v-card-title>
      <v-card-text v-if="deal.inhouseBrokers && deal.inhouseBrokers.length > 0">
        <v-switch v-model="ccBrokers" label="Copy In-House Brokers" inset />
        <div v-if="ccBrokers" class="broker-chips">
          <v-chip v-for="broker in deal.inhouseBrokers" :key="broker._id" class="broker-chips-item">
            {{ broker.name }}
          </v-chip>
        </div>
      </v-card-text>
      <v-card-text>
        <v-combobox
          v-model="ccRecipients"
          label="CC:"
          multiple
          small-chips
          append-icon=""
          deletable-chips
          :rules="ccRecipientsRules"
        />
      </v-card-text>
      <v-divider />
      <v-card-title>Invoice Details</v-card-title>
      <v-card-text>
        <div><strong>Invoice ID:</strong> {{ invoice._key }}</div>
        <div><strong>Terms:</strong> {{ invoice.terms }}</div>
      </v-card-text>
      <v-divider />
      <v-card-title>Email Message</v-card-title>
      <v-card-text>
        <v-textarea
          v-model="subject"
          rows="2"
          label="Subject"
          placeholder="Subject"
        />
        <QuillEditor
          id="send-invoice-message"
          v-model="message"
          :toolbar-options="quillToolbarBase"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn text class="cancel" @click="cancel">Cancel</v-btn>
        <v-spacer />
        <v-btn
          type="submit"
          class="primary"
          :disabled="!getDefaultBilling.email && useDefaultBilling"
        >
          Send Invoice
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
  </v-form>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { ServiceFactory } from '@/services/ServiceFactory';
import { contactService } from '@/bundles/Contacts/factory/contactServiceFactory';
import { uniq } from 'lodash';
import { getInvoiceParsedMessage } from '@/bundles/Account/helpers/invoiceDescriptionHelper';
import { VueEditorMixin } from '@/bundles/App/mixins/editor/VueEditorMixin';

import RoleContactsCard from '@/components/common-components/connectContact/RoleContactsCard';
import QuillEditor from '@/bundles/Account/components/QuillEditor.vue';

const InvoiceService = ServiceFactory.get('invoice');

export default {
  name: 'SendInvoiceForm',

  components: {
    QuillEditor,
    RoleContactsCard,
  },

  mixins: [VueEditorMixin],

  props: {
    invoice: {
      type: Object,
      required: true,
    },
    deal: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    email: '',
    loading: false,
    useDefaultBilling: true,
    customBillingContact: {
      name: '',
      email: '',
      company: {
        name: '',
        address: {
          street: '',
          city: '',
          state: '',
          postal: '',
        },
      },
    },
    ccRecipients: [],
    ccRecipientsRules: [
      (items) => {
        if (!items.length) {
          return true;
        }

        return items.every(item => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(item)) ||
          'Please Enter Valid Email';
      }
    ],
    newSubject: null,
    newMessage: null,
    ccBrokers: true
  }),

  computed: {
    ...mapState({
      token: (state) => state.idToken,
      userId: (state) => state.user.id,
      user: (state) => state.user,
      accountSettings: (state) => state.accountSettings,
    }),
    getDefaultBilling () {
      if ('contact' in this.deal) {
        return this.deal.contact.billing[0];
      }

      return this.deal.contacts.billing[0];
    },
    isCustomBillingContactHasAddress () {
      return Boolean(this.customBillingContact.company?.address?.street);
    },
    subject: {
      get: function () {
        let message = `Invoice #${this.invoice.invoice_number}, Re: ${this.deal.name}`;

        if (this.deal.comparable?.address) {
          message = `${message} (${ this.deal.comparable?.address.street}${this.deal.comparable?.address.street2 ? ` ${this.deal.comparable?.address.street2}` : ''}, ${ this.deal.comparable?.address.city }, ${ this.deal.comparable?.address.state } ${ this.deal.comparable?.address.postal })`
        }

        return message;
      },
      set: function (value) {
        this.newSubject = value;
      },
    },
    message: {
      get: function () {
        return getInvoiceParsedMessage(this.accountSettings.default_invoice_message, {
          recipientName: this.useDefaultBilling ? this.getDefaultBilling.name || this.getDefaultBilling.first_name : this.customBillingContact.name,
          senderName: `${this.user.profile.first_name} ${this.user.profile.last_name}`,
        });
      },
      set: function (value) {
        this.newMessage = value;
      },
    },
    recipient: {
      get: function () {
        return this.getDefaultBilling.email || this.email;
      },
      set: function (val) {
        this.email = val;
      },
    },
    isNotEmpty: function () {
      return !!Object.keys(this.invoice).length;
    },
  },

  methods: {
    ...mapMutations('Notifications', ['ADD_NOTIFICATION']),
    /**
     * reset the custom billing
     */
    resetCustomBilling: function () {
      this.customBillingContact = {
        name: '',
        email: '',
        company: {
          name: '',
          address: { street: '', city: '', state: '', postal: '' },
        },
      };
    },
    /**
     * Handlers for the adding of custom contacts
     */
    sendToMe: async function () {
      this.loading = true;
      try {
        const { id, first_name, last_name, email } = await contactService.getContactForMember(this.userId);
        this.customBillingContact._key = id.split('/')[1];
        this.customBillingContact.name = `${first_name} ${last_name}`;
        this.customBillingContact.email = email;
      } catch (error) {
        this.ADD_NOTIFICATION(error);
      } finally {
        this.loading = false;
      }
    },
    removeContactHandler: async function (payload) {
      // console.log('remove the contact payload: ', payload)
      // try {
      //   const { idToken } = this.$store.state
      //   const id = this.deal._key
      //   // const result = await DealService.updateContact({id, token: idToken, payload: payload})
      //   // console.log('result: ', result)
      //   // this.comparable = result.data.comparable.new
      //   this.$emit('updateDeal', result.data.deal)
      // } catch (e) {
      //   console.error(e.message)
      // }
    },
    updateContactsHandler: async function (payload) {
      this.customBillingContact = payload.contactDetails[0];
    },
    /**
     * Handler for cancel event
     * */
    cancel: function () {
      this.$emit('cancel');
      this.message = "Here's your invoice! We appreciate your prompt payment.";
      this.email = '';
    },
    /**
     * Event handler for submit event
     * */
    submitEventHandler: async function () {
      try {
        const isValid = this.$refs.invoiceForm.validate();

        if (isValid) {
          this.loading = true;

          let payload = {
            name: 'Invoice',
            source: 'invoice',
            links: [{ collection: 'Invoice', key: this.invoice._key }],
            subject: !this.newSubject ? this.subject : this.newSubject,
            comment: !this.newMessage ? this.message : this.newMessage,
          };

          if (this.ccRecipients.length) {
            payload.ccRecipients = this.ccRecipients;
          }

          if (this.ccBrokers && this.deal.inhouseBrokers && this.deal.inhouseBrokers.length > 0) {
            payload.ccRecipients = uniq([
              ...(payload.ccRecipients || []),
              ...this.deal.inhouseBrokers.map(broker => broker.email)
            ]);
          }

          if (this.getDefaultBilling.email) {
            payload.withContacts = [
              this.useDefaultBilling
                ? this.getDefaultBilling._key
                : this.customBillingContact._key,
            ];
          } else if (this.customBillingContact) {
            payload.withContacts = [this.customBillingContact._key];
          } else {
            payload.withEmails = [this.recipient];
          }

          await InvoiceService.send({
            token: this.token,
            payload,
            id: this.invoice._key,
          });
          this.resetCustomBilling();
          this.addSuccessNotification('Invoice sent successfully');
          this.message = "Here's your invoice! We appreciate your prompt payment.";
          this.useDefaultBilling = true;

          this.$emit('refresh');
          this.cancel();
        }
      } catch (error) {
        const notification = { ...error };
        this.ADD_NOTIFICATION(notification);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .broker-chips {
    &-item {
      margin-left: 4px;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
</style>
