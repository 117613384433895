








































import Vue, { PropType } from 'vue';
import { INameValue, ITextValue } from '@/types/common';
import { Nullable } from '@/utils/types';
import { isArray } from 'lodash';
import { addressService } from '@/bundles/Address/factory/addressServiceFactory';

import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';

interface IStateSelect {
  isLoading: boolean;
  states: ITextValue<string>[];
}

export default Vue.extend({
  name: 'StateSelect',

  components: { CommonChip },

  props: {
    value: {
      type: [Array, String] as PropType<Array<string> | Nullable<string>>,
      default: null,
    },
    label: {
      type: String,
      default: 'State / Province / Region'
    },
    country: {
      type: String,
      default: null,
    },
    multiple: Boolean,
    autoOpenMenu: Boolean,
  },

  data: (): IStateSelect => ({
    isLoading: false,
    states: [],
  }),

  computed: {
    modelValue: {
      get (): string[] | Nullable<string> {
        return this.value;
      },
      set (value: string[] | Nullable<string>) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    country () {
      this.fetchStates();
    }
  },

  created () {
    this.addModelValueToStates();
    this.fetchStates();
  },

  mounted () {
    if (this.autoOpenMenu) {
      (this.$refs.autocomplete as any).activateMenu();
    }
  },

  methods: {
    removeState: function (value: string) {
      if (isArray(this.modelValue)) {
        this.modelValue = this.modelValue.filter((i) => i !== value);
        return;
      }

      this.modelValue = null;
    },
    filterHandler: function (item: ITextValue<string>, queryText: string) {
      const query = queryText.toLowerCase();

      return item.text.toLowerCase().includes(query) || item.value.toLowerCase().includes(query);
    },
    async fetchStates () {
      try {
        this.isLoading = true;

        const params: INameValue<string>[] = [];

        if (this.country) {
          params.push({ name: 'country', value: this.country });
        }

        this.states = await addressService.getStates(params);
        this.addModelValueToStates();
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.isLoading = false;
      }
    },
    addModelValueToStates () {
      if (!this.modelValue) {
        return;
      }

      if (Array.isArray(this.modelValue)) {
        this.modelValue.forEach((value) => {
          if (!this.states.find((state) => state.value === value)) {
            this.states.push({ text: value, value });
          }
        });
        return;
      }

      if (!this.states.find((state) => state.value === this.modelValue)) {
        this.states.push({ text: this.modelValue, value: this.modelValue });
      }
    }
  }
});
