import Vue from 'vue';

export const AppFormMixin = Vue.extend({
  methods: {
    showFormValidationErrorNotification (message?: string) {
      this.addNotification({
        status: 0,
        title: 'Error',
        message: message || 'Please fill in all required fields before submitting the form.',
      });
    }
  }
})
