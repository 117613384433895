













































































import Vue, { PropType } from 'vue'
import { IAvailability } from '@/bundles/Availabilities/types';
import { formatCurrency, formatRawNumber } from '@/utils/filters';

import Label from '@/bundles/Common/components/Label.vue';

export default Vue.extend({
  name: 'AvailabilitySalesPriceLabel',

  components: {
    Label
  },

  props: {
    availability: {
      type: Object as PropType<IAvailability>,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    showPsf: {
      type: Boolean,
      default: true,
    },
    hideIcons: Boolean,
  },

  methods: {
    totalPerSF (availability: IAvailability): string {
      const askingPrice = formatRawNumber(availability.sale.asking_price) as number;
      const totalSize = formatRawNumber(availability.available_space) as number;
      const availabilityType = availability.type;

      const total = availabilityType?.toLowerCase() === 'land' ? this.landPerSf(availability) : askingPrice / totalSize;

      return formatCurrency(total);
    },
    landPerSf (availability: IAvailability): number {
      const askingPrice = formatRawNumber(availability.sale.asking_price) as number;
      const landSize = formatRawNumber(availability.acres) as number;

      return askingPrice / landSize;
    }
  }
})
