<template>
  <v-card>
    <v-btn
      v-if="allowClose"
      absolute
      top
      right
      icon
      style="z-index: 2"
      @click="$emit('close')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-img :src="require('@/../static/img/Slick-Cactus-Dark.png')" />
    <v-card-text>
      <v-form
        v-if="otpVerify"
        ref="otpForm"
        class="text-center"
        @submit.prevent="otpVerifyHandler"
      >
        <v-text-field
          ref="otpInput"
          v-model="verificationCode"
          label="Enter verification code"
          outlined
          hide-details="auto"
          :rules="[v => !!v || 'Code is required']"
          type="email"
        />
        <div style="font-size: 16px" class="my-4 text--secondary">
          Enter the code from your two-factor authenticator app.
          <br>If you've lost your device, you can enter one of your recovery codes.
        </div>
        <v-btn class="primary" :loading="verificationLoading" type="submit">
          Verify Code
        </v-btn>
      </v-form>
      <v-form
        v-else-if="!resetPass"
        ref="signInForm"
        :lazy-validation="true"
        @submit="submit"
      >
        <v-alert
          v-if="error"
          value
          type="warning"
          class="mb-3"
        >
          {{ authErrorMessage }}
        </v-alert>
        <v-text-field
          v-model="email"
          label="E-mail"
          :rules="emailRules"
          outlined
          placeholder="Email Address"
          autocapitalize="none"
          type="email"
        />
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="showPassword ? 'text' : 'password'"
          name="input-10-1"
          label="Password"
          hint="At least 8 characters"
          counter
          outlined
          placeholders="Enter your password"
          autocomplete="off"
          autocapitalize="none"
          @click:append="showPassword = !showPassword"
          @keydown.enter.prevent="submit"
        />
        <div class="text-center">
          <div id="captcha-sign-in-container" class="captcha-container" />
          <v-btn
            class="primary mb-5"
            :loading="requestLoading"
            @click="submit"
          >
            Sign In
          </v-btn>
          <v-spacer />
          <span class="resetPassword" @click="enableResetPassword">
            <a href="#"><strong>Sign in with your work email</strong></a>
          </span>
        </div>
      </v-form>
      <v-form
        v-else
        ref="resetLinkForm"
        :lazy-validation="true"
        @submit.prevent="sendLink"
      >
        <div v-if="resetSent">
          <p>Please check your email for a link to sign in.</p>
          <span class="resetPassword" @click="resetLogin">
            <a href="#"><strong>Login to my account</strong></a>
          </span>
        </div>
        <div v-else>
          <p v-if="resetError" class="alert error">
            There was an error trying to send a link. It appears that
            the reset token has expired. Please try again.
          </p>
          <p v-else>
            Enter the email address associated with your account, and we’ll send a magic link to your inbox.
          </p>
          <v-text-field
            v-model="resetEmail"
            label="E-mail"
            :rules="emailRules"
            outlined
            placeholder="Email Address"
            autocomplete="off"
          />
          <div class="text-center">
            <div id="captcha-reset-container" class="captcha-container" />
            <v-btn
              class="primary mb-5"
              :loading="resetPasswordLoading"
              type="submit"
            >
              Send Magic Link
            </v-btn>
            <v-spacer />
            <span class="resetPassword" @click="resetPass = false">
              <a href="#"><strong>Login to my account</strong></a>
            </span>
          </div>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { LOGIN, TOGGLE_SPLASH_SCREEN } from '@/store/action-types';
import { eventBus } from '@/eventbus/eventbus';
import { mapActions, mapMutations, mapState } from 'vuex';
import { authService } from '@/bundles/Auth/factory/authServiceFactory';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import { renderCaptcha, resetCaptcha, validateCaptcha } from '@/plugins/captcha';
import { RootActions } from '@/store/types/action-types';

export default {
  name: 'SignInCard',

  mixins: [AccountLayoutMixin],

  props: {
    preventRedirect: Boolean,
    allowClose: Boolean,
  },

  data: () => ({
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail must be valid',
    ],
    password: '',
    resetSent: false,
    resetPass: false,
    resetError: false,
    resetEmail: '',
    showPassword: false,
    email: '',
    error: false,
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 6 || 'Min 6 characters',
    },
    requestLoading: false,
    resetPasswordLoading: false,
    authErrorMessage: null,
    otpVerify: false,
    verificationCode: null,
    verificationLoading: false,
  }),

  computed: {
    ...mapState({
      token: (state) => state.idToken,
    })
  },

  watch: {
    async activeAccountId (newValue) {
      if (!newValue || !this.token) {
        return;
      }

      await this.$router.push(this.getAccountRoute({ name: 'dashboard' }));
      this.toggleScreen(true);
      window.location.reload();
    },
    resetPass: {
      handler: function (value) {
        if (value) {
          this.$nextTick(() => {
            renderCaptcha('captcha-reset-container');
          });
          return;
        }

        this.$nextTick(() => {
          renderCaptcha('captcha-sign-in-container');
        });
      },
      immediate: true,
    },
  },

  created () {
    eventBus.$on('showSignIn', this.showSignInHandler);
  },

  beforeDestroy () {
    eventBus.$off('showSignIn', this.showSignInHandler);
  },

  methods: {
    ...mapActions({
      login: LOGIN,
      setLoginInfo: RootActions.SET_LOGIN_INFO,
    }),
    ...mapMutations({
      toggleScreen: TOGGLE_SPLASH_SCREEN,
    }),
    ...mapMutations('Notifications', ['ADD_NOTIFICATION']),
    showSignInHandler ({ preventRedirect = false }) {
      // eslint-disable-next-line vue/no-mutating-props
      this.preventRedirect = preventRedirect;
    },
    handleCaptcha: function () {
      const captchaResponse = validateCaptcha();
      let captchaValidated = typeof captchaResponse === 'boolean' && captchaResponse;
      if (typeof captchaResponse === 'string' && captchaResponse) {
        return captchaResponse;
      }
      if (captchaValidated) {
        return true;
      }
      const captchaContainer = document.querySelector('.captcha-container');
      captchaContainer.classList.add('captcha-container--error');
      captchaContainer.focus();
      return false;
    },
    async submit () {
      if (!this.$refs.signInForm.validate()) {
        return;
      }
      const captchaResponse = this.handleCaptcha();
      if (typeof captchaResponse === 'boolean' && !captchaResponse) {
        return;
      }
      const authPayload = {
        email: this.email.toLowerCase().trim(),
        password: this.password,
      };
      if (typeof captchaResponse === 'string' && captchaResponse) {
        authPayload.response = captchaResponse;
      }
      this.requestLoading = true;
      try {
        const response = await this.login(authPayload);

        if (response?.otp_required) {
          this.otpVerify = true;
          this.$nextTick(() => {
            this.$refs.otpForm.resetValidation();
            this.$refs.otpInput.focus();
          })
        }
        this.error = false;
        this.authErrorMessage = null;
        this.$emit('success');
      } catch (error) {
        this.ADD_NOTIFICATION({ ...error });
        this.error = true;
        this.authErrorMessage = error.message;
        resetCaptcha();
      } finally {
        this.requestLoading = false;
      }
    },
    async sendLink () {
      if (!this.$refs.resetLinkForm.validate()) {
        return;
      }

      this.resetPasswordLoading = true;
      try {
        const captchaResponse = this.handleCaptcha();
        if (typeof captchaResponse === 'boolean' && !captchaResponse) {
          return;
        }
        const resetPasswordPayload = { email: this.resetEmail.toLowerCase().trim() };
        if (typeof captchaResponse === 'string' && captchaResponse) {
          resetPasswordPayload.response = captchaResponse;
        }
        await authService.resetPasswordRequest(resetPasswordPayload);

        this.resetSent = true;
      } catch (error) {
        const notification = { ...error, status: 0 };
        this.ADD_NOTIFICATION(notification);
        resetCaptcha();
      } finally {
        this.resetPasswordLoading = false;
      }
    },
    resetLogin () {
      this.resetPass = false;
      this.resetSent = false;
    },
    enableResetPassword () {
      this.resetPass = true;
    },
    async otpVerifyHandler () {
      try {
        if (!this.$refs.otpForm.validate()) {
          return;
        }

        this.verificationLoading = true;

        const payload = {
          code_verifier: this.verificationCode,
          with_login: true,
          email: this.email.toLowerCase().trim(),
          password: this.password,
        };

        const response = await authService.otpVerify(payload);

        await this.setLoginInfo(response);
      } catch (error) {
        this.addNotification({ ...error, status: 0 });
      } finally {
        this.verificationLoading = false;
      }
    },
  },
};
</script>
