<template>
  <div id="company-form">
    <v-toolbar
      flat
      dark
      color="primary"
      class="panel-header"
    >
      <v-toolbar-title>{{ toolbarLabel }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon dark @click="cancelCompany">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card flat>
      <!-- stepper for new contacts -->
      <CompanyAddForm
        v-if="!edit"
        :source-type="sourceType"
        :source-id="sourceId"
        :is-open="isOpen"
        @closeDialog="closeDialog"
        @companyAdded="companyAdded"
        @cancelCompany="cancelCompany"
      />
      <!-- clean edit form to update contacts -->
      <CompanyEditForm
        v-else-if="edit && company._key"
        :edit="edit"
        :company="company"
        @cancel="cancelCompany"
        @updated="updatedHandler"
        @archiveCompany="archiveCompanyHandler"
      />
      <!-- / -->
    </v-card>
  </div>
</template>

<script>
import { eventBus } from '@/eventbus/eventbus';
import { ServiceFactory } from '@/services/ServiceFactory';
import { mapState } from 'vuex';

import CompanyAddForm from './CompanyAddForm';
import CompanyEditForm from './CompanyEditForm';

const CompanyService = ServiceFactory.get('company');

export default {
  name: 'CompanyForm',

  components: {
    CompanyAddForm,
    CompanyEditForm,
  },

  props: {
    company: {
      type: Object,
      default: () => null
    },
    edit: Boolean,
    sourceType: {
      type: String,
      default: ''
    },
    sourceId: {
      type: [String, Number],
      default: 0
    },
    isOpen: Boolean,
  },

  data: () => ({
    valid: false,
    showState: true,
  }),

  computed: {
    ...mapState({
      token: state => state.idToken,
    }),
    toolbarLabel () {
      if (this.sourceType === 'contact') {
        return 'Add contact to company 2';
      } else if (this.sourceType === 'company' && this.edit) {
        return 'Edit Company';
      }

      return 'Add Company';
    },
  },

  methods: {
    async archiveCompanyHandler (id) {
      try {
        const { data } = await CompanyService.update({
          token: this.token,
          id,
          payload: { active: false },
        });

        this.addSuccessNotification('Company archived successfully');
        this.$emit('updated', data);
      } catch (error) {
        this.addNotification({ ...error });
      }
    },
    closeDialog (company) {
      this.$emit('closeDialog', company);
    },
    companyAdded (company) {
      this.$emit('companyAdded', company);
    },
    cancelCompany () {
      this.$emit('cancelCompany');
      eventBus.$emit('clearCompany');
    },
    updatedHandler (company) {
      this.$emit('updated', company);
    },
    uploadSuccess (result) {
      // eslint-disable-next-line vue/no-mutating-props
      this.company.logo = result.filesUploaded[0].url;
    },
  },
};
</script>

<style scoped>
.company-logo {
  max-width: 200px;
}
</style>
