import Company from '@/bundles/Companies/models/Company';
import { getCompanyDefaultObj } from '@/bundles/Companies/helpers/companyDefaultData';

export default class CompanyToMapper {
  mapToModel (data: any): Company {
    const company = new Company(getCompanyDefaultObj() as any); // replace with CompanyCreateDto

    company.name = data?.name || '';
    company.address = {
      street: data?.geo?.streetAddress || '',
      street2: data?.geo?.subPremise || '',
      city: data?.geo?.city || '',
      state: data?.geo?.stateCode || '',
      country: data?.geo?.countryCode || '',
      postal: data?.geo?.postalCode || '',
      timezone_name: data?.geo?.timezone || '',
    };

    company.phone.office = data?.phone || '';
    company.phone.fax = null;

    company.industry_group = data?.category?.industryGroup || null;
    company.industry = data?.category?.industry || null;
    company.metrics = {
      employees: data?.metrics?.employees || '',
      annualRevenue: data?.metrics?.annualRevenue || '',
      estimatedAnnualRevenue: data?.metrics?.estimatedAnnualRevenue || '',
    };
    company.description = data?.description || '';
    company.year_established = data?.foundedYear || null;
    company.logo = data?.logo || null;

    return company;
  }
}
