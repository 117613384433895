import { render, staticRenderFns } from "./ContactAcceptImportVCF.vue?vue&type=template&id=0e1cf05f&"
import script from "./ContactAcceptImportVCF.vue?vue&type=script&lang=js&"
export * from "./ContactAcceptImportVCF.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports