<template>
  <v-form ref="form" lazy-validation>
    <v-skeleton-loader
      :loading="companyLoading"
      type="card-avatar, article, actions"
    >
      <v-card flat>
        <v-card-title> Company Details </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="tempCompany.name"
                label="Company Name"
                :rules="defaultRules"
                required
                autocomplete="off"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="tempCompany.url"
                label="URL"
                autocomplete="off"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="tempCompany.industry_group"
                label="Industry Group"
                :items="industryGroupTypes"
                clearable
                autocomplete="off"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="tempCompany.industry"
                :disabled="!tempCompany.industry_group"
                :items="companyIndustryTypes"
                label="Industry"
                clearable
                autocomplete="off"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="tempCompany.metrics.employees"
                label="Employees"
                autocomplete="off"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="tempCompany.metrics.annualRevenue"
                label="Annual Revenue"
                autocomplete="off"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="tempCompany.metrics.estimatedAnnualRevenue"
                label="Estimated Annual Revenue"
                autocomplete="off"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="tempCompany.year_established"
                label="Year Established"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                key="CompanyEditFormTextarea"
                v-model="tempCompany.description"
                label="Description"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-title>Company Contact Information</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="9">
              <v-text-field
                v-model="tempCompany.address.street"
                label="Address"
                data-1p-ignore
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="tempCompany.address.street2"
                label="Address 2"
                data-1p-ignore
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5">
              <v-text-field
                v-model="tempCompany.address.city"
                label="City"
                data-1p-ignore
              />
            </v-col>
            <v-col cols="12" sm="4">
              <StateSelect
                v-model="tempCompany.address.state"
                :country="tempCompany.address.country"
                :disabled="!showState"
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="tempCompany.address.postal"
                label="Postal Code"
                data-1p-ignore
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="tempCompany.address.country"
                :items="countries"
                label="Country"
                item-text="name"
                item-value="code"
                data-1p-ignore
                @change="tempCompany.address.state = null;"
              />
            </v-col>
            <v-col cols="6">
              <TimeZoneSelect v-model="tempCompany.address.timezone_name" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <CommonPhonePicker
                show-extension
                :phone-number="tempCompany.phone.office"
                :phone-extension="tempCompany.phone.office_ext"
                :phone-country-code="tempCompany.phone.office_country_code"
                @save:number="tempCompany.phone.office = $event"
                @save:extension="tempCompany.phone.office_ext = $event"
                @save:countryCode="tempCompany.phone.office_country_code = $event"
              >
                <template #default="{ on, attrs }">
                  <v-text-field
                    v-model="tempCompany.phone.office"
                    v-facade="getCountryMask(tempCompany.phone.office_country_code)"
                    :suffix="tempCompany.phone.office_ext ? `ext: ${tempCompany.phone.office_ext}` : null"
                    label="Office Phone"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
              </CommonPhonePicker>
            </v-col>
            <v-col cols="6">
              <CommonPhonePicker
                show-extension
                :phone-number="tempCompany.phone.fax"
                :phone-extension="tempCompany.phone.fax_ext"
                :phone-country-code="tempCompany.phone.fax_country_code"
                @save:number="tempCompany.phone.fax = $event"
                @save:extension="tempCompany.phone.fax_ext = $event"
                @save:countryCode="tempCompany.phone.fax_country_code = $event"
              >
                <template #default="{ on, attrs }">
                  <v-text-field
                    v-model="tempCompany.phone.fax"
                    v-facade="getCountryMask(tempCompany.phone.fax_country_code)"
                    :suffix="tempCompany.phone.fax_ext ? `ext: ${tempCompany.phone.fax_ext}` : null"
                    label="Office Fax"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
              </CommonPhonePicker>
            </v-col>
          </v-row>
        </v-card-text>
        <CompanyBrokerageSection
          v-model="tempCompany.brokerLicense"
          :is-brokerage.sync="tempCompany.isBrokerage"
        />
        <v-card-actions class="sc-navigation-drawer-actions">
          <v-btn
            v-if="tempCompany.active"
            class="archive"
            text
            :small="$vuetify.breakpoint.smAndDown"
            :disabled="loading"
            @click="archiveHandler"
          >
            Archive
          </v-btn>
          <v-spacer />
          <v-btn
            class="cancel"
            text
            :small="$vuetify.breakpoint.smAndDown"
            @click="cancelCompany"
          >
            Cancel
          </v-btn>
          <v-btn
            :small="$vuetify.breakpoint.smAndDown"
            color="primary"
            class="px-4"
            :loading="loading"
            @click="saveCompany"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-skeleton-loader>
  </v-form>
</template>

<script>
import { ServiceFactory } from '@/services/ServiceFactory';
import { detailedDiff } from 'deep-object-diff';
import { mapState } from 'vuex';
import { getCompanyDefaultObj } from '@/bundles/Companies/helpers/companyDefaultData';
import { AppFormMixin } from '@/bundles/App/mixins/forms/AppFormMixin';
import { CountriesMasksMixin } from '@/bundles/Common/mixins/CountriesMasksMixin';

import CompanyBrokerageSection from '@/bundles/Companies/components/forms/CompanyBrokerageSection.vue';
import CommonPhonePicker from '@/bundles/Common/components/CommonPhonePicker.vue';
import StateSelect from '@/bundles/App/components/inputs/StateSelect.vue';
import TimeZoneSelect from '@/bundles/App/components/inputs/TimeZoneSelect.vue';

const CompanyService = ServiceFactory.get('company');

export default {
  name: 'CompanyEditForm',

  components: {
    TimeZoneSelect,
    StateSelect,
    CommonPhonePicker,
    CompanyBrokerageSection
  },

  mixins: [AppFormMixin, CountriesMasksMixin],

  props: {
    company: {
      type: Object,
      default: () => {},
    },
    sourceId: {
      type: String,
      default: '',
    },
    edit: {
      type: [String, Boolean],
      default: false,
    },
  },

  data: () => ({
    valid: false,
    defaultRules: [(v) => !!v || 'Item is required'],
    showState: true,
    isCompanyBrokerage: false,
    dbCompany: {},
    tempCompany: getCompanyDefaultObj(),
    companyLoading: false,
    loading: false,
  }),

  computed: {
    ...mapState({
      token: (state) => state.idToken,
      industryGroupTypes: state => state.Company.industryGroupTypes,
      industryTypes: state => state.Company.industryTypes
    }),
    companyIndustryTypes () {
      const type = this.industryTypes.find(
        (item) => item.group === this.tempCompany.industry_group
      );
      return type ? type.types : [];
    },
    countries () {
      return this.$store.getters.countries;
    },
  },

  watch: {
    edit () {
      this.setCompany();
    },
  },

  mounted () {
    this.setCompany();
  },

  methods: {
    setCompany () {
      if (this.sourceId) {
        this.fetchCompany();
      } else {
        const stringData = JSON.stringify(this.company);
        this.dbCompany = JSON.parse(stringData);
        this.tempCompany = JSON.parse(stringData);
      }
    },
    fetchCompany: async function () {
      try {
        this.companyLoading = true;
        const { token } = this;
        const { data } = await CompanyService.get({ token, id: this.sourceId });
        const stringData = JSON.stringify(data);
        this.dbCompany = JSON.parse(stringData);
        this.tempCompany = JSON.parse(stringData);
        if (!this.tempCompany.metrics)
          this.tempCompany = Object.assign({}, this.tempCompany, {
            metrics: {
              employees: '',
              annualRevenue: '',
              estimatedAnnualRevenue: '',
            },
          });
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.companyLoading = false;
      }
    },
    async saveCompany () {
      try {
        if (!this.$refs.form.validate()) {
          this.showFormValidationErrorNotification();
          return;
        }

        this.loading = true;

        let changes = detailedDiff(this.dbCompany, this.tempCompany);
        let payload = Object.assign({}, changes.added, changes.updated, {
          date_update: Date.now(),
        });

        const updated = await CompanyService.update({
          token: this.token,
          payload,
          id: this.tempCompany._key,
        });

        this.addSuccessNotification('Company updated successfully');
        this.$emit('updated', updated.data);
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.loading = false;
      }
    },
    cancelCompany () {
      this.$emit('cancel');
    },
    archiveHandler () {
      confirm('Are you sure you want to archive this company?') && this.confirmArchive();
    },
    confirmArchive () {
      this.$emit('archiveCompany', this.tempCompany._key);
    }
  },
};
</script>

<style scoped>
.company-logo {
  max-width: 200px;
}
</style>
