import { render, staticRenderFns } from "./ImportFromVCard.vue?vue&type=template&id=8393bcf4&"
import script from "./ImportFromVCard.vue?vue&type=script&lang=js&"
export * from "./ImportFromVCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports