<template>
  <div>
    <v-card :elevation="showDetails ? `1` : `0`" v-bind="$attrs">
      <v-card-text class="mb-0 px-0">
        <v-list-item three-line>
          <v-list-item-avatar>
            <img :src="memberAvatar" alt="">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="memberName" />
            <v-list-item-subtitle v-if="memberCompany" class="member-company">
              {{ memberCompany }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>{{ contact.title }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="sourceType === 'company'">
              <div v-if="memberRoles.length" class="mt-3">
                <v-chip
                  v-for="role in getContactRoles(memberRoles)"
                  :key="role.text"
                  class="ma-1"
                  small
                >
                  {{ role.text }}
                </v-chip>
              </div>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="contact.active === false" class="mb-1">
              <CommonChip color="black" dark>Inactive</CommonChip>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="sourceType === 'deal'" class="d-flex align-center flex-wrap gap-2">
              <CommonChip v-if="contact.referring_agent" dense x-small>{{ $t('referring') }}</CommonChip>
              <CommonChip v-if="contact.receiving_agent" dense x-small>{{ $t('receiving') }}</CommonChip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="showDetails = !showDetails"><v-icon>{{ showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
      <v-divider v-if="showDetails" />
      <v-expand-transition>
        <v-card v-if="showDetails" flat class="mb-5">
          <v-card-text class="pa-0 grey lighten-5">
            <v-card-title class="py-0 pt-3">Contact Details</v-card-title>
            <v-btn-toggle
              v-if="showReferring"
              v-model="referringStatus"
              class="mx-5"
            >
              <v-btn small value="referring">Referring</v-btn>
              <v-btn small value="receiving">Receiving</v-btn>
            </v-btn-toggle>
            <v-list-item v-if="memberPhone" :href="`tel:${memberPhone}`">
              <v-list-item-action>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ memberPhone | formatPhone }}
                  <span v-if="memberPhoneExt" class="ml-1">Ext: {{ memberPhoneExt }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>Direct</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="memberMobile" :href="`tel:${memberMobile}`">
              <v-list-item-action>
                <v-icon>mdi-phone</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ memberMobile | formatPhone }}</v-list-item-title>
                <v-list-item-subtitle>Mobile</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="contact.email" :href="`mailto:${contact.email}`">
              <v-list-item-action>
                <v-icon>mdi-email</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ contact.email }}</v-list-item-title>
                <v-list-item-subtitle>Email</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-title class="py-0 mt-3">Actions</v-card-title>
            <v-list-item
              :to="getAccountRoute({
                name: 'contact',
                params: { id: contact._key }
              })"
            >
              <v-list-item-action>
                <v-icon>mdi-launch</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>View Contact Profile</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="showEditAction"
              :class="{ 'filters-action--disabled': isEditDisabled }"
              :title="isEditDisabled ? `${sourceLabel} contacts must be edited at the ${sourceLabel} level` : '' "
              @click="editContactRolesClickHandler"
            >
              <v-list-item-action>
                <v-icon :disabled="isEditDisabled">mdi-pencil</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Edit {{ sourceLabel }} Role(s)</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="sourceType === 'deal'"
              :disabled="refreshContactEdge === contact._edge"
              @click="$emit('refresh:contact', contact._edge)"
            >
              <v-list-item-action>
                <v-progress-circular
                  v-if="refreshContactEdge === contact._edge"
                  indeterminate
                  color="primary"
                  size="20"
                  width="1"
                />
                <v-icon v-else>mdi-refresh</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Refresh Contact Info
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :class="{ 'filters-action--disabled': isEditDisabled }"
              :title="isEditDisabled ? `${sourceLabel} contacts must be removed at the ${sourceLabel} level` : '' "
              @click="removeContactClickHandler(contact._key)"
            >
              <v-list-item-action>
                <v-icon :disabled="isEditDisabled">mdi-delete</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="sourceLabel">
                  Remove From {{ sourceLabel }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-divider />
          <v-card-text class="py-0">
            <v-list-item slot="activator" dense>
              <v-spacer />
              <v-list-item-action class="my-0">
                <v-btn icon @click="showDetails = !showDetails"><v-icon>{{ showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-expand-transition>
    </v-card>

    <v-dialog v-model="roleDialog" max-width="500">
      <v-form ref="form" @submit.prevent="saveRoles">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Edit Role(s)</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-select
              v-model="newRoles"
              :items="contactRoles"
              label="Select the roles for this contact"
              :rules="[(v) => v.length> 0 || 'Contact should have at least one role']"
              multiple
              autocomplete="off"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" type="submit" :loading="isRolesUpdating">Save Roles</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import { getContactRoles, arrToSnakeCase, ContactRolesEnum } from '@/bundles/Contact/enums/contactRoles';
import { sourceMappingDictionary } from '@/bundles/Contact/helpers/cards/contactsCard';

import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';

export default {
  name: 'ContactTile',

  components: {
    CommonChip
  },

  mixins: [AccountLayoutMixin],

  props: {
    contact: {
      type: Object,
      default: () => ({})
    },
    sourceType: {
      type: String,
      default: ''
    },
    contactRole: {
      type: String,
      default: ''
    },
    isRolesUpdating: Boolean,
    refreshContactEdge: {
      type: String,
      default: null
    }
  },

  data: () => ({
    roleDialog: false,
    newRoles: [],
    showDetails: false,
  }),

  computed: {
    ...mapGetters('Admin', ['getListByCategory']),
    showEditAction () {
      const sources = ['property', 'company', 'availability', 'pipeline', 'occupier', 'availabilityProperty', 'requirement'];
      const sourceType = this.sourceType === 'pipeline_primary_client' ? 'pipeline' : this.sourceType;

      return sources.some(source => source === sourceType);
    },
    isEditDisabled () {
      return this.sourceType === 'availabilityProperty';
    },
    sourceLabel () {
      const sources = ['property', 'company', 'availability', 'pipeline', 'occupier', 'requirement', 'deal', 'comparable', 'pipeline_primary_client', 'availabilityProperty'];

      const sourceType = sourceMappingDictionary[this.sourceType] || this.sourceType;

      if (sources.some(source => source === sourceType)) {
        return sourceType.charAt(0).toUpperCase() + sourceType.slice(1);
      }

      return '';
    },
    contactRoles () {
      const sources = ['property', 'company', 'pipeline', 'occupier', 'availability', 'requirement'];

      const sourceType = this.sourceType === 'pipeline_primary_client' ? 'pipeline' : this.sourceType;

      if (!sources.some(category => category === sourceType)) {
        return [];
      }

      const roles = this.getListByCategory(`${sourceType}_roles`).items;

      return getContactRoles(arrToSnakeCase(roles));
    },
    memberName: function () {
      return this.contact.first_name + ' <strong>' + this.contact.last_name + '</strong>';
    },
    memberCompany: function () {
      return this.contact.company?.name || '';
    },
    memberPhone () {
      return this.contact.phone?.direct || '';
    },
    memberPhoneExt () {
      return this.contact.phone?.direct_ext || '';
    },
    memberMobile () {
      return this.contact.phone?.mobile || '';
    },
    memberAvatar: function () {
      return this.contact.img || this.contact.avatar || this.contactPlaceholder;
    },
    memberRoles () {
      return arrToSnakeCase(this.contact.roles || []);
    },
    showReferring () {
      const allowedRoles = [
        ContactRolesEnum.landlord_agent,
        ContactRolesEnum.buyer_agent,
        ContactRolesEnum.seller_agent,
        ContactRolesEnum.tenant_agent,
        ContactRolesEnum.consulting_agent,
      ]
      return allowedRoles.includes(this.contactRole);
    },
    referringStatus: {
      get () {
        if (this.contact.receiving_agent) {
          return 'receiving';
        }
        if (this.contact.referring_agent) {
          return 'referring';
        }
        return '';
      },
      set (value) {
        const payload = {
          _edge: this.contact._edge,
          contactId: this.contact._key,
          referral: value
        }
        this.$emit('updateReferral', payload)
        return true;
      }
    }
  },

  watch: {
    isRolesUpdating (newVal, oldValue) {
      if (!newVal && oldValue) {
        this.roleDialog = false;
      }
    },
    memberRoles: {
      handler (newVal) {
        this.newRoles = newVal;
      },
      deep: true,
      immediate: true,
    }
  },

  methods: {
    getContactRoles (roles) {
      return getContactRoles(roles);
    },
    saveRoles () {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$emit('updateRoles', { ...this.contact, roles: this.newRoles });
    },
    editContactRolesClickHandler () {
      if (this.isEditDisabled) {
        return false;
      }

      this.roleDialog = true;
    },
    removeContactClickHandler (key) {
      if (this.isEditDisabled) {
        return false;
      }

      this.$emit('removeContact', key);
    },
  },
}
</script>

<style lang="scss">
.filters-action--disabled {
  color: rgba(0,0,0,.38) !important;
}
</style>
