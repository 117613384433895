
import Vue, { CreateElement, PropType, RenderContext, VNode } from 'vue';
import { formatCurrency, formatDate } from '@/utils/filters';
import { IOpenInvoiceListItem } from '@/bundles/Invoice/types';
import { getAccountRouteHandler } from '@/bundles/App/helpers';

import Label from '@/bundles/Common/components/Label.vue';

export default Vue.extend({
  name: 'OpenInvoiceMobileCard',

  functional: true,

  props: {
    item: {
      type: Object as PropType<IOpenInvoiceListItem>,
      required: true
    },
    activeAccountId: {
      type: Number,
      required: true
    },
    selected: Boolean,
  },

  render (h: CreateElement, { props, data, listeners }: RenderContext<{ item: IOpenInvoiceListItem; activeAccountId: number; selected: boolean; }>): VNode | VNode[] {
    const getCompanyNames = (contacts: { name: string }[]): string => (contacts.map(item => item.name).join(', ') || '');

    return h('v-card', {
      props: { outlined: true },
      class: `open-invoice-mobile-card  ${data.class || ''} ${data.staticClass || ''} ${props.selected ? 'open-invoice-mobile-card--selected' : ''}`.trim(),
      attrs: { ...data.attrs },
      on: {
        ...data.on,
        click: () => (listeners['click:select'] as Function)(),
      },
    }, [
      h('div', { class: 'd-flex align-center pa-3', }, [
        h('router-link', {
          class: 'open-invoice-mobile-card__title app-link',
          props: {
            to: getAccountRouteHandler({
              name: 'deal',
              params: { id: props.item.deal._key },
              activeAccountId: props.activeAccountId,
            })
          },
          nativeOn: {
            click: (e: Event) => e.stopPropagation()
          }
        }, props.item.deal.name),
      ]),
      h('v-divider'),
      h('div', { class: 'pa-3 d-flex flex-column gap-3' }, [
        h('div', { class: 'd-flex gap-3' }, [
          h('div', { class: 'open-invoice-mobile-card__info' }, [
            h('div', { class: 'open-invoice-mobile-card__info-label open-invoice-mobile-card__invoice-amount-label' }, 'Invoice Amount:'),
            h('div', { class: 'open-invoice-mobile-card__invoice-amount' }, formatCurrency(props.item.amount))
          ]),
          h('div', { class: 'open-invoice-mobile-card__info' }, [
            h('div', { class: 'open-invoice-mobile-card__info-label open-invoice-mobile-card__brokers-label' }, 'Brokers:'),
            h('div', { class: 'open-invoice-mobile-card__brokers' }, [
              h(Label, { props: { value: props.item.deal.brokers.map(broker => `${broker.first_name} ${broker.last_name}`).join(', ').trim() || null } }),
            ])
          ])
        ]),
        h('div', { class: 'd-flex gap-3' }, [
          h('div', { class: 'open-invoice-mobile-card__info' }, [
            h('div', { class: 'open-invoice-mobile-card__info-label open-invoice-mobile-card__due-date-label' }, 'Due Date:'),
            h('div', { class: 'open-invoice-mobile-card__due-date' }, formatDate(props.item.due_date))
          ]),
          h('div', { class: 'open-invoice-mobile-card__info' }, [
            h('div', { class: 'open-invoice-mobile-card__info-label open-invoice-mobile-card__buyer-tenant-label' }, 'Buyer/Tenant:'),
            h('div', { class: 'open-invoice-mobile-card__buyer-tenant' }, [
              h(Label, { props: { value: getCompanyNames(props.item.buyer_tenant) } }),
            ])
          ])
        ]),
        h('div', { class: 'd-flex gap-3' }, [
          h('div', { class: 'open-invoice-mobile-card__info' }, [
            h('div', { class: 'open-invoice-mobile-card__info-label open-invoice-mobile-card__outstanding-label' }, 'Outstanding:'),
            h('div', { class: 'open-invoice-mobile-card__remaining open-invoice-mobile-card__outstanding' }, formatCurrency(props.item.remaining))
          ]),
          h('div', { class: 'open-invoice-mobile-card__info' }, [
            h('div', { class: 'open-invoice-mobile-card__info-label open-invoice-mobile-card__seller-landlord-label' }, 'Seller/Landlord:'),
            h('div', { class: 'open-invoice-mobile-card__seller-landlord' }, [
              h(Label, { props: { value: getCompanyNames(props.item.seller_landlord) } })
            ])
          ])
        ])
      ])
    ])
  }
});
