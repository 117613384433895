import { FilterAllExcludeFilterEnum } from '@/bundles/Filters/enums/transactionType';
import { TaskStatusEnum } from '@/bundles/Tasks/enums/TaskStatusEnum';

export const getTypeLogo = (type: string) => {
  switch (type) {
    case 'company':
      return 'mdi-city';
    case 'contact':
      return 'mdi-contacts';
    case 'occupier':
      return 'mdi-account-supervisor-circle';
    case 'requirement':
      return 'mdi-check-all';
    case 'property':
      return 'mdi-domain';
    case 'availability':
      return 'mdi-map-marker-radius';
    case 'comparable':
      return 'mdi-compare';
    case 'project':
      return 'mdi-clipboard-text';
    case 'pipeline':
      return 'mdi-pipe';
    case 'deal':
      return 'mdi-currency-usd';
    default:
      return 'mdi-city';
  }
}

export const getTaskStatusFilter = (status: FilterAllExcludeFilterEnum): TaskStatusEnum[] => {
  if (status === FilterAllExcludeFilterEnum.all) {
    return [TaskStatusEnum.overdue, TaskStatusEnum.pending, TaskStatusEnum.completed];
  }

  if (status === FilterAllExcludeFilterEnum.filter) {
    return [TaskStatusEnum.completed];
  }

  // status === FilterAllExcludeFilterEnum.exclude and default status
  return [TaskStatusEnum.overdue, TaskStatusEnum.pending];
};
