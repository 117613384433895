import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import PRRequestToMapper from '@/bundles/Requests/mappers/PRRequestToMapper';

const errorMapper = new ErrorMapper();
const resource = '/pressrelease';

export default {
  list: async function ({ token, params, controller = null }) {
    try {
      const paramsString = generateParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (controller) {
        config = { ...config, signal: controller.signal };
      }

      return await instance.get(`${resource}/list${paramsString}`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getByApprovalKey: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/approve/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  submitRequestApproval: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/process-approval`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRequests: async function ({ token }) {
    try {
      const { data } = await await instance.get(`${resource}/requests`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return (data || []).map((item) => new PRRequestToMapper().mapToModel(item));
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  sendTest: async function ({ token, id, payload }) {
    try {
      return await instance.post(`${resource}/${id}/test`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  send: async function ({ token, id, payload }) {
    try {
      return await instance.post(`${resource}/${id}/send`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  requestApproval: async function ({ token, payload, id }) {
    try {
      return await instance.post(`${resource}/${id}/requestApproval`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ token, payload, id }) {
    try {
      return await instance.patch(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addRecipients: async function ({ token, payload, id }) {
    try {
      return await instance.patch(`${resource}/${id}/addRecipients`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addRequestRecipients: async function ({ token, payload, id }) {
    try {
      return await instance.patch(`${resource}/${id}/addRequestRecipients`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeRecipients: async function ({ token, payload, id }) {
    try {
      return await instance.patch(`${resource}/${id}/removeRecipients`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  cancel: async function ({ token, id }) {
    try {
      return await instance.patch(`${resource}/cancel/${id}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  deny: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/process-deny`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  }
};
