











































import mixins from 'vue-typed-mixins';
import { VCardSectionMixin } from '@/bundles/Contact/mixins/VCardSectionMixin';

export default mixins(VCardSectionMixin).extend({
  name: 'VCardImportPhoneInfo',
});
