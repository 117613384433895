import instance from '../instance';
import { generateObjectParams, generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/deal';

export default {
  list: async function ({ params = [], token, onDownloadEventHandler = null, controller = null }) {
    try {
      const queryParams = generateObjectParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: queryParams
      };
      if (onDownloadEventHandler) {
        config = Object.assign(config, {
          onDownloadProgress: onDownloadEventHandler,
        });
      }
      if (controller) {
        config = { ...config, signal: controller.signal };
      }
      return await instance.get(`${resource}/list`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  loadImportReData: async function ({ token, onDownloadEventHandler = null, params = [] }) {
    try {
      let paramsString = generateParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (onDownloadEventHandler) {
        config = Object.assign(config, {
          onDownloadProgress: onDownloadEventHandler,
        });
      }
      return await instance.get(`${ resource }/re-import-data${ paramsString }`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRecord: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/record`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getBrokerGross: async function ({ params = [], token }) {
    try {
      let paramsString = generateParams(params);
      return await instance.get(`${resource}/brokerGross${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRecordDeals: async function ({ token, id, source, params }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${source}/${id}/deals${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getDealTotals: async function ({ params = [], token }) {
    try {
      let paramsString = generateParams(params);
      return await instance.get(`${resource}/totals${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getShared: async function (id) {
    try {
      return await instance.get(`${resource}/${id}/shared`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  unarchive: async function ({ token, id }) {
    try {
      return await instance.patch(`${resource}/${id}/unarchive`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addContact: async function ({ id, token, payload }) {
    try {
      return await instance.post(`${resource}/${id}/contact`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeContact: async function ({ id, token, contact }) {
    try {
      return await instance.delete(`${resource}/${id}/contact/${contact}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateTransactionDate: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/transactionDate`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateContact: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/contact`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateCompany: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/company`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addCompany: async function ({ id, token, payload }) {
    try {
      return await instance.post(`${resource}/${id}/company`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeCompany: async function ({ id, token, companyId }) {
    try {
      return await instance.delete(`${resource}/${id}/company/${companyId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateBrokers: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/brokers`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateBroker: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/broker`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  approve: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/approve`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  saveInvoice: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/invoice`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  resetInvoices: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/resetInvoices`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archiveInvoice: async function ({ id, token, invoiceId }) {
    try {
      return await instance.delete(`${resource}/${id}/invoice/${invoiceId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  editInvoice: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/invoice`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getPressRelease: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/pressRelease`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  savePressRelease: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/pressRelease`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  sendMultipleDealsPressRelease: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/send-multiple-press-release`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateMarketing: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/marketing`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateConsideration: async function ({ id, token }) {
    try {
      return await instance.patch(`${resource}/${id}/updateConsideration`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  markAsSent: async function ({ token, payload, id }) {
    try {
      return await instance.patch(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getBrokerGrossReportBroker: async function ({ token, params = [] }) {
    try {
      let paramsString = generateParams(params);
      return await instance.get(`${resource}/brokerGrossReportBroker${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  exportBrokerGrossReportBroker: async function ({ token, body = [] }) {
    try {
      return await instance.post(`${resource}/broker-gross-report/export`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  exportPaymentReport: async function ({ token, body = [] }) {
    try {
      return await instance.post(`${resource}/payment-report/export`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getPaymentReport: async function ({ token, params = [] }) {
    try {
      let paramsString = generateParams(params);
      return await instance.get(`${resource}/brokerGrossReportBroker${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  brokerDeals: async function ({ token, params = [] }) {
    try {
      let paramsString = generateParams(params);
      return await instance.get(`${resource}/brokerDeals${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  exportBrokerGross: async function ({ params = [], token, id }) {
    try {
      let paramsString = generateParams(params);
      return await instance.get(`${resource}/broker-deals/${ id }/export${ paramsString }`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  exportAllBrokersGross: async function ({ params = [], token }) {
    try {
      let paramsString = generateParams(params);
      return await instance.get(`${resource}/broker-deals/export${ paramsString }`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateMetadata: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/metadata`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  refreshContact: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/contact/refresh`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  }
};
