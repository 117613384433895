






















import Vue from 'vue';

export default Vue.extend({
  name: 'ContactPublishToWebSwitch',

  props: {
    value: Boolean,
    isInternal: Boolean,
  },

  computed: {
    publishedToWeb: {
      get (): boolean {
        return this.value;
      },
      set (value: boolean) {
        this.$emit('input', value)
      }
    },
    isEditAllowed (): boolean {
      // @ts-ignore
      return this.isAdmin && this.isInternal;
    },
    tooltipText (): string {
      // @ts-ignore
      if (!this.isAdmin) {
        return 'You are not an admin and cannot publish contacts to the web.';
      }

      if (!this.isInternal) {
        return 'This contact is not internal and cannot be published to the web.';
      }

      return '';
    }
  }
})
