// TODO: rewrite that service to common schema + change name to be ContactServiceLegacy
import HttpTransport from '@/bundles/Http/transport/HttpTransport';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import { Nullable } from '@/utils/types';

export default class ContactService {
  private readonly transport: HttpTransport;
  private readonly errorMapper: ErrorMapper;

  constructor (transport: HttpTransport, errorMapper: ErrorMapper) {
    this.transport = transport;
    this.errorMapper = errorMapper;
  }

  async getContactForMember (id: number, legacy_id?: Nullable<string>): Promise<any> {
    try {
      return await this.transport.get(`/contact/member/${ id }${legacy_id ? `?legacy_id=${ legacy_id }` : ''}`);
    } catch (error) {
      throw this.errorMapper.mapToCodeModel(error);
    }
  }
}
