import { IAccountPreferences } from '@/bundles/Account/types';
import { Nullable } from '@/utils/types';
import { placeholderCompanyImg, placeholderContactImg, placeholderPropertyImg } from '@/settings';

export default class AccountPreferences implements IAccountPreferences {
  company_placeholder: Nullable<string>;
  contact_placeholder: Nullable<string>;
  marker_color: Nullable<string>;
  primary_color: Nullable<string>;
  property_placeholder: Nullable<string>;
  secondary_color: Nullable<string>;

  constructor (data: IAccountPreferences) {
    this.company_placeholder = data.company_placeholder || placeholderCompanyImg;
    this.contact_placeholder = data.contact_placeholder || placeholderContactImg;
    this.marker_color = data.marker_color || null;
    this.primary_color = data.primary_color || null;
    this.property_placeholder = data.property_placeholder || placeholderPropertyImg;
    this.secondary_color = data.secondary_color || null;
  }
}
