import Vue, { PropType } from 'vue';
import { ITextValue } from '@/types/common';
import { IParsedVCardData } from '@/bundles/Contact/interfaces/IVCardParsedObj';

import VCardImportFormSection from '@/bundles/Contact/components/vcard/VCardImportFormSection.vue';
import VCardImportSelect from '@/bundles/Contact/components/vcard/VCardImportSelect.vue';

export const VCardSectionMixin = Vue.extend({
  components: { VCardImportSelect, VCardImportFormSection },

  props: {
    value: {
      type: Object as PropType<IParsedVCardData>,
      required: true,
    },
    items: {
      type: Array as PropType<ITextValue<string>[]>,
      default: () => [],
    }
  },

  computed: {
    parsedData: {
      get (): IParsedVCardData {
        return this.value;
      },
      set (value: IParsedVCardData) {
        this.$emit('input', value);
      }
    },
  }
});
