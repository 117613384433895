<template>
  <!-- EDIT CONTACT FORM && ADD NEW FROM SCRATCH -->
  <v-form ref="form" lazy-validation>
    <v-card v-if="loaded" flat>
      <v-card-text class="text-center">
        <div style="border: dashed 1px #ccc">
          <DragAndDropFile @processFile="handleProcessFile" />
        </div>
      </v-card-text>
      <v-card-title>Contact Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="editContact.first_name"
              label="First Name"
              :rules="defaultRules"
              required
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="editContact.last_name"
              label="Last Name"
              :rules="defaultRules"
              required
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="editContact.nickname"
              label="Nickname"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-combobox
              v-model="editContact.designation"
              :search-input.sync="search"
              label="Add or remove designations for broker"
              multiple
              hide-details
              hide-no-data
            >
              <template #selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  color="#e0e0e0"
                  small
                >
                  <span class="pr-2">
                    {{ item }}
                  </span>
                  <v-icon small @click="parent.selectItem(item)">
                    $delete
                  </v-icon>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="editContact.title" label="Title" />
          </v-col>
        </v-row>
        <v-row v-if="source">
          <v-col cols="12">
            <v-text-field
              v-model="editContact.company.name"
              label="Company Name"
              disabled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <CommonPhonePicker
              show-extension
              :phone-country-code="editContact.phone.direct_country_code"
              :phone-number="editContact.phone.direct"
              :phone-extension="editContact.phone.direct_ext"
              @save:number="editContact.phone.direct = $event"
              @save:countryCode="editContact.phone.direct_country_code = $event"
              @save:extension="editContact.phone.direct_ext = $event"
            >
              <template #default="{ on, attrs }">
                <v-text-field
                  v-model="editContact.phone.direct"
                  v-facade="getCountryMask(editContact.phone.direct_country_code)"
                  label="Direct Phone"
                  :suffix="editContact.phone.direct_ext ? `ext: ${editContact.phone.direct_ext}` : null"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
            </CommonPhonePicker>
          </v-col>
          <v-col cols="6">
            <CommonPhonePicker
              show-extension
              :phone-country-code="editContact.phone.mobile_country_code"
              :phone-number="editContact.phone.mobile"
              :phone-extension="editContact.phone.mobile_ext"
              @save:number="editContact.phone.mobile = $event"
              @save:countryCode="editContact.phone.mobile_country_code = $event"
              @save:extension="editContact.phone.mobile_ext = $event"
            >
              <template #default="{ on, attrs }">
                <v-text-field
                  v-model="editContact.phone.mobile"
                  v-facade="getCountryMask(editContact.phone.mobile_country_code)"
                  label="Mobile Phone"
                  :suffix="editContact.phone.mobile_ext ? `ext: ${editContact.phone.mobile_ext}` : editContact.phone.mobile_ext"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
            </CommonPhonePicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="editContact.email" label="Email Address" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>
        Office Address
        <v-spacer />
        <v-checkbox
          v-model="editContact.office_address_same_as_company"
          label="Same as company"
          :disabled="!editContact.company"
          hide-details
          class="mt-0 pt-0"
        />
      </v-card-title>
      <v-card-text v-if="editContact.office_address">
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="editContact.office_address.street"
              class="pt-0"
              :disabled="editContact.office_address_same_as_company"
              label="Address"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="editContact.office_address.street2"
              class="pt-0"
              :disabled="editContact.office_address_same_as_company"
              label="Address 2"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="editContact.office_address.city"
              :disabled="editContact.office_address_same_as_company"
              label="City"
            />
          </v-col>
          <v-col cols="12" sm="5">
            <StateSelect
              v-model="editContact.office_address.state"
              :country="editContact.office_address.country"
              :disabled="editContact.office_address_same_as_company"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="editContact.office_address.postal"
              :disabled="editContact.office_address_same_as_company"
              label="Postal"
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="editContact.office_address.country"
              :disabled="editContact.office_address_same_as_company"
              :items="countries"
              label="Country"
              item-text="name"
              item-value="code"
              hide-details
              @change="editContact.office_address.state = null;"
            />
          </v-col>
          <v-col cols="12">
            <TimeZoneSelect
              v-model="editContact.office_address.timezone_name"
              :disabled="editContact.office_address_same_as_company"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <CommonPhonePicker
              show-extension
              :phone-country-code="editContact.phone.office_country_code"
              :phone-number="editContact.phone.office"
              :phone-extension="editContact.phone.office_ext"
              @save:number="editContact.phone.office = $event"
              @save:countryCode="editContact.phone.office_country_code = $event"
              @save:extension="editContact.phone.office_ext = $event"
            >
              <template #default="{ on, attrs }">
                <v-text-field
                  v-model="editContact.phone.office"
                  v-facade="getCountryMask(editContact.phone.office_country_code)"
                  :disabled="editContact.office_address_same_as_company"
                  label="Office Phone"
                  :suffix="editContact.phone.office_ext ? `ext: ${editContact.phone.office_ext}` : editContact.phone.office_ext"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
            </CommonPhonePicker>
          </v-col>
          <v-col cols="6">
            <CommonPhonePicker
              show-extension
              :phone-country-code="editContact.phone.fax_country_code"
              :phone-number="editContact.phone.fax"
              :phone-extension="editContact.phone.fax_ext"
              @save:number="editContact.phone.fax = $event"
              @save:countryCode="editContact.phone.fax_country_code = $event"
              @save:extension="editContact.phone.fax_ext = $event"
            >
              <template #default="{ on, attrs }">
                <v-text-field
                  v-model="editContact.phone.fax"
                  v-facade="getCountryMask(editContact.phone.fax_country_code)"
                  label="Fax"
                  v-bind="attrs"
                  :suffix="editContact.phone.fax_ext ? `ext: ${editContact.phone.fax_ext}` : editContact.phone.fax_ext"
                  :disabled="editContact.office_address_same_as_company"
                  v-on="on"
                />
              </template>
            </CommonPhonePicker>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title> Home Address </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="editContact.home_address.street"
              class="pt-0"
              label="Address"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="editContact.home_address.city"
              label="City"
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="5">
            <StateSelect
              v-model="editContact.home_address.state"
              :country="editContact.home_address.country"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="editContact.home_address.postal"
              label="Postal"
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              v-model="editContact.home_address.country"
              :items="countries"
              label="Country"
              item-text="name"
              item-value="code"
              @change="editContact.home_address.state = null;"
            />
          </v-col>
        </v-row>
        <v-row>
          <!--          TODO: ask Kevin about it, doesn't save at all-->
          <v-col cols="12" sm="6">
            <CommonPhonePicker
              :phone-number="editContact.home_phone"
              @save:number="editContact.home_phone = $event"
            >
              <template #default="{ on, attrs }">
                <v-text-field
                  v-model="editContact.home_phone"
                  v-facade="getCountryMask(null)"
                  label="Home Phone"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
            </CommonPhonePicker>
          </v-col>
          <v-col v-if="editContact.company.is_account_company" cols="12">
            <ContactPublishToWebSwitch
              v-model="editContact.published_to_web"
              :is-internal="editContact.is_internal"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <AppPrivacy
        v-model="editContact.permissions"
        :disabled="!isPermissionsChangeAllowed"
      />
      <v-card-actions class="sc-navigation-drawer-actions">
        <v-btn
          v-if="editContact.active"
          class="archive"
          text
          :small="$vuetify.breakpoint.smAndDown"
          :disabled="loading"
          @click="archive"
        >
          Archive
        </v-btn>
        <v-btn
          v-else
          class="archive"
          text
          :disabled="loading"
          :small="$vuetify.breakpoint.smAndDown"
          @click="activate"
        >
          Restore
        </v-btn>
        <v-spacer />
        <v-btn
          class="cancel"
          :disabled="loading"
          text
          :small="$vuetify.breakpoint.smAndDown"
          @click="cancelContact"
        >
          Cancel
        </v-btn>
        <v-btn
          :small="$vuetify.breakpoint.smAndDown"
          color="primary"
          :loading="loading"
          class="px-4"
          @click="updateContact"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="isSelectFieldsWindowShown"
      persistent
      max-width="600px"
      transition="dialog-bottom-transition"
      fullscreen
    >
      <ContactAcceptImportVCF
        :records="contactsFromVCF"
        :contact="contact"
        @closeWindow="cancelImport"
      />
    </v-dialog>
  </v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ServiceFactory } from '@/services/ServiceFactory';
import { eventBus } from '@/eventbus/eventbus';
import { convertFromVCF } from '@/bundles/Contact/helpers/vcard/parseVCF';
import { AppFormMixin } from '@/bundles/App/mixins/forms/AppFormMixin';
import { getDefaultContact } from '@/bundles/Contact/helpers/getDefaultContact';
import { CountriesMasksMixin } from '@/bundles/Common/mixins/CountriesMasksMixin';

import DragAndDropFile from '@/components/common-components/DragAndDropFile';
import ContactAcceptImportVCF from '@/components/contacts/ContactAcceptImportVCF';
import CommonPhonePicker from '@/bundles/Common/components/CommonPhonePicker';
import AppPrivacy from '@/bundles/App/components/AppPrivacy';
import StateSelect from '@/bundles/App/components/inputs/StateSelect.vue';
import ContactPublishToWebSwitch from '@/bundles/Contact/components/inputs/ContactPublishToWebSwitch.vue';
import TimeZoneSelect from '@/bundles/App/components/inputs/TimeZoneSelect.vue';

const ContactService = ServiceFactory.get('contact');

export default {
  name: 'ContactEditForm',

  components: {
    TimeZoneSelect,
    ContactPublishToWebSwitch,
    StateSelect,
    CommonPhonePicker,
    DragAndDropFile,
    ContactAcceptImportVCF,
    AppPrivacy
  },

  mixins: [AppFormMixin, CountriesMasksMixin],

  props: {
    source: {
      type: Object,
      default: () => ({}),
    },
    sourceType: {
      type: String,
      default: '',
    },
    sourceId: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    contactsFromVCF: {},
    isSelectFieldsWindowShown: false,
    loaded: true,
    search: null,
    valid: false,
    defaultRules: [(v) => !!v || 'Item is required'],
    dbContact: {},
    editContact: getDefaultContact(),
    loading: false
  }),

  computed: {
    ...mapState({
      token: (state) => state.idToken,
      currentUserId: (state) => state.userId
    }),
    ...mapGetters(['countries']),
    isPermissionsChangeAllowed: function () {
      return this.isSuperAdmin || this.editContact.created?._id === this.currentUserId;
    },
  },

  watch: {
    edit () {
      this.setContact();
    },
    'editContact.office_address_same_as_company' (value) {
      if (value && this.contact.company) {
        const { city, postal, state, street, street2, timezone_name, country } = this.contact.company.address;
        const { office, office_country_code, fax, fax_country_code } = this.contact.company.phone;

        this.editContact.office_address = { city, postal, state, street, street2, country, timezone_name };
        this.editContact.phone = {
          ...this.editContact.phone,
          office,
          office_country_code,
          fax,
          fax_country_code,
        };
      }
    }
  },

  created () {
    this.setContact();
  },

  methods: {
    cancelImport () {
      this.isSelectFieldsWindowShown = false;
      this.clicked = false;
      this.$emit('cancelContact');
    },
    handleProcessFile (file) {
      convertFromVCF(file, (error, data) => {
        if (error) {
          this.addNotification({
            title: 'Error',
            message: 'Error while parsing file',
            status: 0,
          });
        } else {
          this.callSelectFieldsWindow(data);
        }
      });
    },
    callSelectFieldsWindow ({ items, ...parsedData }) {
      this.contactsFromVCF = parsedData;
      this.isSelectFieldsWindowShown = true;
    },
    setContact () {
      if (this.sourceId) {
        this.fetchContact();
      } else {
        const stringData = JSON.stringify(this.contact);
        this.dbContact = JSON.parse(stringData);
        this.editContact = JSON.parse(stringData);
        this.loaded = true;
      }
    },
    async updateContact () {
      if (!this.$refs.form.validate()) {
        this.showFormValidationErrorNotification();
        return;
      }

      try {
        this.loading = true;
        let { data } = await ContactService.update({
          token: this.token,
          payload: this.editContact,
          id: this.editContact._key,
        });

        this.$emit('updateContact', data);
        this.addSuccessNotification('Contact updated successfully');
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.loading = false;
      }
    },
    cancelContact () {
      this.$emit('cancelContact');
    },
    archive () {
      eventBus.$emit('archiveContactRequest');
    },
    activate () {
      eventBus.$emit('restoreContactRequest');
    },
    fetchContact: async function () {
      try {
        const { data } = await ContactService.get({
          token: this.token,
          id: this.sourceId,
        });
        this.dbContact = JSON.parse(JSON.stringify(data[0]));
      } catch (error) {
        this.addNotification({ ...error });
      }
    },
  },
};
</script>
