import instance from '../instance';
import { generateObjectParams, generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/property';

export default {
  list: async function ({ params = [], token, onDownloadEventHandler = null, controller = null }) {
    try {
      const queryParams = generateObjectParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: queryParams,
      };
      if (onDownloadEventHandler) {
        config = Object.assign(config, {
          onDownloadProgress: onDownloadEventHandler,
        });
      }
      if (controller) {
        config = { ...config, signal: controller.signal };
      }
      return await instance.get(`${resource}/list`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getOccupiers: async function ({ id, token, params }) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/${id}/occupiers${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateLocation: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}/location`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getContacts: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/contacts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addContact: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/addContact`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addPhoto: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/addphoto/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  copy: async function ({ token, id }) {
    try {
      return await instance.post(`${resource}/${id}/copy`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  listShared: async function (params = []) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/shared/list${paramsString}`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getShared: async function (id) {
    try {
      return await instance.get(`${resource}/${id}/shared`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getProperties: async function ({ token, params }) {
    try {
      return await instance.get(`${resource}/${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeExpense: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/removeExpense/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  setPrimaryPhoto: async function ({ id, token, payload, params }) {
    try {
      const paramsString = generateParams(params);

      return await instance.put(`${resource}/setPrimaryPhoto/${id}/${paramsString}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removePhoto: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/removeExpense/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  saveExpense: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/addExpense/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateExpense: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/updateExpense/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getExpenses: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/expenses/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getAvailabilities: async function ({ id, token, params }) {
    const paramsString = generateParams(params);
    try {
      return await instance.get(`${resource}/${id}/availabilities${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getAvailability: async function ({ id, token, availabilityId }) {
    try {
      return await instance.get(`${resource}/${id}/availabilities/${availabilityId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeOccupier: async function ({ id, token, occupierId }) {
    try {
      return await instance.delete(`${resource}/${id}/occupier/${occupierId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateLocationByAddress: async function ({ token, id }) {
    try {
      return await instance.patch(`${resource}/${id}/updateCoordinatesByAddress`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  saveValuation: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/valuation`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getMarkets: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/markets`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  refreshMarkets: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/markets/refresh`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getPostalCodes: async function ({ token, code }) {
    try {
      return await instance.get(`${resource}/postal/${code}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getStates: async function ({ token, keyword }) {
    try {
      return await instance.get(`${resource}/states/${keyword}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getParcel: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/parcel`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  merge: async function ({ token, id, payload }) {
    try {
      return await instance.post(`${resource}/${id}/merge`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addCompany: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/add-company`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeCompany: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/remove-company`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
