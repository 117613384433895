




















































import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ContactSelectItem',

  props: {
    item: {
      type: Object as PropType<any>, // TODO: add proper type when model created
      required: true
    },
    disabled: Boolean,
    selected: Boolean
  },

  methods: {
    clickHandler () {
      if (!this.disabled) {
        this.$emit('toggleSelect', this.item._id);
      }
    }
  }
});
