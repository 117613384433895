import axios, { AxiosRequestConfig } from 'axios';
import store from '@/store/store';
import { RootMutations } from '@/store/types/mutation-types';

export function requestInterceptor (config: AxiosRequestConfig): AxiosRequestConfig {
  const token = store.state.idToken || store.state.temporaryAuthToken;
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${ token }`,
    };
  }
  if (store.state.visitorId) {
    config.headers = {
      ...config.headers,
      'visitor-id': store.state.visitorId!,
    };
  }
  if (config.method?.toLowerCase() === 'get') {
    const controller = new AbortController();
    store.commit(RootMutations.ADD_CANCEL_TOKEN, controller);

    config.signal = config.signal
      ? (AbortSignal as any).any([config.signal, controller.signal]) // handle both signals
      : controller.signal;
  }
  return config;
}
