



















































































































































































































































































































import { mapState } from 'vuex';
import mixins from 'vue-typed-mixins';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import {
  getComparableTransactionImageLabel,
  getComparableTransactionTypeLabel
} from '@/bundles/Comparables/helpers/comparableTransaction';
import { getComparableLeaseTermLength } from '@/bundles/Comparables/helpers';
import { getNBRPropertyTypeClass } from '@/bundles/Property/helpers/colors/getNBRPropertyTypeClass';
import { getDiffBetweenDates } from '@/utils/filters';
import { roundToDecimal } from '@/bundles/App/helpers/mathHelpers';

import Photo from '@/components/common-components/photo/Photo.vue';
import LandSizeLabel from '@/bundles/MeasurementUnits/components/LandSizeLabel.vue';
import BuildingSizeLabel from '@/bundles/MeasurementUnits/components/BuildingSizeLabel.vue';
import POLIcon from '@/bundles/Comparables/components/POLIcon.vue';
import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';
import Label from '@/bundles/Common/components/Label.vue';
import AvailabilityChipsWrapper from '@/bundles/Availabilities/components/chips/AvailabilityChipsWrapper.vue';
import ComparableSalesPriceLabel from '@/bundles/Comparables/components/labels/ComparableSalesPriceLabel.vue';
import ConfidentialChip from '@/bundles/Comparables/components/confidential/ConfidentialChip.vue';

export default mixins(AccountLayoutMixin).extend({
  name: 'ComparablesListTableContent',

  components: {
    ConfidentialChip,
    ComparableSalesPriceLabel,
    AvailabilityChipsWrapper,
    Label,
    POLIcon,
    Photo,
    LandSizeLabel,
    BuildingSizeLabel,
    CommonChip
  },

  props: {
    props: {
      type: Object,
      required: true,
    },
    selected: Boolean,
    allowSelect: Boolean,
    allowLink: {
      type: Boolean,
      default: () => true,
    },
    allowPhoto: {
      type: Boolean,
      default: () => true,
    },
    selectOne: Boolean
  },

  computed: {
    ...mapState({
      user: (state: any) => state.user,
    }),
    getPhotoUrl: function () {
      return this.props.photo_links?.thumbnail_url || this.props.photo?.url;
    },
    getComparableTransactionTypeLabel () {
      return getComparableTransactionTypeLabel(this.props.transaction);
    },
    leaseTermLength () {
      return getComparableLeaseTermLength(this.props);
    },
    hasLandlordContacts () {
      return this.props.contacts?.landlord?.length || false;
    },
    landlordContactCompanyName () {
      return this.props.contacts?.landlord[0]?.company?.name || null;
    },
    monthsOnMarket () {
      if (!this.props.transaction.transaction_date || !this.props.transaction.date_on_market) {
        return null;
      }

      const days = getDiffBetweenDates(this.props.transaction.date_on_market, this.props.transaction.transaction_date, ['days']).days;

      if (!days || days < 0) {
        return null;
      }

      return days < 30 ? roundToDecimal(days / 30, 1) : Math.round(days / 30);
    },
  },

  methods: {
    getComparableTransactionImageLabel,
    getNBRPropertyTypeClass,
    isContact: function (contactId) : boolean {
      return contactId.substring(0, 7) === 'Contact'
    },
  },
});
