<template>
  <div
    class="drag-and-drop-file"
    @dragenter.prevent.stop="dropMessage = ''"
    @dragover.prevent.stop
    @drop.prevent.stop="handleDropFile"
  >
    <template v-if="!droppedFile">
      <v-btn
        v-if="!clicked"
        block
        text
        @click="buttonClickedHandler()"
      >
        <v-icon>mdi-upload</v-icon>
        Drag/Drop VCard to Import
      </v-btn>
      <!-- <v-btn color="primary" type="file" @change="handleFileInputChange">Browse</v-btn> -->
      <v-file-input
        v-if="clicked"
        id="fileInput"
        ref="fileInput"
        v-model="droppedFile"
        dense
        type="file"
        placeholder="Browse"
        class="drag-and-drop-file__input"
        @change="handleDropFile"
      />
    </template>
    <template v-else>
      <p class="mb-2 body-1">{{ dropMessage }}</p>
      <div class="d-flex flex-wrap justify-center align-center">
        <v-btn
          color="primary"
          class="mx-2 mb-1"
          @click="processFile"
        >
          Confirm
        </v-btn>
        <v-btn class="mx-2 mb-1" @click="clearDropArea">Cancel</v-btn>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'DragAndDropFile',
  data: () => ({
    dropMessage: '',
    droppedFile: null,
    clicked: false,
  }),
  methods: {
    handleDropFile (e) {
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e
      if (
        file.type.match(/text.vcard/) ||
        file.name.indexOf('.vcf') === file.name.length - '.vcf'.length
      ) {
        const reader = new FileReader()
        const vm = this
        reader.onload = function () {
          vm.dropMessage = file.name + ' ' + file.size
          vm.droppedFile = reader.result
        }
        reader.readAsText(file)
      } else {
        alert('File not supported')
      }
    },
    processFile () {
      this.$emit('processFile', this.droppedFile)
      this.clearDropArea()
    },
    clearDropArea () {
      this.droppedFile = null
      this.dropMessage = ''
      this.clicked = false
    },
    buttonClickedHandler () {
      this.clicked = true
      this.$nextTick(function () {
        document.getElementById('fileInput').click()
      })
    },
  },
}
</script>
