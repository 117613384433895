import axios from 'axios'
import { resetTokenAndReattempRequest } from './refresh'
import versionSetup from '../helpers/interceptors'
import store from '@/store/store';
import { RootMutations } from '@/store/types/mutation-types';

const instance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.VUE_APP_V1_API_URL,
})

instance.interceptors.response.use(
  async (response) => {
    return response
  },
  (error) => {
    return resetTokenAndReattempRequest(error)
  }
)

instance.interceptors.response.use(versionSetup, (error) => {
  return Promise.reject(error)
})

instance.interceptors.request.use((config) => {
  if (config.method?.toLowerCase() === 'get') {
    const controller = new AbortController();
    store.commit(RootMutations.ADD_CANCEL_TOKEN, controller);

    config.signal = config.signal
      ? AbortSignal.any([config.signal, controller.signal]) // handle both signals
      : controller.signal;
  }

  return config;
});

export default instance;
