import instance from '../instance';
import { generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();
const resource = '/requirement';

export default {
  list: async function ({ params = [], token, controller = null }) {
    try {
      const paramsString = generateParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      if (controller) {
        config = { ...config, signal: controller.signal };
      }
      return await instance.get(`${resource}/list${paramsString}`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addToRequirement: async function ({ id, token, payload }) {
    try {
      return await instance.put(`/requirement/${id}/addAvailability`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  listShared: async function (params = []) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/shared/list${paramsString}`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ token, payload }) {
    try {
      return await instance.post('/requirement', payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  savePrivacy: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/privacy`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getAvailabilities: async function ({ id, token, params }) {
    const paramsString = generateParams(params);
    try {
      return await instance.get(`${resource}/${id}/availabilities${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getList: async function ({ id, token, type, params }) {
    const paramsString = generateParams(params);
    try {
      return await instance.get(`${type}/${id}/requirements${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  saveComments: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/comments`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addContact: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/addContact`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removeContact: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}/removeContact`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  }
};
