import 'vuetify/dist/vuetify.min.css'
import '../sass/stylesheets.sass'
import '../sass/styles.scss'
import Vue from 'vue'
import axios from 'axios'
import router from './router/router'
import store from './store/store'
import { placeholderCompanyImg, placeholderListingImg, placeholderPropertyImg, timeZone } from './settings'
import vuetify from './plugins/vuetify'
import * as filters from './utils/filters'
import '@/plugins/dragPolyfill'
import VueGtag from 'vue-gtag'
import AdminMixin from '@/mixins/Admin';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import LogRocket from 'logrocket';
import { registerWorker } from '@/utils/registerWorker';
import i18n from './bundles/Localization';
import { RootActions } from '@/store/types/action-types';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';
import InputFacade from 'vue-input-facade';
import { AppMixin } from '@/bundles/App/mixins/AppMixin';
import { RootMutations } from '@/store/types/mutation-types';
import VueDOMPurifyHTML from 'vue-dompurify-html';

import App from './App.vue'

if (
  !window.location.host.includes('localhost') &&
  window.location.host !== 'dev.app.slickcactus.com' &&
  window.location.host !== 'dev.slickcactus.com'
) {
  LogRocket.init('pegupe/slickcactus')

  if (store.state.userId) {
    LogRocket.identify(store.state.userId!, {
      name: store.state.userName,
      email: store.state.userEmail,

      // Add your own custom user variables here, ie:
      subscriptionType: 'pro',
    });
  }

  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('logrocket_url', sessionURL);
    });
  });
}

Vue.prototype.$geojsonurl = '//slickcactus.com/static/jsonsets'
Vue.prototype.$formDrawerWidth = 600
Vue.prototype.$devmode = false
Vue.prototype.$logRocket = LogRocket;

if (window.location.host.includes('localhost')) {
  Vue.prototype.$geojsonurl = '//' + window.location.host + '/static/jsonsets'
  Vue.prototype.$devmode = true
} else if (window.location.host === '192.168.7.156:8080') {
  Vue.prototype.$geojsonurl = '//' + window.location.host + '/static/jsonsets'
} else if (
  window.location.host === 'dev.app.slickcactus.com' ||
  window.location.host === 'dev.slickcactus.com' ||
  window.location.host.includes('192.168') ||
  window.location.host === 'pagination.slickcactus.com'
) {
  // @ts-ignore
  axios.defaults.baseURL = process.env.VUE_APP_V1_API_URL;
  Vue.prototype.$geojsonurl = '//' + window.location.host + '/static/jsonsets'
  Vue.prototype.$devmode = true
}

// set filters from utils/filters.js
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.prototype.$placeholderCompanyImg = placeholderCompanyImg;
Vue.prototype.$placeholderPropertyImg = placeholderPropertyImg;
Vue.prototype.$placeholderListingImg = placeholderListingImg;
Vue.prototype.$currencyPrefix = '$';
Vue.prototype.$buildingSizeSuffix = 'SF';
Vue.prototype.$landSizeSuffix = 'ACRES';
Vue.prototype.$landSize2Suffix = 'SF';

Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.prototype.$eventBus = new Vue();

router.beforeEach((to, from, next) => {
  const page = document.documentElement
  if (page.classList.contains('ov-hidden')) page.classList.remove('ov-hidden')
  if (to.query.logout) {
    store.dispatch(RootActions.LOG_OUT);
    localStorage.clear()
    return true
  } else {
    next()
    return true
  }
})

Vue.mixin(AdminMixin);
Vue.mixin(AppMixin);
// Google Analytics
Vue.use(
  VueGtag,
  {
    config: { id: 'G-N6YH2ZV09J' },
  },
  router
);

Vue.use(InputFacade);
Vue.use(VueDOMPurifyHTML);

try {
  registerWorker()
} catch (e: any) {
  console.error(e.message)
}

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_URL,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'dev.app.slickcactus.com', 'app.slickcactus.com', '192.168.1.6'],
    }),
  ],
  initialScope: {
    // @ts-ignore
    user: store.state.user,
  },
  tracesSampleRate: 1.0,
});

if (!store.state.visitorId) {
  getFingerprint()
    .then((fp) => {
      store.commit(RootMutations.SET_VISITOR_ID, fp);
    })
    .catch((error) => console.error(error));
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  vuetify,
  router,
  store,
  i18n,
  components: { App },
  template: '<App/>',
})
