







import Vue from 'vue';
import { DateTime } from 'luxon';
import { Nullable } from '@/utils/types';
import { isValuePresent } from '@/bundles/App/helpers/isValuePresent';

import Label from '@/bundles/Common/components/Label.vue';

interface IData {
  currentTime: DateTime;
  intervalId: Nullable<number>;
}

export default Vue.extend({
  name: 'LocalTimeLabel',

  components: { Label },

  props: {
    timezone: {
      type: String,
      default: null,
    },
  },

  data: (): IData => ({
    currentTime: DateTime.local(),
    intervalId: null,
  }),

  computed: {
    formattedTime (): Nullable<string> {
      return this.timezone ? this.currentTime.toLocaleString(DateTime.TIME_SIMPLE) : null;
    },
  },

  watch: {
    timezone: {
      handler () {
        this.updateTime();
      },
      immediate: true,
    }
  },

  beforeDestroy () {
    this.resetInterval();
  },

  methods: {
    updateTime () {
      if (!this.timezone) {
        return;
      }

      this.currentTime = DateTime.local().setZone(this.timezone);

      this.resetInterval(); // clear interval if it's already set
      this.intervalId = window.setInterval(() => {
        this.currentTime = DateTime.local().setZone(this.timezone);
      }, 1000); // Update every 10 seconds
    },
    resetInterval () {
      if (isValuePresent(this.intervalId)) {
        clearInterval(this.intervalId as number);
      }
    },
  }
});
