<template>
  <v-card>
    <v-card-title>
      Import from vCard
      <v-spacer />
      <v-btn icon light @click="closeWindow">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        {{ fields }}
        <template #default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Current Value</th>
              <th class="text-left">vCard value</th>
              <th>Accept Change</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in fields" :key="item.key">
              <td style="white-space: nowrap">{{ item.name }}</td>
              <td>{{ item.cur }}</td>
              <td>{{ item.vCard }}</td>
              <td>
                <template v-if="!ifDifferent(item)">
                  <v-icon>mdi-check</v-icon>
                </template>
                <template v-else>
                  <v-switch v-model="item.acceptChange" />
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="primary" @click="updateContactRequest">Save</v-btn>
      <v-btn class="cancel" text @click="closeWindow">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { eventBus } from '@/eventbus/eventbus'

export default {
  name: 'ContactAcceptImportVCF',
  props: {
    records: {
      type: Object,
      default: () => ({}),
    },
    contact: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    fields () {
      return [
        {
          name: 'First Name',
          cur: this.contact.first_name || '-',
          vCard: this.records.first_name || '-',
          key: 'first_name',
          acceptChange: false,
        },
        {
          name: 'Last Name',
          cur: this.contact.last_name || '-',
          vCard: this.records.last_name || '-',
          key: 'last_name',
          acceptChange: false,
        },
        {
          name: 'Email Address',
          cur: this.contact.email || '-',
          vCard: this.records.email || '-',
          key: 'email',
          acceptChange: false,
        },
        {
          name: 'Title',
          cur: this.contact.title || '-',
          vCard: this.records.title || '-',
          key: 'title',
          acceptChange: false,
        },
        {
          name: 'Direct Phone',
          cur: this.contact.phone.direct || '-',
          vCard: this.records.phone.direct || '-',
          key: 'phone.direct',
          acceptChange: false,
        },
        {
          name: 'Mobile Phone',
          cur: this.contact.phone.mobile || '-',
          vCard: this.records.phone.mobile || '-',
          key: 'phone.mobile',
          acceptChange: false,
        },
        {
          name: 'Office Phone',
          cur: this.contact.phone.office || '-',
          vCard: this.records.phone.office || '-',
          key: 'phone.office',
          acceptChange: false,
        },
        {
          name: 'Home Phone',
          cur: this.contact.phone.home || '-',
          vCard: this.records.phone.home || '-',
          key: 'phone.home',
          acceptChange: false,
        },
        {
          name: 'Fax',
          cur: this.contact.phone.fax || '-',
          vCard: this.records.phone.fax || '-',
          key: 'phone.fax',
          acceptChange: false,
        },
        {
          name: 'Office address',
          cur: this.concatAddress(this.contact.office_address),
          vCard: this.concatAddress(this.records.office_address),
          addressParts: this.records.office_address,
          key: 'office_address',
          acceptChange: false,
        },
        {
          name: 'Home Address',
          cur: this.concatAddress(this.contact.home_address),
          vCard: this.concatAddress(this.records.home_address),
          addressParts: this.records.home_address,
          key: 'home_address',
          acceptChange: false,
        },
      ]
    },
  },
  methods: {
    closeWindow () {
      this.$emit('closeWindow')
    },
    ifDifferent (item) {
      return item.cur !== item.vCard
    },
    generateUpdatePayload (fields) {
      let payload = {}
      let phone = {}
      fields.forEach((item) => {
        if (item.acceptChange) {
          if (item.key.includes('phone')) {
            const phonePart = item.key.slice(item.key.indexOf('.') + 1)
            phone[phonePart] = item.vCard
          } else if (item.key.includes('address')) {
            payload[item.key] = item.addressParts
          } else {
            payload[item.key] = item.vCard
          }
          if (Object.keys(phone).length) {
            payload.phone = phone
          }
        }
      })
      return payload
    },
    updateContactRequest () {
      eventBus.$emit(
        'updateContactRequest',
        this.generateUpdatePayload(this.fields)
      )
      this.closeWindow()
    },
    concatAddress (addressObj) {
      if (addressObj.street) {
        return Object(addressObj) === addressObj &&
          Object.values(addressObj).some((item) => item.length)
          ? Object.values(addressObj)
              .filter((item) => !!item)
              .join(', ')
          : '-'
      }
      return null
    },
  },
}
</script>
