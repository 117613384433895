import { ICountry } from '@/bundles/Common/interfaces/ICountries';
import { removeNonMaskChars } from '@/bundles/Common/helpers/index';
const countries = import('@/bundles/Common/static/countries.json');

export const getCountries = async (): Promise<ICountry[]> => {
  const countriesModule = await countries;

  return countriesModule.default;
};

const getLargestMask = (masks: string[]): string => {
  return masks.reduce((acc: string, mask: string) => {
    const accLength = removeNonMaskChars(acc).length;
    const maskLength = removeNonMaskChars(mask).length;

    acc = accLength > maskLength ? acc : mask;

    return acc;
  }, '');
}

export const getCountryMask = (countries: ICountry[], code: string): string => {
  const country = countries.find((country) => country.iso === code);

  if (!country) {
    return '(###) ###-####';
  }

  if (Array.isArray(country.mask)) {
    return getLargestMask(country.mask);
  }

  return country.mask;
}
