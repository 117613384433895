import { ICompany } from '@/bundles/Companies/types';
import { DEFAULT_COUNTRY_CODE } from '@/bundles/Common/mixins/CountriesMasksMixin';

export const getCompanyDefaultPhoneObj = () => ({
  office: '',
  office_country_code: DEFAULT_COUNTRY_CODE,
  fax: '',
  fax_country_code: DEFAULT_COUNTRY_CODE,
});

export const getCompanyDefaultObj = (): Omit<ICompany, 'location'> => ({
  _id: '',
  _key: '',
  name: '',
  logo: '',
  url: '',
  description: '',
  address: {
    street: '',
    street2: '',
    city: '',
    state: '',
    postal: '',
    country: '',
    timezone_name: null,
  },
  phone: getCompanyDefaultPhoneObj(),
  isBrokerage: false,
  brokerLicense: {
    legal_name: '',
    number: '',
    state: '',
    taxId: ''
  },
  industry_group: '',
  industry: '',
  metrics: {
    employees: '',
    annualRevenue: '',
    estimatedAnnualRevenue: ''
  },
  created: {
    name: ''
  },
  updated: {
    name: ''
  },
  active: true,
  year_established: '',
  contacts: [],
  notes: [],
  tasks: [],
  name_history: [],
  site: {
    coordinates: {
      lng: null,
      long: null,
      lat: null,
    }
  },
});
