<template>
  <div>
    <v-navigation-drawer
      v-if="isAuthenticated"
      id="sc-primary-nav-drawer"
      v-model="navState"
      class="c-app-bar"
      clipped
      dark
      app
      overflow
      :stateless="$vuetify.breakpoint.smAndUp"
      :mini-variant="$vuetify.breakpoint.smAndUp && isMini"
      :permanent="$vuetify.breakpoint.smAndUp"
    >
      <v-list class="mb-5">
        <v-tooltip v-if="isMini && $route.name !== 'dashboard'" right>
          <template #activator="{ on, attrs }">
            <v-list-item
              link
              class="logo-link pl-3"
              :to="getAccountRoute({ name: 'dashboard' })"
              v-bind="attrs"
              v-on="on"
              @click.native="hideNavBar"
            >
              <v-list-item-avatar size="30">
                <v-img tile src="/img/sc-logo.png" contain />
              </v-list-item-avatar>
              <v-list-item-title class="overline" style="color: #6CC04A;">SlickCactus</v-list-item-title>
            </v-list-item>
          </template>
          <span class="overline" style="font-size: 1.2em; font-weight: 500;">Dashboard</span>
        </v-tooltip>
        <v-list-item
          v-else
          link
          class="logo-link pl-3"
          two-line
          @click.native="hideNavBar"
        >
          <v-list-item-avatar size="30">
            <v-img tile src="/img/sc-logo.png" contain />
          </v-list-item-avatar>
          <v-list-item-title class="overline" style="color: #6CC04A;">SlickCactus</v-list-item-title>
        </v-list-item>
        <v-list>
          <div v-for="link in items" :key="link.icon">
            <v-tooltip v-if="isMini && $route.name !== link.route && `/${$route.name}` !== `${link.route}`" right>
              <template #activator="{ on, attrs }">
                <v-list-item
                  class="app-bar-nav-list-item"
                  link
                  exact-path
                  :to="getAccountRoute({ name: link.route })"
                  v-bind="attrs"
                  v-on="on"
                  @click.native="hideNavBar()"
                >
                  <v-list-item-icon>
                    <v-btn icon style="width: auto!important;">
                      <v-icon dark>
                        {{ link.icon }}
                      </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-title>{{ link.text }}</v-list-item-title>
                </v-list-item>
              </template>
              <span class="overline" style="font-size: 1.2em; font-weight: 500;">{{ link.text }}</span>
            </v-tooltip>
            <v-list-item
              v-else
              :to="getAccountRoute({ name: link.route })"
              class="app-bar-nav-list-item"
              link
            >
              <v-list-item-icon>
                <v-btn icon link style="width: auto!important;">
                  <v-icon dark>
                    {{ link.icon }}
                  </v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
        <v-list v-if="customNavigationLinks && customNavigationLinks.length">
          <v-divider />
          <div v-for="(customNavigationLink, index) in customNavigationLinks" :key="index">
            <v-tooltip v-if="isMini" right>
              <template #activator="{ on, attrs }">
                <v-list-item
                  class="app-bar-nav-list-item"
                  target="_blank"
                  :href="customNavigationLink.url"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-icon>
                    <v-btn icon link style="width: auto!important;">
                      <v-icon>{{ customNavigationLink.icon }}</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ customNavigationLink.title }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <span class="overline" style="font-size: 1.2em; font-weight: 500;">{{ customNavigationLink.title }}</span>
            </v-tooltip>
            <v-list-item
              v-else
              class="app-bar-nav-list-item"
              target="_blank"
              :href="customNavigationLink.url"
            >
              <v-list-item-icon>
                <v-btn icon link style="width: auto!important;">
                  <v-icon dark>
                    {{ customNavigationLink.icon }}
                  </v-icon>
                </v-btn>
              </v-list-item-icon>
              <v-list-item-title>{{ customNavigationLink.title }}</v-list-item-title>
            </v-list-item>
          </div>
        </v-list>
      </v-list>
      <template #append>
        <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex justify-center mb-2">
          <v-spacer v-if="!isMini" />
          <v-btn icon style=" opacity: .3" @click="updateNavView">
            <v-icon>mdi-chevron-double-{{ isMini ? 'right' : 'left' }}</v-icon>
          </v-btn>
        </div>
        <v-divider />
        <div v-if="$vuetify.breakpoint.xs || !isMini" class="pa-2 mt-0 text-center">
          <v-btn style="color: #999" @click="openTrackerForm">
            <v-icon small class="mr-2" style="color: #999">mdi-comment-question</v-icon>
            Support / Feedback
          </v-btn>
        </div>
        <v-list-item v-else class="user-actions-list-item" @click="openTrackerForm">
          <v-list-item-icon>
            <v-icon style="color: #999">mdi-comment-question</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="dialog" fullscreen persistent>
      <TrackerWrapper />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import TrackerWrapper from './common-components/tracker/TrackerWrapper';
import { RootMutations } from '@/store/types/mutation-types';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';

export default {
  name: 'AppBar',

  components: {
    TrackerWrapper
  },

  mixins: [AccountLayoutMixin],

  data: () => ({
    dialog: false,
    drawer: false,
    showUserActions: false,
    mini: false,
    appBarWidth: 230,
    items: [
      { icon: 'mdi-view-dashboard-variant', text: 'Dashboard', route: 'dashboard' },
      { icon: 'mdi-map', text: 'Spatial GIS', route: 'spatial-search' },
      { icon: 'mdi-city', text: 'Companies', route: 'companies' },
      { icon: 'mdi-contacts', text: 'Contacts', route: 'contacts' },
      { icon: 'mdi-office-building-marker',
        text: 'Occupiers',
        route: 'occupiers',
      },
      { icon: 'mdi-check-all', text: 'Requirements', route: 'requirements' },
      { icon: 'mdi-domain', text: 'Properties', route: 'properties' },
      {
        icon: 'mdi-map-marker-radius',
        text: 'Availabilities',
        route: 'availabilities',
      },
      { icon: 'mdi-compare', text: 'Comparables', route: 'comparables' },
      { icon: 'mdi-clipboard-text', text: 'Presentations', route: 'projects' },
      { icon: 'mdi-table', text: 'Saved Sets', route: 'sets' },
      { icon: 'mdi-pipe', text: 'Pipelines', route: 'pipelines' },
      { icon: 'mdi-currency-usd', text: 'Closed Deals', route: 'deals' },
      { icon: 'mdi-flash-outline', text: 'Marketing', route: 'marketing' },
    ],
  }),

  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState({
      userLogo: (state) => state.user?.profile?.img,
      nav: (state) => state.nav,
      isMini: (state) => state.isMiniNav,
      customNavigationLinks: (state) => state.accountSettings?.customNavigationLinks,
    }),
    navState: {
      get: function () {
        return this.nav;
      },
      set: function (value) {
        this.setNavState(value);
      }
    },
    userAvatar: function () {
      return this.userLogo || this.contactPlaceholder;
    },
  },

  created: function () {
    this.$eventBus.$on('closeFeedbackForm', this.closeFormHandler);
  },

  beforeDestroy: function () {
    this.$eventBus.$off('closeFeedbackForm', this.closeFormHandler);
  },

  methods: {
    ...mapMutations({
      setNavState: RootMutations.SET_NAV_STATE,
      setNavView: RootMutations.SET_NAV_VIEW,
    }),
    updateNavView: function () {
      this.setNavView(!this.isMini);
    },
    closeFormHandler: function () {
      this.dialog = false;
    },
    openTrackerForm: function () {
      this.drawer = false;
      this.dialog = true;
    },
    hideNavBar () {
      this.setNavState(false);
    },
  },
};
</script>
