























































































import { getCountryMask } from '@/bundles/Common/helpers/getCountries';
import mixins from 'vue-typed-mixins';
import { CountriesMasksMixin, DEFAULT_COUNTRY_CODE } from '@/bundles/Common/mixins/CountriesMasksMixin';

interface ICommonPhonePicker {
  showMenu: boolean;
  phone: string;
  extension: string;
  countryCode: string;
  phoneMask: string;
}

export default mixins(CountriesMasksMixin).extend({
  name: 'CommonPhonePicker',

  props: {
    phoneCountryCode: {
      type: String,
      default: DEFAULT_COUNTRY_CODE
    },
    phoneNumber: {
      type: String,
      default: ''
    },
    phoneExtension: {
      type: String,
      default: ''
    },
    showExtension: Boolean
  },

  data: (): ICommonPhonePicker => ({
    showMenu: false,
    phone: '',
    extension: '',
    countryCode: DEFAULT_COUNTRY_CODE,
    phoneMask: getCountryMask([], DEFAULT_COUNTRY_CODE)
  }),

  watch: {
    showMenu () {
      this.phone = this.phoneNumber;
      this.extension = this.phoneExtension;
      this.countryCode = this.phoneCountryCode || DEFAULT_COUNTRY_CODE;
    },
    countryCode (value) {
      if (!value) {
        this.countryCode = DEFAULT_COUNTRY_CODE;
      }

      this.setMaskForCountry();
    }
  },

  methods: {
    savePhone () {
      this.$emit('save:number', this.phone);
      this.$emit('save:countryCode', this.countryCode);

      if (this.showExtension) {
        this.$emit('save:extension', this.extension);
        this.extension = '';
      }

      this.showMenu = false;
      this.phone = '';
    },
    setMaskForCountry () {
      this.phoneMask = getCountryMask(this.countriesList, this.countryCode);
    },
  }
});
