import {
  ICompany,
  ICompanyAddress,
  ICompanyBrokerLicense, ICompanyContact,
  ICompanyMetrics,
  ICompanyPhone, ICompanySite
} from '@/bundles/Companies/types';
import { Nullable } from '@/utils/types';
import { BaseArangoModel } from '@/bundles/App/models/BaseArangoModel';
import { ILocation } from '@/utils/interfaces';

export default class Company extends BaseArangoModel implements ICompany {
  name: string;
  active: boolean;
  url: Nullable<string>;
  address: ICompanyAddress;
  description: Nullable<string>;
  industry: Nullable<string>;
  industry_group: Nullable<string>;
  logo: string;
  year_established: Nullable<string>;
  phone: ICompanyPhone;
  metrics: ICompanyMetrics;
  brokerLicense: ICompanyBrokerLicense;
  isBrokerage: boolean;
  contacts: ICompanyContact[];
  site: ICompanySite;
  name_history: string[];
  notes: string[];
  tasks: string[];
  location: ILocation;

  constructor (payload: ICompany) {
    super(payload);

    this.name = payload.name;
    this.active = payload.active;
    this.url = payload.url;

    this.address = {
      street: payload.address.street || null,
      street2: payload.address.street2 || null,
      city: payload.address.city || null,
      state: payload.address.state || null,
      country: payload.address.country || null,
      postal: payload.address.postal || null,
      timezone_name: payload.address.timezone_name || null,
    };

    this.description = payload.description;
    this.industry = payload.industry;
    this.industry_group = payload.industry_group;
    this.logo = payload.logo;
    this.year_established = payload.year_established;

    this.phone = { // payload.phone is Nullable
      fax: payload.phone?.fax || null,
      fax_country_code: payload.phone?.fax_country_code || null,
      office: payload.phone?.office || null,
      office_country_code: payload.phone?.office_country_code || null,
    };

    this.metrics = payload.metrics;
    this.brokerLicense = payload.brokerLicense;
    this.isBrokerage = payload.isBrokerage;
    this.contacts = payload.contacts;
    this.site = payload.site;
    this.name_history = payload.name_history;
    this.notes = payload.notes;
    this.tasks = payload.tasks;
    this.location = payload.location;
  }
}
