import { getDefaultPermissions } from '@/bundles/App/helpers';

export const getDefaultContact = () => ({
  designation: [],
  img: '',
  first_name: '',
  last_name: '',
  nickname: '',
  email: '',
  title: '',
  company: '',
  office_address_same_as_company: false,
  phone: {
    direct: '',
    direct_ext: '',
    direct_country_code: '',
    mobile: '',
    mobile_ext: '',
    mobile_country_code: '',
    office: '',
    office_ext: '',
    office_country_code: '',
    fax: '',
    fax_ext: '',
    fax_country_code: '',
  },
  home_address: {
    street: '',
    city: '',
    state: '',
    postal: '',
    country: '',
  },
  office_address: {
    street: '',
    street2: '',
    city: '',
    state: '',
    postal: '',
    country: '',
    timezone_name: null,
  },
  home_phone: '',
  published_to_web: false,
  permissions: getDefaultPermissions(),
})
