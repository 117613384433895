import instance from '../instance';
import { generateObjectParams, generateParams } from '@/utils/helpers';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';

const errorMapper = new ErrorMapper();

const resource = '/availability';

export default {
  list: async function ({ params = [], token, onDownloadEventHandler = null, controller = null }) {
    try {
      const queryParams = generateObjectParams(params);
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: queryParams,
      };
      if (onDownloadEventHandler) {
        config = Object.assign(config, {
          onDownloadProgress: onDownloadEventHandler,
        });
      }
      if (controller) {
        config = { ...config, signal: controller.signal };
      }
      return await instance.get(`${resource}/list`, config);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getMarketing: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/marketing`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getOne: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getRecord: async function ({ token, id }) {
    try {
      return await instance.get(`${resource}/${id}/record`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  get: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getStats: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/stats`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getContacts: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/contacts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addContact: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/addContact`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  update: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateLocation: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/location`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  patch: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addRequirements: async function ({ id, token, payload }) {
    try {
      return await instance.put(`${resource}/${id}/addRequirement`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getComparable: async function ({ id, token }) {
    try {
      return await instance.get(`${resource}/${id}/comparable`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  create: async function ({ token, payload }) {
    try {
      return await instance.post(`${resource}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  saveKeywords: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/keywords`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  saveComments: async function ({ token, id, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/comments`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getKeywords: async function ({ token, id, payload }) {
    try {
      return await instance.get(`${resource}/${id}/keywords`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getShared: async function (id) {
    try {
      return await instance.get(`${resource}/shared/${id}`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  listShared: async function (params = []) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/shared/list${paramsString}`);
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  archive: async function ({ token, payload }) {
    try {
      return await instance.put(`${resource}/archive`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getPipelines: async function ({ token, id, params = [] }) {
    const paramsString = generateParams(params);
    try {
      return await instance.get(`${resource}/${id}/pipelines${paramsString}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  removePipeline: async function ({ token, id, pipeline }) {
    try {
      return await instance.delete(`${resource}/${id}/pipeline/${pipeline}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  updateMarketing: async function ({ id, token, payload }) {
    try {
      return await instance.patch(`${resource}/${id}/marketing`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  addPipeline: async function ({ token, id, pipeline }) {
    try {
      return await instance.patch(`${resource}/${id}/pipeline/${pipeline}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
  getReport: async function (token, params = []) {
    try {
      const paramsString = generateParams(params);
      return await instance.get(`${resource}/report${paramsString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    } catch (error) {
      throw errorMapper.mapToCodeModel(error);
    }
  },
};
