import { Nullable } from '@/utils/types';

const REQUIRED_MERGE_TAGS = [
  '{{ RecipientName }}',
  '{{ SenderName }}',
];
export const isInvoiceMessageValid = (message: Nullable<string>): boolean => {
  if (!message) return false;

  return REQUIRED_MERGE_TAGS.every((tag) => message.includes(tag));
}

export const getInvoiceMessageError = (message: Nullable<string>): string => {
  if (!isInvoiceMessageValid(message)) {
    return `Invoice description must contain the following merge tags: <b>${REQUIRED_MERGE_TAGS.join(', ')}</b>`;
  }

  return '';
}

export const getInvoiceParsedMessage = (message: Nullable<string>, { recipientName, senderName }: { recipientName: string; senderName: string; }): string => {
  if (!message) return '';

  return message
    .replace('{{ RecipientName }}', recipientName)
    .replace('{{ SenderName }}', senderName);
}
