import { INotification, INotificationMetadata } from '@/bundles/Notification/interfaces/INotification';
import { Nullable } from '@/utils/types';

export class Notification implements INotification {
  id: number;
  title: string;
  show: boolean;
  body: string;
  metadata: Nullable<INotificationMetadata>;
  user_id: number;
  account_id: number;
  created_at: string;
  updated_at: string;

  constructor (entity: INotification) {
    this.id = entity.id;
    this.title = entity.title;
    this.show = entity.show;
    this.body = entity.body;
    this.body = entity.body;
    this.metadata = entity.metadata;
    this.user_id = entity.user_id;
    this.account_id = entity.account_id;
    this.created_at = entity.created_at;
    this.updated_at = entity.updated_at;
  }
}
