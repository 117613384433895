















import Vue, { PropType } from 'vue';
import { Nullable } from '@/utils/types';

interface IData {
  search: Nullable<string>;
}

export default Vue.extend({
  name: 'TimeZoneSelect',

  props: {
    value: {
      type: String as PropType<Nullable<string>>,
      default: null
    },
    label: {
      type: String,
      default: 'Timezone'
    },
    hideDetails: {
      type: Boolean,
      default: true
    }
  },

  data: (): IData => ({
    search: null,
  }),

  computed: {
    modelValue: {
      get (): Nullable<string> {
        return this.value;
      },
      set (value: Nullable<string>) {
        this.$emit('input', value)
      }
    },
    getTimeZonesWithOffsets (): { timeZone: string; offset: string }[] {
      const timeZones = Intl.supportedValuesOf('timeZone');

      return timeZones.map(timeZone => {
        const now = new Date();
        const formatter = new Intl.DateTimeFormat('en-US', {
          timeZone,
          hour12: false,
          timeZoneName: 'short'
        });
        const parts = formatter.formatToParts(now);
        const offset = parts.find(part => part.type === 'timeZoneName')?.value || '';

        return {
          timeZone,
          offset
        };
      });
    }
  },

  methods: {
    customFilter (item: { timeZone: string; offset: string }, queryText: Nullable<string>, itemText: Nullable<string>): boolean {
      const text = queryText?.toLowerCase() || '';

      return item.timeZone.toLowerCase().indexOf(text) > -1 || item.offset.toLowerCase().indexOf(text) > -1;
    },
    getFormattedName (item: { timeZone: string; offset: string }): string {
      return `${item.timeZone} (${item.offset})`;
    }
  }
})
