

















































import Vue, { PropType } from 'vue';
import { ICompanyBrokerLicense } from '@/bundles/Companies/types';

import StateSelect from '@/bundles/App/components/inputs/StateSelect.vue';

export default Vue.extend({
  name: 'CompanyBrokerageSection',

  components: { StateSelect },

  props: {
    value: {
      type: Object as PropType<ICompanyBrokerLicense>,
      required: true
    },
    isBrokerage: Boolean
  },

  methods: {
    updateField (key: string, value: string) {
      this.$emit('input', { ...this.value, [key]: value });
    }
  }
})
