import { IStoreFilterItem } from '@/bundles/Filters/interfaces/IStoreFilterItem';
import { FilterAllFilterOnlyEnum } from '@/bundles/Filters/enums/FilterAllFilterOnlyEnum';

export const FILTERS_KEYS = ['dashboard', 'occupier', 'company', 'availability', 'property',
  'comparable', 'deal', 'contact', 'requirement', 'project', 'pipeline', 'savedSet',
  'member', 'team', 'request', 'eblast', 'pressrelease', 'map', 'marketing',
  'eblastList', 'profileAdmin', 'marketingTemplates', 'marketingCampaign', 'customRows', 'signList',
];

export interface IFilters {
  [key: string]: IStoreFilterItem[] | any; // todo: customRows and marketingTemplates has any value they don't use regular filters model, need to create separate store for them
}

interface IFilterObjectShape {
  filters: IFilters;
  keyword: string;
  filterName: string;
  filterId: string;
  lastSelectedType: string;
  savedSetId: string;
}

export const FILTER_OBJECT_SHAPE: IFilterObjectShape = {
  filters: {},
  keyword: '',
  filterName: '',
  filterId: '',
  lastSelectedType: '',
  savedSetId: '', // todo: check if filterId can be used
};

const getDefaultFiltersDictionary = () => ({
  marketingTemplates: {
    is_enterprise: FilterAllFilterOnlyEnum.filter,
  },
});

export const getDefaultFilterObjectShape = (filterKey: string) => {
  return Object.assign({}, FILTER_OBJECT_SHAPE, {
    filters: filterKey in getDefaultFiltersDictionary() ? getDefaultFiltersDictionary()[filterKey] : {},
  });
}
