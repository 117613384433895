import { arrToSnakeCase, ContactRolesEnum, getContactRoles } from '@/bundles/Contact/enums/contactRoles';
import { ITextValue } from '@/types/common';
import { difference } from 'lodash';

export const contactsCard = {
  availability: 'Availability',
  availabilityProperty: 'Property',
  pipeline_primary_client: 'Primary Client',
};

export const contactCardsNoneLabelDictionary = {
  company: 'No contacts found for this company',
  pipeline: 'No contacts found for this pipeline',
  occupier: 'No contacts found for this occupier',
  contact: 'No contacts found for this contact',
  comparable: 'No contacts found for this comparable',
  property: 'No contacts found for this property',
  requirement: 'No contacts found for this requirement',
  pipeline_primary_client: 'No primary contacts found for this pipeline',
};

export const contactPipelinePrimaryClientRoles = [
  ContactRolesEnum.local_manager,
  ContactRolesEnum.regional_manager,
  ContactRolesEnum.ultimate_decision_maker,
  ContactRolesEnum.key_stake_holder,
];

export const getPipelineContactRoles = (roles: string[]): ITextValue<string>[] => {
  const snakeCaseRoles = arrToSnakeCase(roles) as ContactRolesEnum[];
  const nonPrimaryRoles = snakeCaseRoles.filter(role => !contactPipelinePrimaryClientRoles.includes(role));

  return getContactRoles(nonPrimaryRoles);
}

export const getContactRolesBySource = (source: string, getListByCategory: Function): ITextValue<string>[] => {
  const sourceType = source === 'availabilityProperty' ? 'property' : source;
  const categories = ['property', 'company', 'pipeline', 'occupier', 'availability', 'comparable', 'deal', 'requirement', 'pipeline_primary_client'];

  if (!categories.some(category => category === sourceType)) {
    return [];
  }

  if (sourceType === 'pipeline') {
    return getPipelineContactRoles(getListByCategory(`${sourceType}_roles`).items);
  }

  if (sourceType === 'pipeline_primary_client') {
    return getContactRoles(contactPipelinePrimaryClientRoles);
  }

  const roles = getListByCategory(`${sourceType}_roles`).items;

  return getContactRoles(arrToSnakeCase(roles) as ContactRolesEnum[]);
}

export const mapPipelineContactsBySource = (contacts: any[], source: 'pipeline_primary_client' | 'pipeline', pipelineContactRolesList): any[] => {
  if (!contacts.length) {
    return [];
  }

  const pipelineContactRoles = pipelineContactRolesList.map(item => item.value);
  const notPrimaryClientRoles = source === 'pipeline' ? difference(pipelineContactRoles, contactPipelinePrimaryClientRoles) : [];

  return contacts.filter(contact => {
    const snakeCaseRoles = arrToSnakeCase(contact.roles) as ContactRolesEnum[];
    const isPrimaryClient = contactPipelinePrimaryClientRoles.some(role => snakeCaseRoles.includes(role));
    const isRegularContact = notPrimaryClientRoles.some(role => snakeCaseRoles.includes(role));

    return source === 'pipeline_primary_client' ? isPrimaryClient : isRegularContact;
  });
}

export const sourceMappingDictionary = {
  availabilityProperty: 'property',
  pipeline_primary_client: 'pipeline',
};
