









import Vue from 'vue';

export default Vue.extend({
  name: 'VCardImportFormSection',

  props: {
    title: {
      type: String,
      required: true,
    },
  }
});
