import { ITransactionLease, ITransactionSale, TransactionTypeEnum } from '@/bundles/Comparables/types';

export const getComparableTransactionTypeLabel = (transaction: ITransactionSale | ITransactionLease) => {
  if ('investment_sale' in transaction && transaction.investment_sale) {
    return `${transaction.transaction_type} - Investment`;
  }

  if ('sublease' in transaction && transaction.sublease) {
    return `${transaction.transaction_type} - Sublease`;
  }

  return transaction.transaction_type;
}

export const getComparableTransactionImageLabel = (transaction: ITransactionSale | ITransactionLease) => {
  if ('investment_sale' in transaction && transaction.investment_sale) {
    return `Investment Sale`;
  }

  if ('sublease' in transaction && transaction.sublease) {
    return `Sublease`;
  }

  return transaction.transaction_type === TransactionTypeEnum.sale ? 'Sold' : 'Leased';
}
