









































































import Vue, { PropType } from 'vue';
import { IPermissions } from '@/bundles/App/interfaces/IPermissions';
import { getTextValuePrivacyTypes, PrivacyType } from '@/bundles/App/types';
import { Nullable } from '@/utils/types';
import { cloneDeep, isEqual } from 'lodash';
import Member from '@/bundles/Members/models/Member';
import Team from '@/bundles/Teams/models/Team';
import { mapMutations, mapState } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';
import { memberService } from '@/bundles/Members/factory/memberServiceFactory';
import { teamsService } from '@/bundles/Teams/factory/teamsFactory';
import { getDefaultPermissions } from '@/bundles/App/helpers';
import { ITextValue } from '@/types/common';

import AppAutocomplete from '@/bundles/App/components/common/AppAutocomplete.vue';

interface IAppPrivacyInstance {
  permissions: IPermissions;
  members: Member[];
  teams: Team[];
  dataLoading: boolean;
  privacyTypes: ITextValue<PrivacyType>[];
}

export default Vue.extend({
  name: 'AppPrivacy',

  components: { AppAutocomplete },

  props: {
    value: {
      type: Object as PropType<Nullable<IPermissions>>,
      default: () => null,
    },
    disabled: Boolean,
    showTitle: {
      type: Boolean,
      default: true,
    },
    outlined: Boolean,
  },

  data: (): IAppPrivacyInstance => ({
    permissions: getDefaultPermissions(),
    members: [],
    teams: [],
    dataLoading: false,
    privacyTypes: getTextValuePrivacyTypes(),
  }),

  computed: {
    ...mapState({
      user: (state: any) => state.user,
    }),
  },

  watch: {
    value: {
      handler: function (value) {
        if (!value && !this.disabled) {
          this.$emit('input', getDefaultPermissions());
          return;
        }

        if (value && !isEqual(value, this.permissions)) {
          this.permissions = cloneDeep(value);
        }
      },
      immediate: true,
      deep: true,
    },
    'permissions.privacy': {
      handler: function (value) {
        if (value === PrivacyType.TEAM_OR_USER && !this.members.length) {
          this.dataLoading = true;
          Promise.all([this.loadMembers(), this.loadTeams()])
            .then(() => { this.dataLoading = false; });
        }
        if (value !== PrivacyType.TEAM_OR_USER && (this.permissions.user_ids.length || this.permissions.team_ids.length)) {
          this.permissions.team_ids = [];
          this.permissions.user_ids = [];
        }
      },
      immediate: true,
    },
    permissions: {
      handler: function (value) {
        this.$emit('input', cloneDeep(value));
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    loadMembers: async function () {
      try {
        const params = [
          {
            name: 'status',
            value: 'active',
          },
          {
            name: 'sort',
            value: 'name',
          },
          {
            name: 'descending',
            value: false,
          },
          {
            name: 'take',
            value: 0,
          },
        ];
        const { data } = await memberService.list(params);
        this.members = data;
      } catch (error) {
        const notification = { ...error };
        this.addNotification(notification);
      }
    },
    loadTeams: async function () {
      try {
        this.teams = await teamsService.myTeams();
      } catch (error) {
        const notification = { ...error };
        this.addNotification(notification);
      }
    }
  },
});
