var render = function (_h,_vm) {var _c=_vm._c;return _c('div',_vm._b({staticClass:"d-flex gap-2 mt-1",class:[_vm.props.isOneLine ? 'align-center' : 'flex-column', _vm.data.staticClass]},'div',_vm.data.attrs,false),[_c('div',{staticClass:"d-flex gap-2 flex-wrap"},[(_vm.props.mixedUse)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(_vm.$options.components.PropertyTypeChip,_vm._g(_vm._b({tag:"component",staticClass:"wide-chip",attrs:{"property-type":"Mixed Use"}},'component',attrs,false),on))]}}],null,false,3943648022)},[_c('span',[_vm._v("Property Type")])]):_vm._e(),(_vm.props.type)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(_vm.$options.components.PropertyTypeChip,_vm._g(_vm._b({tag:"component",staticClass:"wide-chip",attrs:{"property-type":_vm.props.type}},'component',attrs,false),on))]}}],null,false,3315475984)},[_c('span',[_vm._v("Property Type")])]):_vm._e(),(_vm.props.buildingClass)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(_vm.$options.components.CommonChip,_vm._g(_vm._b({tag:"component",staticClass:"wide-chip",attrs:{"dark":"","color":"black"}},'component',attrs,false),on),[_vm._v(" "+_vm._s(_vm.props.buildingClass)+" ")])]}}],null,false,1016977935)},[_c('span',[_vm._v("Building Class")])]):_vm._e(),(_vm.props.showTransactionStatus && _vm.props.transactionStatus)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(_vm.$options.components.CommonChip,_vm._g(_vm._b({tag:"component",staticClass:"wide-chip",attrs:{"dark":"","color":"grey darken-2"}},'component',attrs,false),on),[_vm._v(" "+_vm._s(_vm.props.transactionStatus)+" ")])]}}],null,false,3405867361)},[_c('span',[_vm._v("Transaction Status")])]):_vm._e()],1),(_vm.props.contractPending)?_c('div',[(_vm.props.contractPending)?_c(_vm.$options.components.AvailabilityTransactionStatusChip,{tag:"component",staticClass:"wide-chip",attrs:{"status":"In Escrow"}}):_vm._e()],1):_vm._e(),(_vm.props.status || _vm.props.inHouse || _vm.props.agencyType)?_c('div',{staticClass:"d-flex gap-2",class:{'flex-wrap': _vm.props.allowWrap}},[(_vm.props.status)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.props.isPublishedToWeb && _vm.props.status === 'Active')?_c(_vm.$options.components.CommonChip,{tag:"component",staticClass:"wide-chip",attrs:{"dark":"","color":"green"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20"}},[_vm._v("mdi-web")]),_vm._v(" Active To Web ")],1):_c(_vm.$options.components.AvailabilityStatusChip,_vm._g(_vm._b({tag:"component",staticClass:"wide-chip",attrs:{"status":_vm.props.status}},'component',attrs,false),on))]}}],null,false,4012332679)},[_c('span',[_vm._v("Status")])]):_vm._e(),(_vm.props.inHouse)?_c(_vm.$options.components.CommonChip,{tag:"component",staticClass:"wide-chip",attrs:{"dark":"","color":"green"}},[_vm._v(" In House ")]):_vm._e(),(_vm.props.agencyType)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(_vm.$options.components.CommonChip,_vm._g(_vm._b({tag:"component",staticClass:"wide-chip",attrs:{"color":_vm.props.agencyType === 'Exclusive' ? 'green' : 'grey darken-3',"dark":""}},'component',attrs,false),on),[_vm._v(" "+_vm._s(_vm.props.agencyType)+" ")])]}}],null,false,4073694589)},[_c('span',[_vm._v("Agency Type")])]):_vm._e()],1):_vm._e(),(_vm.props.isPortfolio)?_c('div',[_c(_vm.$options.components.CommonChip,{tag:"component",staticClass:"wide-chip",attrs:{"dark":"","color":"#A020F0"}},[_vm._v(" Portfolio ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }