<template>
  <v-dialog v-model="showModal" fullscreen :max-width="maxWidth">
    <v-card flat class="mt-5">
      <v-container>
        <v-btn
          class="ma-5"
          fab
          absolute
          right
          size="80"
          icon
          @click="closeEventHandler"
        >
          <v-icon size="4em">mdi-close</v-icon>
        </v-btn>
        <v-card-text>
          <v-row class="pt-3 justify-center">
            <v-col cols="12">
              <ContactSelect
                :selected="selectedContacts"
                :visibility="visibility"
                :reset="reset"
                :query-by-role="queryByRole"
                :allow-selection="allowSelection"
                :title="title"
                is-force-touch
                @select="selectHandler"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          v-if="$vuetify.breakpoint.xs"
          style="
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            background-color: #fff;
          "
        >
          <v-spacer />
          <v-btn color="primary" @click="connectContactHandler">
            {{ buttonText }}
          </v-btn>
          <v-btn text @click="closeEventHandler">Cancel</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
    <v-snackbar
      v-model="selectedContacts.length"
      class="connect-contact-snackbar"
      right
      max-width="300px"
      :timeout="-1"
      light
    >
      <div>
        <div class="overline">{{ title.substring(0,3) === 'Add' ? title : `Add ${title}` }}</div>
        <v-card
          v-for="contact in selectedContacts"
          :key="contact._id"
          class="mb-4"
          flat
        >
          <ContactCard
            :item="contact"
            allow-remove
            :source="source"
            :contact-company="contactCompany"
            :is-outlined="true"
            :use-company="useCompany"
            :show-photo="false"
            show-internal
            is-flat
            @removeHandler="removeHandler(contact._id)"
            @updateContactCompany="setContactCompany"
          />
        </v-card>
        <div v-if="selectedContacts.length" class="text-right mt-2">
          <v-spacer />
          <v-btn color="primary" @click="connectContactHandler">
            {{ buttonText }}
          </v-btn>
          <v-btn
            text
            class="cancel"
            @click="closeEventHandler"
          >
            Cancel
          </v-btn>
        </div>
      </div>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import ContactSelect from './ContactSelect'
import ContactCard from '@/components/common-components/recordCards/ContactCard'

export default {
  name: 'ConnectContact',

  components: {
    ContactSelect,
    ContactCard,
  },

  props: {
    value: Boolean,
    closable: Boolean,
    source: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Select Contact',
    },
    displayTitle: {
      type: Boolean,
      default: true,
    },
    flat: Boolean,
    contacts: {
      type: Array,
      default: () => [],
    },
    transactionType: {
      type: String,
      default: '',
    },
    visibility: {
      type: [Boolean, Number],
      default: false,
    },
    role: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'Add Contacts',
    },
    reset: {
      type: Number,
      default: 0,
    },
    queryByRole: {
      type: String,
      default: '',
    },
    useCompany: Boolean,
    allowMultiple: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: String,
      default: '100%'
    }
  },

  data: () => ({
    sourceInstance: null,
    selectedContacts: [],
    contactCompany: null,
  }),

  computed: {
    allowSelection () {
      return !(!this.allowMultiple && this.selectedContacts.length)
    },
    showModal: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  },

  watch: {
    contacts: function (value) {
      this.contactsChangeHandler(value)
    },
    visibility: function (value) {
      if (!value) {
        this.selectedContacts = []
      }
    },
  },

  methods: {
    setContactCompany (payload) {
      this.contactCompany = payload
    },
    /**
     * Handler for select contact event
     * @param {Array} items
     * */
    selectHandler: function (items) {
      this.contactCompany = items.length === 1 ? items[0]._id : this.contactCompany
      this.selectedContacts = items.map((item) => {
        item.role = ''
        return item
      })
    },
    /**
     * Handler for remove event
     * @param {string} id
     * */
    removeHandler: function (id) {
      this.selectedContacts = this.selectedContacts.filter(
        (item) => item._id !== id
      )
    },
    /**
     * Handler for connect contact event
     * */
    connectContactHandler () {
      const connectedContacts = this.selectedContacts.map((contact) => {
        contact.roles = this.role ? [this.role] : contact.roles
        contact.useCompany = contact._id === this.contactCompany
        return contact
      })
      this.$emit('connect', connectedContacts, true)
      this.selectedContacts = []
    },
    /**
     * Handler for contacts change event
     * @param {Array} value
     * */
    contactsChangeHandler: function (value) {
      if (value.length) {
        this.selectedContacts = [
          ...this.selectedContacts,
          ...value.filter(
            (item) =>
              !this.selectedContacts.some(
                (contact) => contact._key === item._key
              )
          ),
        ]
      }
    },
    /**
     * Handler for close event
     * */
    closeEventHandler: function () {
      this.$emit('close')
    },
  },
}
</script>
