


















import Vue, { PropType } from 'vue';
import { ITextValue } from '@/types/common';
import CommonChip from '@/bundles/Common/components/chips/CommonChip.vue';

export default Vue.extend({
  name: 'VCardImportSelect',

  components: { CommonChip },

  props: {
    value: {
      type: String,
      default: ''
    },
    items: {
      type: Array as PropType<ITextValue<string>[]>,
      default: () => []
    }
  },

  computed: {
    selectValue: {
      get (): string {
        return this.value;
      },
      set (value: string) {
        this.$emit('input', value);
      }
    },
  }
})
