<template>
  <div class="contact-select" :style="`${!cuttedArray.length ? 'margin: 20%;' : 'margin-top: 10px;'}`">
    <div class="" :class="{ 'mb-0': !isFetched }">
      <v-row>
        <v-col cols="12">
          <h1 class="mb-5 grey--text text--darken-1">{{ title.substring(0,3) === 'Add' ? title : `Add ${title}` }}</h1>
          <v-text-field
            ref="searchInput"
            v-model="searchValue"
            class=""
            height="60px"
            :loading="fetchLoader"
            label="Search"
            prepend-inner-icon="mdi-magnify"
            placeholder="Enter a name, email address or phone number to search"
            outlined
            hide-details
            clearable
          />
          <span v-if="!searchValue || searchValue.length < 3" class="grey--text">
            Start typing to see contacts
          </span>
        </v-col>
        <v-col>
          <div class="contact-select__items mt-5">
            <v-row>
              <v-col
                v-for="contact in cuttedArray"
                :key="contact._key"
                class="pt-0"
                cols="6"
              >
                <v-card>
                  <ContactSelectItem
                    :item="contact"
                    :selected="isSelected(contact._id)"
                    :disabled="!allowSelection"
                    @toggleSelect="selectHandler"
                  />
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-show="items.length > toDisplay" class="contact-select__more">
            ...and {{ total - toDisplay }} more.
            <a href="javascript:void(0)" @click="loadMoreHandler">Load more</a>
          </div>
          <div v-show="isFetched" class="mt-3">
            Not finding your contact?
            <a @click="contactFormDrawer = true">Click here to create a new contact</a>
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="isFetched && items.length && searchValue !== '' > 0"
      class="contact-select__items-count pt-1"
    >
      <span>Results</span> (1-{{ toDisplay }} of {{ total }})
    </div>
    <div
      v-if="isFetched && items.length === 0 && searchValue !== ''"
      class="contact-select__items-count pt-1"
    >
      <span>No Results</span>
    </div>

    <v-navigation-drawer
      v-model="contactFormDrawer"
      class="task-form-drawer sc-navigation-drawer"
      :width="600"
      light
      right
      app
      temporary
      touchless
    >
      <ContactForm
        :contact="newContact"
        :edit="editContact"
        source-type="ContactSelect"
        @closeDialog="resetNewContact"
        @refreshContacts="resetNewContact"
        @added="addedHandler"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { ServiceFactory } from '@/services/ServiceFactory';
import ContactSelectItem from './ContactSelectItem.vue';
import ContactForm from '@/components/contacts/ContactForm';

const ContactService = ServiceFactory.get('contact');

export default {
  name: 'ContactSelect',

  components: {
    ContactSelectItem,
    ContactForm,
  },

  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    visibility: {
      type: [Number, Boolean],
      default: 0,
    },
    queryByRole: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    allowSelection: {
      type: Boolean,
      default: true,
    },
    outlined: Boolean,
    isFlat: Boolean,
    elevation: {
      type: Number,
      default: 0
    },
    isForceTouch: Boolean
  },

  data: () => ({
    items: [],
    searchDelay: null,
    fetchLoader: false,
    total: 0,
    toDisplay: 25,
    isFetched: false,
    contactFormDrawer: false,
    editContact: false,
    newContact: {
      img: '',
      first_name: '',
      last_name: '',
      email: '',
      title: '',
      company: '',
      phone: {
        direct: '',
        mobile: '',
        office: '',
        fax: '',
      },
    },
    searchValue: '',
  }),

  computed: {
    ...mapState({
      token: (state) => state.idToken,
    }),
    cuttedArray: function () {
      return this.items.slice(0, this.toDisplay);
    },
    isInternal: function () {
      return this.title.includes('Source');
    }
  },

  watch: {
    visibility: {
      handler: function (newValue) {
        if (newValue) {
          this.searchValue = '';
          this.isFetched = false;
        }

        setTimeout(() => {
          this.$refs.searchInput.focus();
          if (this.isInternal) {
            this.fetchWrapper();
          }
        }, 100);
      },
      immediate: true,
    },
    searchValue: function (value) {
      this.searchChangeHandler(value);
    },
    queryByRole: function (value) {
      this.searchChangeHandler(value);
      if (!this.isFetched) this.isFetched = true;
    }
  },

  methods: {
    ...mapMutations('Notifications', ['ADD_NOTIFICATION']),
    addedHandler (data) {
      this.items.unshift(data);
      // eslint-disable-next-line vue/no-mutating-props
      this.selected.push(data);
      this.$emit('select', this.selected);
      this.contactFormDrawer = false;
    },
    resetNewContact () {
      this.contactFormDrawer = false;
    },
    fetch: async function (search) {
      if (search?.length > 0 || this.queryByRole !== '') {
        try {
          this.fetchLoader = true;
          const params = [
            { name: 'contact_internal', value: this.isInternal },
          ];

          if (this.queryByRole !== '') {
            const queriedByRole = { name: 'searchRole', value: 'Broker' };
            params.push(queriedByRole);
          }

          if (search?.length > 0) {
            const keywordSearch = { name: 'keyword', value: search };
            params.push(keywordSearch)
          }
          const { data } = await ContactService.list({ params, token: this.token });
          return { contacts: data.result, total: data.total };
        } catch (error) {
          const notification = { ...error };
          this.ADD_NOTIFICATION(notification);
        } finally {
          this.fetchLoader = false;
        }
      } else {
        return { contacts: [], total: 0 };
      }
    },
    searchChangeHandler: function (value) {
      if (!value || value.length < 3) {
        this.items = [];
        this.total = 0;
        return;
      }

      clearTimeout(this.searchDelay);
      this.searchDelay = setTimeout(() => {
        this.fetchWrapper(value);
      }, 500);
    },
    async fetchWrapper (value) {
      try {
        this.isFetched = false;
        const { contacts, total } = await this.fetch(value);

        this.items = contacts;
        this.total = total;

        this.toDisplay = this.total < 25 ? this.total : 25;
      } catch (error) {
        const notification = { ...error };
        this.ADD_NOTIFICATION(notification);
      } finally {
        this.isFetched = true;
      }
    },
    loadMoreHandler: function () {
      this.toDisplay += 25;
      this.toDisplay = this.toDisplay > this.total ? this.total : this.toDisplay;
    },
    selectHandler: function (id) {
      const selected = this.selected.find((item) => item._id === id)
        ? this.selected.filter((item) => item._id !== id)
        : [...this.selected, this.items.find((item) => item._id === id)];
      this.$emit('select', selected);
    },
    isSelected: function (id) {
      return !!this.selected.find((item) => item._id === id);
    },
  },
};
</script>
