import { IOtpAuthResponse } from '@/bundles/Auth/types';

export class OtpAuthResponse implements IOtpAuthResponse {
  access_token: string;
  otp_required: boolean;

  constructor (data: IOtpAuthResponse) {
    this.access_token = data.access_token;
    this.otp_required = data.otp_required
  }
}
