<template>
  <v-card flat>
    <v-card-text class="pb-0">
      <v-form ref="companyNameForm">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field
              ref="companyUrl"
              v-model="tempCompany.url"
              filled
              label="Company domain name"
              :rules="[rules.domain]"
            >
              <template #append>
                <v-fade-transition leave-absolute>
                  <v-progress-circular
                    v-if="searchByUrlLoading"
                    size="24"
                    color="info"
                    indeterminate
                  />
                </v-fade-transition>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-text-field
              ref="companyName"
              v-model="tempCompany.name"
              filled
              label="Company Name"
              :rules="[rules.name]"
              :loading="loadingCompanies"
              autocomplete="off"
            />
          </v-col>
        </v-row>
        <v-card
          v-if="companies.length"
          outlined
          style="overflow: hidden; position: absolute; z-index: 6; width: 80%; left: 10%;"
        >
          <v-toolbar
            v-if="isCompanyFoundBySearch && isFormDisabled"
            type="info"
            flat
            color="primary"
            dark
          >
            <v-toolbar-title>We found some matches</v-toolbar-title>
            <v-spacer />
            {{ companies.length }} Companies
          </v-toolbar>
          <v-virtual-scroll
            :bench="benched"
            :items="companies"
            :height="companies.length > 2 ? 300 : companies.length === 2 ? 200 : 100"
            item-height="100"
            class="mt-3"
          >
            <template #default="{ item }">
              <v-list-item :key="item._key" :ripple="true">
                <v-list-item-content>
                  <v-list-item-title>
                    <div class="record-id">Company ID# {{ item._key }}</div>
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.address.street }}, {{ item.address.city }} {{ item.address.state }} {{ item.address.postal }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.url }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    :to="getAccountRoute({ name: 'company', params: { id: item._key }})"
                    target="_blank"
                  >
                    <v-icon small>
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          <template v-if="isCompanyFoundBySearch && isFormDisabled && tempCompany.url">
            <v-divider />
            <v-card-actions>
              Sorry, the provided URL is already in use by another company.
              Please choose a different URL for your company.
            </v-card-actions>
          </template>
          <template v-if="tempCompany.name && !tempCompany.url">
            <v-divider />
            <div class="pa-4">
              <span class="cursor-pointer primary--text" @click="createNewCompanyHandler">
                Cant find your company? Create a new one</span>
            </div>
          </template>
        </v-card>
      </v-form>
      <div :class="{ 'pb-0': overlay }">
        <v-row>
          <v-col>
            <v-card flat>
              <v-form ref="companyForm" lazy-validation style="position:relative;">
                <v-card flat :class="{ 'pa-4': overlay }">
                  <v-overlay
                    absolute
                    :opacity="0.8"
                    color="#fff"
                    :value="overlay"
                    class="form-field-overlay"
                  >
                    <v-card dark>
                      <v-card-text>
                        <span class="subtitle-1">{{ getMessage }}</span>
                      </v-card-text>
                      <v-card-actions v-if="errorMessage" class="pt-0">
                        <v-spacer />
                        <v-btn color="primary" @click="overlay = false">Enter company details</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-overlay>
                  <v-row>
                    <v-col cols="12" class="d-flex">
                      <span class="mr-2">Logo:</span>
                      <v-img
                        :src="tempCompany.logo || $placeholderCompanyImg"
                        max-height="150"
                        max-width="150"
                        aspect-ratio="1"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="6">
                          <v-autocomplete
                            v-model="tempCompany.industry_group"
                            label="Industry Group"
                            :items="industryGroupTypes"
                            clearable
                            autocomplete="off"
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-autocomplete
                            v-model="tempCompany.industry"
                            label="Industry"
                            :disabled="!tempCompany.industry_group"
                            :items="companyIndustryTypes"
                            clearable
                            autocomplete="off"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="tempCompany.address.street"
                            label="Address"
                            autocomplete="off"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="tempCompany.address.street2"
                        label="Address 2"
                        autocomplete="off"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="5">
                      <v-text-field
                        v-model="tempCompany.address.city"
                        label="City"
                        autocomplete="off"
                        name="companyCity"
                      />
                    </v-col>
                    <v-col cols="4">
                      <StateSelect
                        v-model="tempCompany.address.state"
                        :country="tempCompany.address.country"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="tempCompany.address.postal"
                        label="Postal Code"
                        autocomplete="off"
                        name="companyPostalCode"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="tempCompany.address.country"
                        :items="countries"
                        label="Country"
                        item-text="name"
                        item-value="code"
                        autocomplete="dontshow"
                        name="companyCountry"
                        @change="tempCompany.address.state = null;"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <CommonPhonePicker
                        show-extension
                        :phone-number="tempCompany.phone.office"
                        :phone-extension="tempCompany.phone.office_ext"
                        :phone-country-code="tempCompany.phone.office_country_code"
                        @save:number="tempCompany.phone.office = $event"
                        @save:extension="tempCompany.phone.office_ext = $event"
                        @save:countryCode="tempCompany.phone.office_country_code = $event"
                      >
                        <template #default="{ on, attrs }">
                          <v-text-field
                            v-model="tempCompany.phone.office"
                            v-facade="getCountryMask(tempCompany.phone.office_country_code)"
                            :suffix="tempCompany.phone.office_ext ? `ext: ${tempCompany.phone.office_ext}` : null"
                            label="Office Phone"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                      </CommonPhonePicker>
                    </v-col>
                    <v-col cols="6">
                      <CommonPhonePicker
                        show-extension
                        :phone-number="tempCompany.phone.fax"
                        :phone-extension="tempCompany.phone.fax_ext"
                        :phone-country-code="tempCompany.phone.fax_country_code"
                        @save:number="tempCompany.phone.fax = $event"
                        @save:extension="tempCompany.phone.fax_ext = $event"
                        @save:countryCode="tempCompany.phone.fax_country_code = $event"
                      >
                        <template #default="{ on, attrs }">
                          <v-text-field
                            v-model="tempCompany.phone.fax"
                            v-facade="getCountryMask(tempCompany.phone.fax_country_code)"
                            :suffix="tempCompany.phone.fax_ext ? `ext: ${tempCompany.phone.fax_ext}` : null"
                            label="Office Fax"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                      </CommonPhonePicker>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="tempCompany.metrics.employees"
                        label="Employees"
                        autocomplete="off"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="tempCompany.metrics.annualRevenue"
                        label="Annual Revenue"
                        autocomplete="off"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="tempCompany.metrics.estimatedAnnualRevenue"
                        label="Estimated Annual Revenue"
                        autocomplete="off"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="tempCompany.year_established"
                        label="Year Established"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        v-model="tempCompany.description"
                        label="Description"
                      />
                    </v-col>
                  </v-row>
                  <div style="margin: 0 -1rem;">
                    <CompanyBrokerageSection
                      v-model="tempCompany.brokerLicense"
                      :is-brokerage.sync="tempCompany.isBrokerage"
                    />
                  </div>
                </v-card>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions class="sc-navigation-drawer-actions">
      <v-spacer />
      <v-btn
        text
        class="cancel"
        :small="$vuetify.breakpoint.smAndDown"
        @click="cancelNewCompany"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="isCompanyFoundBySearch && isFormDisabled"
        color="primary"
        class="px-4"
        :disabled="isCompanyFoundBySearch"
        :small="$vuetify.breakpoint.smAndDown"
        @click="isFormDisabled = false"
      >
        Create New Company
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="px-4"
        :loading="isLoading"
        :disabled="isSaveCompanyBtnDisable"
        :small="$vuetify.breakpoint.smAndDown"
        @click="saveCompany"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { TOGGLE_SPLASH_SCREEN } from '@/store/action-types';
import { mapMutations, mapGetters, mapState } from 'vuex';
import { eventBus } from '@/eventbus/eventbus';
import { debounce } from 'lodash';
import { getCompanyDefaultObj, getCompanyDefaultPhoneObj } from '@/bundles/Companies/helpers/companyDefaultData';
import { ServiceFactory } from '@/services/ServiceFactory';
import { companyService } from '@/bundles/Companies/factory/companyServiceFactory';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';
import { RequestAbortMixin } from '@/bundles/App/mixins/RequestAbortMixin';
import { AppFormMixin } from '@/bundles/App/mixins/forms/AppFormMixin';
import { CountriesMasksMixin } from '@/bundles/Common/mixins/CountriesMasksMixin';

import CommonPhonePicker from '@/bundles/Common/components/CommonPhonePicker';
import CompanyBrokerageSection from '@/bundles/Companies/components/forms/CompanyBrokerageSection.vue';
import StateSelect from '@/bundles/App/components/inputs/StateSelect.vue';

const CompanyService = ServiceFactory.get('company');

export default {
  name: 'CompanyAddForm',

  components: {
    StateSelect,
    CommonPhonePicker,
    CompanyBrokerageSection,
  },

  mixins: [AccountLayoutMixin, RequestAbortMixin, AppFormMixin, CountriesMasksMixin],

  props: {
    sourceType: {
      type: String,
      default: '',
    },
    sourceId: {
      type: [String, Number],
      default: '',
    },
    company: {
      type: Object,
      default: () => ({
        name: '',
        logo: '',
        url: '',
        description: '',
        address: {
          street: '',
          street2: '',
          city: '',
          state: '',
          postal: '',
        },
        phone: getCompanyDefaultPhoneObj(),
        industry_group: '',
        industry: '',
        isBrokerage: false,
        brokerLicense: {
          legal_name: '',
          number: '',
          state: '',
          taxId: ''
        },
        metrics: {
          employees: '',
          annualRevenue: '',
          estimatedAnnualRevenue: ''
        }
      }),
    },
    isOpen: Boolean,
  },

  data: () => ({
    companies: [],
    loadingCompanies: false,
    isFormDisabled: true,
    rules: {
      name: (value) => (!!value && value.length > 1) || 'Name length must be more than 1 characters',
      domain: value => {
        if (!value) { return true; }

        const pattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
        return pattern.test(value) || 'Invalid domain name';
      },
    },
    tempCompany: getCompanyDefaultObj(),
    defaultCompany: getCompanyDefaultObj(),
    benched: 0,
    overlay: true,
    searchByUrlLoading: false,
    errorMessage: null,
    isLoading: false,
  }),

  computed: {
    ...mapState({
      token: (state) => state.idToken,
      industryGroupTypes: state => state.Company.industryGroupTypes,
      industryTypes: state => state.Company.industryTypes,
    }),
    ...mapGetters(['countries']),
    companyIndustryTypes () {
      const type = this.industryTypes.find(
        (item) => item.group === this.tempCompany.industry_group
      );
      return type ? type.types : [];
    },
    isCompanyFoundBySearch () {
      return !!this.companies.length && !this.loadingCompanies;
    },
    isSaveCompanyBtnDisable () {
      return !this.tempCompany.name || this.tempCompany.name.length <= 1 || this.loadingCompanies;
    },
    getMessage () {
      return this.errorMessage || 'Enter a company name or domain name to get started';
    }
  },

  watch: {
    isOpen (value) { // todo: rework with v-if on parent component
      if (value) {
        this.errorMessage = null;

        setTimeout(() => { // next tick not working
          this.$refs.companyUrl.focus();
        }, 100);
      }
    },
    'tempCompany.name' (value) {
      if (!!value && value.length > 1) {
        this.isFormDisabled = true;
        this.searchCompaniesDebounced(this);
      }
    },
    'tempCompany.url' () {
      const isCompanyDomainValid = this.$refs.companyUrl.validate();

      if (!isCompanyDomainValid || !this.tempCompany.url) {
        return;
      }

      this.searchCompaniesByUrlDebounced(this);
    },
  },

  mounted () {
    this.tempCompany = Object.assign({}, this.company);
    eventBus.$on('clearCompany', this.clearCompany);
  },

  beforeDestroy () {
    eventBus.$off('clearCompany', this.clearCompany);
  },

  methods: {
    ...mapMutations({
      toggleSplashScreen: TOGGLE_SPLASH_SCREEN,
    }),
    searchCompaniesDebounced: debounce(async function (vm) {
      await vm.searchCompanies();
    }, 400),
    searchCompanies: async function () {
      try {
        this.overlay = true;
        this.loadingCompanies = true;

        const res = await CompanyService.list({
          token: this.token,
          params: [
            { name: 'sort', value: 'name' },
            { name: 'descending', value: 'desc' },
            { name: 'url', value: this.tempCompany.url },
            { name: 'name', value: this.tempCompany.name }
          ],
        });
        const { result: companies } = res.data;
        if (companies.length > 20) {
          companies.length = 20;
        }
        this.companies = companies;
        this.overlay = this.companies.length;
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.loadingCompanies = false;
      }
    },
    searchCompaniesByUrlDebounced: debounce(async function (vm) {
      await vm.searchCompaniesByUrlHandler();
    }, 400),
    async searchCompaniesByUrlHandler () {
      try {
        this.searchByUrlLoading = true;

        this.handleRequestAbort();
        const company = await companyService.getCompanyByEnrich({
          params: [{ name: 'domain', value: this.tempCompany.url }],
          controller: this.abortController
        });

        if (typeof company === 'object' && company !== null) {
          this.tempCompany = { ...this.tempCompany, ...company, url: this.tempCompany.url };
          return;
        }

        this.companies = [];

        this.errorMessage = company || null;
      } catch (error) {
        if (error.message === 'canceled') {
          return;
        }

        this.addNotification({ ...error, status: 0 });
        this.errorMessage = error.message;
      } finally {
        this.searchByUrlLoading = false;
      }
    },
    saveCompany () {
      if (this.tempCompany._key) {
        this.updateCompany();
      } else {
        this.saveNewCompany();
      }
    },
    async saveNewCompany () {
      if (!this.$refs.companyForm.validate()) {
        this.showFormValidationErrorNotification();
        return;
      }

      this.isLoading = true;
      const submitDate = Date.now();

      const formData = {
        date_insert: submitDate,
        date_update: submitDate,
        name: this.tempCompany.name,
        logoSource: this.tempCompany.logo,
        logoUrl: this.tempCompany.logo,
        url: this.tempCompany.url,
        industry: this.tempCompany.industry,
        industry_group: this.tempCompany.industry_group,
        description: this.tempCompany.description,
        address: { ...this.tempCompany.address },
        phone: { ...this.tempCompany.phone },
        metrics: { ...this.tempCompany.metrics },
        isBrokerage: this.tempCompany.isBrokerage,
        brokerLicense: {
          legal_name: this.tempCompany.brokerLicense.legal_name,
          number: this.tempCompany.brokerLicense.number,
          state: this.tempCompany.brokerLicense.state,
          taxId: this.tempCompany.brokerLicense.taxId
        },
        year_established: this.tempCompany.year_established
      };

      try {
        const { data } = await CompanyService.create({ token: this.token, payload: formData });

        if (data) {
          this.$emit('updatePropertyHandler', data);
          this.$emit('companyAdded', data);
          this.$emit('closeDialog', data);
          this.clearCompany();
        }
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.isLoading = false;
      }
    },
    cancelNewCompany () {
      this.clearCompany();
      this.$emit('cancelCompany');
    },
    uploadSuccess (result) {
      this.tempCompany.logo = result.filesUploaded[0].url;
    },
    clearCompany () {
      this.$refs.companyNameForm.reset();
      this.$refs.companyForm.reset();
      setTimeout(() => {
        this.tempCompany = Object.assign({}, this.defaultCompany);
        this.toggleSplashScreen(false);
      }, 500);
    },
    createNewCompanyHandler () {
      this.isFormDisabled = false;
      this.overlay = false;
      this.companies = [];
    }
  },
};
</script>
