import AccountService from '@/bundles/Account/services/accountService';
import { httpV2 } from '@/bundles/Http/factory/httpFactory';
import ErrorMapper from '@/bundles/Http/mappers/ErrorMapper';
import AccountToMapper from '@/bundles/Account/mappers/AccountToMapper';

function accountServiceFactory () {
  return new AccountService({
    transport: httpV2,
    errorMapper: new ErrorMapper(),
    accountMapper: new AccountToMapper(),
  });
}

export const accountService = accountServiceFactory();
