import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import { NotificationMutations } from '@/store/types/mutation-types';
import { LocalizationEnum } from '@/bundles/Localization/enum/LocalizationEnum';
import { StoreModulesNamesEnum } from '@/store/StoreModulesNamesEnum';
import { placeholderContactImg } from '@/settings';

interface IAppMixin {
  LocalizationEnum: typeof LocalizationEnum;
}

export const AppMixin = Vue.extend({
  data: (): IAppMixin => ({
    LocalizationEnum
  }),

  computed: {
    ...mapState({
      contactPlaceholder: (state: any): string => state.accountPreferences?.contact_placeholder || placeholderContactImg,
    })
  },

  methods: {
    ...mapMutations(StoreModulesNamesEnum.Notifications, {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    addSuccessNotification (message: string, title?: string): void {
      this.addNotification({
        status: 200,
        title: title || 'Success',
        message,
      });
    }
  }
});
