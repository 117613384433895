

































import Vue, { PropType } from 'vue';
import { UserWidgetsEnum } from '@/bundles/Settings/enums/userLayoutEnum';
import { debounce } from 'lodash';

export default Vue.extend({
  name: 'DashboardCard',

  props: {
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      default: 0,
    },
    widgetType: {
      type: String as PropType<UserWidgetsEnum>,
      required: true,
    },
    loading: Boolean,
    expanded: Boolean,
  },

  data: (): { showContent: boolean } => ({
    showContent: true,
  }),

  mounted () {
    this.showContent = this.expanded;
  },

  methods: {
    toggleCard () {
      this.showContent = !this.showContent;
      this.saveCardStateDebounced(this);
    },
    saveCardStateDebounced: debounce(function (vm) {
      vm.$emit('update:expanded', {
        name: vm.widgetType,
        value: vm.showContent
      });
    }, 400),
  }
});
