export enum FilterAllExcludeFilterEnum {
  exclude = 'exclude',
  all = 'all',
  filter = 'filter',
}

export const FilterAllExcludeFilterLabels = {
  [FilterAllExcludeFilterEnum.exclude]: `Exclude`,
  [FilterAllExcludeFilterEnum.all]: 'All',
  [FilterAllExcludeFilterEnum.filter]: `Only`,
}
