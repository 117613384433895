<template>
  <!-- TODO: refactor component-->
  <div class="record-card-contact" :class="{ 'record-card-contact--full-height': isListView }">
    <v-card
      v-if="displayCard"
      :class="[
        {'sc-record-card--no-pointer sc-record-card--no-hover': !isListView},
        `sc-record-card mx-auto ${cardClass}`
      ]"
      :outlined="isOutlined"
      :flat="isFlat"
      :height="isListView ? '100%' : 'auto'"
      :ripple="false"
      @click="changeSelected"
    >
      <v-card-title v-if="showTitle">Contact</v-card-title>
      <v-divider v-if="showTitle" />
      <v-list class="pa-0">
        <v-list-item three-line @click="handleClick">
          <v-card class="sc-record-avatar mr-3" :elevation="0" :ripple="false">
            <Upload
              v-model="showUploadPhoto"
              :options="uploadOptions"
              :source-id="item._key"
              source-type="contact"
              @fileUploaded="uploadPhoto"
            >
              <v-avatar
                v-if="showPhoto"
                class="editable-avatar"
                size="80"
                rounded="lg"
              >
                <v-img
                  v-if="!savingPhoto"
                  :src="imageUrl"
                  contain
                  :class="{ 'record-card__edit-avatar': allowEdit }"
                >
                  <template v-if="allowEdit && !item.readonly">
                    <v-icon class="record-card__icon" @click="showUploadPhoto = true">mdi-pencil</v-icon>
                    <ConfirmationDialog v-model="confirmRemoveLogoDialog" @cancel="confirmRemoveLogoDialog = false" @submit="removePhotoHandler">
                      Are you sure you want to remove photo from this record?
                    </ConfirmationDialog>
                    <v-icon class="record-card__icon" @click="confirmRemoveLogoDialog = true">mdi-trash-can-outline</v-icon>
                  </template>
                </v-img>
                <v-progress-circular
                  v-else
                  :width="3"
                  color="green"
                  indeterminate
                />
              </v-avatar>
            </Upload>
          </v-card>
          <v-list-item-content>
            <v-list-item-title class="title mb-1">
              <span class="record-id">Contact ID# {{ item._key }}</span>
              <router-link
                v-if="allowLink"
                :to="getAccountRoute({ name: 'contact', params: { id: item._key }})"
                class="headline mb-1"
              >
                {{ item.first_name }} <span :style="`${source !== 'record' ? 'font-weight: 900' : ''}`">{{ item.last_name }}</span>
                <template v-if="item.nickname">
                  ({{ item.nickname }})
                </template>
              </router-link>
              <div v-else class="headline mb-1">
                {{ item.first_name }} <span :style="`${source !== 'record' ? 'font-weight: 900' : ''}`">{{ item.last_name }}</span>
                <template v-if="item.nickname">
                  ({{ item.nickname }})
                </template>
              </div>
              <div><small style="white-space: nowrap; font-weight: normal; font-size: .65em; text-transform: capitalize;">{{ item.title }}</small></div>
              <a v-if="allowEdit & !item.readonly" @click="editHandler">
                <v-btn x-small class="record-card-edit" icon><v-icon>mdi-pencil</v-icon></v-btn>
              </a>
              <router-link v-if="allowMerge" :to="getNavigationLink" class="router-link">
                <v-btn icon x-small>
                  <v-icon>mdi-call-merge</v-icon>
                </v-btn>
              </router-link>
              <v-btn
                v-if="allowExport"
                icon
                x-small
                :loading="exportLoading"
                @click="exportContact"
              >
                <v-icon>mdi-file-export</v-icon>
              </v-btn>
            </v-list-item-title>
            <span v-if="showCompany">
              <span class="contact-company">{{ item.company ? item.company.name : '' }}</span>
              <span class="contact-address">
                {{ getContactAddress.street }}
                {{ getContactAddress.city }}
                {{ getContactAddress.state }}
                {{ getContactAddress.postal }}
              </span>
            </span>
            <div v-if="useCompany" class="pl-4">
              <v-switch
                v-model="contactCompanyValue"
                :value="item._id"
                label="Use this Company for the comparable?"
                @change="updateContactCompany($event)"
              />
            </div>
            <v-list-item-subtitle class="d-flex align-center flex-wrap gap-2">
              <ContactMemberChip v-if="item.is_internal" />
              <PublishedToWebChip v-if="item.published_to_web" />
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="allowRemove" class="mt-2 ml-3">
            <v-btn icon @click="removeHandler">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-if="allowSelect" class="mr-3">
            <v-checkbox
              :value="selected"
              :disabled="disableSelect"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider v-if="showSummary" class="ma-4 mb-0" />
      <v-card-text v-if="showSummary">
        <div>
          <strong>Direct: </strong>
          <span v-if="item.phone.direct">
            <a :href="`tel:${item.phone.direct}`">
              <span>{{ item.phone.direct | formatPhone }}</span>
            </a>
            <span v-if="item.phone.direct_ext" class="ml-1">Ext: {{ item.phone.direct_ext }}</span>
          </span>
          <span v-else class="na">n/a</span>
        </div>
        <div>
          <strong>Mobile: </strong>
          <span v-if="item.phone.mobile">
            <a :href="`tel:${item.phone.mobile}`">
              <span>{{ item.phone.mobile | formatPhone }}</span>
            </a>
            <span v-if="item.phone.mobile_ext" class="ml-1">Ext: {{ item.phone.mobile_ext }}</span>
          </span>
          <span v-else class="na">n/a</span>
        </div>
        <div>
          <strong>Office: </strong>
          <span v-if="item.phone.office">
            <a :href="`tel:${item.phone.office}`">
              <span>{{ item.phone.office | formatPhone }}</span>
            </a> <span v-if="item.phone.office_ext">Ext: {{ item.phone.office_ext }}</span>
          </span>
          <span v-else class="na">n/a</span>
        </div>
        <div>
          <strong>Fax: </strong>
          <span v-if="item.phone.fax">
            <a :href="`tel:${item.phone.fax}`">
              <span>{{ item.phone.fax }}</span>
            </a> <span v-if="item.phone.fax_ext">Ext: {{ item.phone.fax_ext }}</span>
          </span>
          <span v-else class="na">n/a</span>
        </div>
        <div>
          <strong>Email: </strong>
          <a v-if="item.email" :href="`mailto:${item.email}`">
            <span>{{ item.email }}</span>
          </a>
          <span v-else class="na">n/a</span>
        </div>
        <v-divider class="ma-5" />
        <div>
          <div><strong>Office Address</strong></div>
          <template v-if="item.office_address && item.office_address.street">
            {{ getOfficeContactAddress.street }}
            {{ getOfficeContactAddress.street2 }}<br>
            {{ getOfficeContactAddress.city }}
            {{ getOfficeContactAddress.state }}
            {{ getOfficeContactAddress.postal }}
            <LocalTimeLabel :timezone="getOfficeContactAddress.timezone_name" />
          </template>
          <template v-else>
            <span class="na">n/a</span>
          </template>
        </div>
        <div v-if="item.home_address && item.home_address.street"><strong>Home Address</strong></div>
        <template v-if="item.home_address && item.home_address.street">
          {{ item.home_address.street }}<span v-if="item.home_address.street2">, {{ item.home_address.street2 }}</span><br>
          {{ item.home_address.city }}, {{ item.home_address.state }} {{ item.home_address.postal }}
        </template>
      </v-card-text>
    </v-card>
    <div v-else class="contact">
      <div class="contact-name">
        {{ item.first_name }} {{ item.last_name }}
      </div>
      <div v-if="item.title" class="contact-title">{{ item.title }}</div>
      <div class="contact-company">
        {{ item.company ? item.company.name : '' }}
      </div>
    </div>
  </div>
</template>

<script>
import { ServiceFactory } from '@/services/ServiceFactory';
import { mapMutations, mapState } from 'vuex';
import { AccountLayoutMixin } from '@/bundles/App/mixins/accountMixin';

import Upload from '@/bundles/Upload/components/Upload';
import ConfirmationDialog from '@/bundles/Common/components/ConfirmationDialog.vue';
import ContactMemberChip from '@/bundles/Contact/components/chips/ContactMemberChip.vue';
import PublishedToWebChip from '@/bundles/Contact/components/chips/PublishedToWebChip.vue';
import LocalTimeLabel from '@/bundles/App/components/labels/LocalTimeLabel.vue';

const ContactService = ServiceFactory.get('contact');

export default {
  name: 'ContactCard',

  components: {
    LocalTimeLabel,
    PublishedToWebChip,
    ContactMemberChip,
    ConfirmationDialog,
    Upload
  },

  mixins: [AccountLayoutMixin],

  props: {
    source: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    cardClass: {
      type: String,
      default: '',
    },
    allowSelect: Boolean,
    disableSelect: Boolean,
    allowRemove: Boolean,
    allowMerge: Boolean,
    selected: Boolean,
    allowExport: Boolean,
    isOutlined: Boolean,
    isFlat: Boolean,
    showPhoto: {
      type: Boolean,
      default: () => true,
    },
    displayCard: {
      type: Boolean,
      default: () => true,
    },
    allowPicker: Boolean,
    allowEdit: Boolean,
    showCompany: {
      type: Boolean,
      default: true,
    },
    showSummary: Boolean,
    allowLink: Boolean,
    isListView: Boolean,
    contactCompany: {
      type: String,
      default: null,
    },
    elevation: {
      type: Number,
      default: 0
    },
    showInternal: Boolean,
    showTitle: Boolean,
    useCompany: Boolean
  },

  data: () => ({
    savingPhoto: false,
    contactsFromVCF: {},
    isSelectFieldsWindowShown: false,
    exportLoading: false,
    showUploadPhoto: false,
    confirmRemoveLogoDialog: false,
  }),

  computed: {
    ...mapState({
      token: (state) => state.idToken,
    }),
    imageUrl () {
      return this.item.img || this.item.company?.logo || this.contactPlaceholder;
    },
    uploadOptions () {
      return { accept: 'image/*', aspectRatio: 1 };
    },
    contactCompanyValue: {
      get () {
        return this.contactCompany;
      },
      set (value) {
        this.$emit('updateContactCompany', value);
      },
    },
    getNavigationLink () {
      return this.getAccountRoute({
        name: 'contact-merge',
        params: { id: this.item._key }
      });
    },
    getContactAddress: function () {
      return {
        street: this.item?.company?.address?.street || '',
        city: this.item?.company?.address?.city || '',
        state: this.item?.company?.address?.state || '',
        postal: this.item?.company?.address?.postal || '',
      };
    },
    getOfficeContactAddress: function () {
      return {
        street: this.item?.office_address?.street || '',
        street2: this.item?.office_address?.street2 || '',
        city: this.item?.office_address?.city || '',
        state: this.item?.office_address?.state || '',
        postal: this.item?.office_address?.postal || '',
        timezone_name: this.item?.office_address?.timezone_name || null,
      };
    }
  },

  methods: {
    ...mapMutations('Notifications', ['ADD_NOTIFICATION']),
    /**
     * Upload the photo for the contact
     */
    async uploadPhoto ({ files }) {
      this.savingPhoto = true;
      try {
        const { data } = await ContactService.updatePhoto({
          id: this.item._key,
          token: this.token,
          payload: { image: files[0] },
        });

        this.$emit('updatePhoto', data.img);
      } catch (error) {
        const notification = { ...error };
        this.ADD_NOTIFICATION(notification);
      } finally {
        this.savingPhoto = false;
        this.showUploadPhoto = false;
      }
    },
    toggle () {
      this.$emit('toggleContact');
    },
    removeHandler () {
      const payload = {
        _edge: this.item._edge,
        _key: this.item._key,
        _id: this.item._id
      };
      this.$emit('removeHandler', payload);
    },
    handleClick () {
      if (!this.disableSelect) {
        this.$emit('toggleContact');
      }
    },
    editHandler () {
      this.$emit('editContact', this.item._id);
    },
    updateContactCompany (event) {
      // this.$emit('updateContactCompany', event)
    },
    async exportContact () {
      try {
        this.exportLoading = true;
        const response = await ContactService.exportVCard({ token: this.token, id: this.item._key });

        const FileSaver = require('file-saver');
        const file = new File([response.data], `export-${this.item.first_name}-${this.item.last_name}.vcf`);
        FileSaver.saveAs(file);
      } catch (error) {
        const notification = { ...error };
        this.ADD_NOTIFICATION(notification);
      } finally {
        this.exportLoading = false;
      }
    },
    changeSelected: function () {
      this.$emit('click', this.item)
    },
    removePhotoHandler () {
      this.uploadPhoto({ files: [null] });
      this.confirmRemoveLogoDialog = false;
    },
  },
};
</script>
<style scoped>
a.router-link {
  text-decoration: none;
}
</style>
