<template>
  <div>
    <v-card flat>
      <v-card-title v-if="(roleTitle !== '' || allowSave) && !isDense">
        {{ roleTitle }}
        <v-spacer />
        <span v-if="allowSave && (contacts || []).length && displayLock">
          <v-icon v-if="!edit" icon @click="edit = true">mdi-lock</v-icon>
          <v-icon v-else @click="edit = false">mdi-lock-open</v-icon>
        </span>
      </v-card-title>
      <div v-for="contact in contacts" :key="contact._key">
        <ContactTile
          :key="contact._key"
          avatar
          :contact="contact"
          :source-type="source"
          :contact-role="role"
          :refresh-contact-edge="refreshContactEdge"
          @removeContact="removeContact"
          @updateReferral="updateReferralHandler"
          @refresh:contact="$emit('refresh:contact', $event)"
        />
      </div>
      <v-card-text
        v-if="showAddBtn"
        :class="{ 'text-center': buttonStyle === 'block' }"
      >
        <v-btn
          v-if="buttonStyle === 'block'"
          block
          x-large
          text
          class="btn-add-contact"
          @click="openContactSelect"
        >
          <v-icon left>mdi-plus</v-icon> Add {{ contactTypeLabel }}
        </v-btn>
        <v-btn v-else block @click="openContactSelect">
          <v-icon left>mdi-plus</v-icon> Add {{ contactTypeLabel }}
        </v-btn>
      </v-card-text>
    </v-card>
    <ConnectContact
      v-model="contactDialog"
      :query-by-role="queryByRole"
      :title="roleTitle !== '' ? roleTitle : title"
      :source="source"
      :use-company="useCompany"
      :role="role"
      :visibility="reset"
      :allow-multiple="allowMultiple"
      @connect="connectContacts"
      @close="contactDialog = false"
    />
  </div>
</template>

<script>
import ConnectContact from '@/components/common-components/connectContact/СonnectContact'
import ContactTile from '@/components/contacts/ContactTile.vue';

export default {
  name: 'RoleContactsCard',

  components: {
    ContactTile,
    ConnectContact,
  },

  props: {
    contacts: {
      type: Array,
      default: () => [],
    },
    role: {
      type: String,
      default: '',
    },
    roleTitle: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    allowSave: {
      type: Boolean,
      default: true,
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    contactTypeLabel: {
      type: String,
      default: 'Contact',
    },
    queryByRole: {
      type: String,
      default: '',
    },
    source: {
      type: String,
      default: '',
    },
    maxCount: {
      type: Number,
      default: -1,
    },
    useCompany: Boolean,
    buttonStyle: {
      type: String,
      default: 'block',
    },
    allowMultiple: {
      type: Boolean,
      default: true,
    },
    isDense: Boolean,
    displayLock: {
      type: Boolean,
      default: true
    },
    refreshContactEdge: {
      type: String,
      default: null,
    }
  },

  data: () => ({
    edit: false,
    contactDialog: false,
    companyDialog: false,
    selectedContacts: [],
    reset: 0,
  }),

  computed: {
    title () {
      return this.source === 'requirement' ? 'Connect Procuring Broker' : 'Add contact';
    },
    showAdd () {
      return (
        this.allowAdd && (this.maxCount === -1 ||
        (this.contacts !== null && this.maxCount > this.contacts?.length))
      )
    },
    showAddBtn () {
      const shouldShowAdd = (this.edit || !this.allowSave) && this.showAdd;
      const canAddNewContact = !this.contacts?.length || this.allowMultiple;

      return shouldShowAdd || canAddNewContact;
    }
  },

  methods: {
    connectContacts (contacts) {
      const addContacts = contacts.map((contact) => contact._id)
      const fullContacts = contacts.map((contact) => contact)
      const payload = {
        _id: this._id,
        role: this.role,
        contacts: addContacts,
        contactDetails: fullContacts,
        action: 'addContacts',
      }
      this.$emit('updateHandler', payload)
      this.contactDialog = false
    },
    removeCompany (company) {
      const payload = {
        _id: this._id,
        role: this.role,
        contact: company._id,
        action: 'remove',
      }

      this.$emit('removeHandler', payload)
    },
    removeContact (contactKey) {
      const payload = {
        contact: this.contacts.find((contact) => contact._key === contactKey),
        role: this.role
      }

      this.$emit('removeHandler', payload)
    },
    openContactSelect () {
      this.reset++
      this.contactDialog = true
    },
    updateReferralHandler (value) {
      const payload = {
        _edge: value._edge,
        id: value.contactId,
        referring_agent: (value.referral === 'referring'),
        receiving_agent: (value.referral === 'receiving')
      }
      this.$emit('updateReferral', payload)
    }
  },
}
</script>
