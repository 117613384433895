<template>
  <div v-if="isLoaded" id="contact-form">
    <v-toolbar
      flat
      dark
      color="primary"
      class="panel-header"
    >
      <v-toolbar-title>{{ toolbarLabel }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon dark @click="cancelContact">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <ContactAddStepper
      v-if="!edit"
      :source="source"
      :source-type="sourceType"
      :source-id="sourceId"
      :default-role="defaultRole"
      :has-roles="hasRoles"
      @contactAdded="addedHandler"
      @cancelContact="cancelContact"
    />
    <ContactEditForm
      v-else-if="edit && contact._key"
      :contact="contact"
      :source="source"
      :source-type="sourceType"
      :edit="editContact"
      @cancelContact="cancelContact"
      @inactivateContact="gotoContacts"
      @updateContact="updateContact"
    />
  </div>
</template>

<script>
import ContactEditForm from './ContactEditForm'
import ContactAddStepper from './ContactAddStepper'

export default {
  name: 'ContactForm',

  components: {
    ContactEditForm,
    ContactAddStepper,
  },

  props: {
    contact: {
      type: Object,
      default: () => ({})
    },
    edit: Boolean,
    source: {
      type: Object,
      default: () => ({})
    },
    sourceType: {
      type: String,
      default: ''
    },
    sourceId: {
      type: [String, Number],
      default: 0
    },
    editContact: Boolean,
    isBrokerage: Boolean,
    hasRoles: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    companiesLoaded: false,
    isLoaded: false,
    phoneMask: '(###) ###-####',
    phone: {
      direct: '',
      mobile: '',
      office: '',
      fax: '',
    },
  }),

  computed: {
    toolbarLabel () {
      if (this.sourceType === 'company') {
        return 'Add contact to company'
      } else if (this.sourceType === 'contact' && this.edit) {
        return 'Edit Contact'
      } else {
        return 'Add Contact'
      }
    },
    defaultRole () {
      return this.isBrokerage ? 'Agent' : 'Employee'
    },
  },

  mounted () {
    this.isLoaded = this.contact !== null;
  },

  methods: {
    updateContact (contact) {
      this.$emit('updateContact', contact)
    },
    cancelContact () {
      this.$emit('closeDialog')
    },
    addedHandler (contact) {
      this.$emit('updateContacts')
      this.$emit('added', contact)
    },
    gotoContacts () {
      this.$emit('gotoContacts')
    },
  },
}
</script>

<style scoped>
.contact-photo {
  width: 60px;
  height: 60px;
}
</style>
