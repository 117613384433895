<template>
  <DashboardTaskSkeleton :loading="isLoading">
    <v-card outlined>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-icon left @click="displayTasks = !displayTasks">
            mdi-chevron-{{ displayTasks ? 'up' : 'down' }}
          </v-icon>
          <span>{{ title }} ({{ totalRows }})</span>
        </v-toolbar-title>
        <v-spacer />
        <template v-if="$vuetify.breakpoint.lgAndUp">
          <template v-if="selected.length">
            <v-btn icon :loading="archivingLoading" @click="showConfirmationDialog = true">
              <v-icon title="Remove Link">
                mdi-trash-can
              </v-icon>
            </v-btn>
            <v-btn
              color="green"
              class="mr-2"
              style="color: #FFFFFF !important;"
              :loading="markCompleteLoading"
              @click="updateTasksStatusHandler(taskStatusEnum.completed)"
            >
              <v-icon left>mdi-check</v-icon> Mark Complete
            </v-btn>
            <v-btn
              color="primary"
              class="mr-2"
              style="color: #FFFFFF !important;"
              :loading="markCompleteLoading"
              @click="updateTasksStatusHandler(taskStatusEnum.pending)"
            >
              <v-icon left>mdi-clock</v-icon> Mark Pending
            </v-btn>
          </template>
          <v-spacer />
          <v-btn-toggle v-model="completedFilter" mandatory class="filter-button-group">
            <v-btn :value="FilterAllExcludeFilterEnum.exclude">{{ FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.exclude] }}</v-btn>
            <v-btn :value="FilterAllExcludeFilterEnum.all">{{ FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.all] }}</v-btn>
            <v-btn :value="FilterAllExcludeFilterEnum.filter">{{ FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.filter] }}</v-btn>
          </v-btn-toggle>
          <v-divider vertical class="ml-3 mr-3" />
          <v-checkbox
            v-model="showBeyond"
            class="mt-0"
            hide-details
            label="Beyond 7 days?"
          />
        </template>
        <template v-else>
          <template v-if="selected.length">
            <v-btn
              class="mr-2"
              color="green"
              :loading="markCompleteLoading"
              @click="updateTasksStatusHandler(taskStatusEnum.completed)"
            >
              <v-icon color="white">mdi-check</v-icon>
            </v-btn>
            <v-btn color="primary" :loading="markCompleteLoading" @click="updateTasksStatusHandler(taskStatusEnum.pending)">
              <v-icon color="white">mdi-clock</v-icon>
            </v-btn>
          </template>
          <v-menu
            v-model="menu"
            bottom
            left
            :close-on-content-click="false"
          >
            <template #activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>mdi-filter-variant</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-btn-toggle v-model="completedFilter" mandatory class="filter-button-group">
                      <v-btn :value="FilterAllExcludeFilterEnum.exclude">{{ FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.exclude] }}</v-btn>
                      <v-btn :value="FilterAllExcludeFilterEnum.all">{{ FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.all] }}</v-btn>
                      <v-btn :value="FilterAllExcludeFilterEnum.filter">{{ FilterAllExcludeFilterLabels[FilterAllExcludeFilterEnum.filter] }}</v-btn>
                    </v-btn-toggle>
                  </v-list-item-action>
                  <v-list-item-title>Show completed tasks</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox
                      v-model="showBeyond"
                      class="mt-0"
                      hide-details
                    />
                  </v-list-item-action>
                  <v-list-item-title>Show beyond 7 days</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="menu = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </template>
      </v-toolbar>
      <div v-show="displayTasks" id="common-data-table">
        <TasksTable
          v-show="tasks.length"
          :tasks="tasks"
          :is-table-loading="isTableLoading"
          :selected="selected"
          :collection-type="collectionType"
          :total-rows="totalRows"
          :associated-records="associatedRecords"
          @fetch:tasks="getTasks"
          @select:tasks="selectTasksHandler"
          @update:task="updateHandler"
        />
        <RecordTaskCard
          v-if="collectionId"
          :tasks-length="tasks.length"
          :associated-records="associatedRecords"
        />
      </div>
    </v-card>
    <ConfirmationDialog
      v-model="showConfirmationDialog"
      @cancel="showConfirmationDialog = false"
      @submit="archiveTasksHandler"
    >
      Are you sure you want to archive selected tasks?
    </ConfirmationDialog>
  </DashboardTaskSkeleton>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { intersectionWith } from 'lodash';
import { TaskPrivacyEnum } from '@/bundles/Tasks/enums/TaskPrivacyEnum';
import { taskService } from '@/bundles/Tasks/factories/taskServiceFactory';
import { TaskStatusEnum } from '@/bundles/Tasks/enums/TaskStatusEnum';
import { NotificationMutations } from '@/store/types/mutation-types';
import { getDefaultTaskPagination } from '@/bundles/Tasks/helpers/defaultPagination';
import { FilterAllExcludeFilterEnum, FilterAllExcludeFilterLabels } from '@/bundles/Filters/enums/transactionType';
import { getTaskStatusFilter } from '@/bundles/Tasks/helpers/helpers';

import DashboardTaskSkeleton from '@/bundles/Skeletons/components/DashboardTaskSkeleton';
import RecordTaskCard from '@/bundles/Tasks/components/RecordTaskCard';
import TasksTable from '@/bundles/Tasks/components/table/TasksTable';
import ConfirmationDialog from '@/bundles/Common/components/ConfirmationDialog.vue';

export default {
  name: 'TasksCard',

  components: {
    TasksTable,
    RecordTaskCard,
    ConfirmationDialog,
    DashboardTaskSkeleton,
  },

  props: {
    taskPrivacy: {
      type: String,
      default: TaskPrivacyEnum.me,
    },
    collectionId: {
      type: String,
      default: null
    },
    collectionType: {
      type: String,
      default: null
    },
    associatedRecords: {
      type: Array,
      default: () => []
    },
    hideAssociatedRecordsCard: Boolean,
    marketing: Boolean
  },

  data: () => ({
    isLoading: false,
    tasks: [],
    menu: false,
    selected: [],
    completedFilter: FilterAllExcludeFilterEnum.exclude,
    showBeyond: true,
    displayTasks: false,
    markCompleteLoading: false,
    totalRows: 0,
    isTableLoading: false,
    taskStatusEnum: TaskStatusEnum,
    archivingLoading: false,
    showConfirmationDialog: false
  }),

  computed: {
    ...mapState('Task', {
      taskItemsPerPage: state => state.tableOptions.itemsPerPage,
    }),
    title () {
      const taskTitle = {
        [TaskPrivacyEnum.me]: 'My Tasks',
        [TaskPrivacyEnum.by_me]: 'My Assignments',
        [TaskPrivacyEnum.my_team]: `My Team's Tasks`,
      }

      return taskTitle[this.taskPrivacy];
    },
  },

  watch: {
    totalRows: {
      handler (value) {
        this.$emit('update:count', value);
      },
      immediate: true,
    },
    isLoading (value) {
      this.$emit('update:loading', value);
    },
    completedFilter: function () {
      this.getTasks();
    },
    showBeyond: function () {
      this.getTasks();
    }
  },

  created () {
    this.FilterAllExcludeFilterEnum = FilterAllExcludeFilterEnum;
    this.FilterAllExcludeFilterLabels = FilterAllExcludeFilterLabels;
  },

  mounted () {
    if (this.taskPrivacy === TaskPrivacyEnum.me) {
      this.displayTasks = true;
    }

    if (this.collectionId) {
      this.showBeyond = true;
    }

    this.getTasks({ isPaginationChanged: false });

    this.$eventBus.$on('fetch:tasks', this.getTasks);
  },

  beforeDestroy () {
    this.$eventBus.$off('fetch:tasks', this.getTasks);
  },

  methods: {
    ...mapMutations('Notifications', {
      addNotification: NotificationMutations.ADD_NOTIFICATION,
    }),
    async updateTasksStatusHandler (status) {
      try {
        this.markCompleteLoading = true;

        await taskService.updateTasksStatus({
          ids: this.selected,
          status,
        });

        this.selected = [];
        await this.getTasks();
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this.markCompleteLoading = false;
      }
    },
    updateHandler (task) {
      this.tasks = this.tasks.map((item) => {
        if (item._key === task._key) {
          return task;
        }

        return item;
      });
    },
    async getTasks ({ isPaginationChanged = false, pagination = null } = {}) {
      try {
        this[isPaginationChanged ? 'isTableLoading' : 'isLoading'] = true;

        if (!pagination) {
          pagination = getDefaultTaskPagination();
        }

        const statusFilter = getTaskStatusFilter(this.completedFilter);

        let params = [
          { name: 'assignee_privacy', value: this.taskPrivacy },
          { name: 'take', value: this.taskItemsPerPage },
          { name: 'sort', value: pagination.sortBy },
          { name: 'descending', value: pagination.descending },
          { name: 'skip', value: (pagination.page - 1) * this.taskItemsPerPage },
          { name: 'status', value: statusFilter },
        ];

        if (this.marketing) {
          params.push({ name: 'marketing', value: true });
        }

        if (this.showBeyond) {
          params.push({ name: 'beyond_week', value: true });
        }

        if (this.collectionId) {
          params.push({ name: 'collection', value: this.collectionId });
        }

        const { data, total } = await taskService.getMyTasks(params);

        this.tasks = [...data];
        this.totalRows = total;
      } catch (error) {
        this.addNotification({ ...error });
      } finally {
        this[isPaginationChanged ? 'isTableLoading' : 'isLoading'] = false;
      }
    },
    selectTasksHandler (taskIds) {
      this.selected = [...taskIds];
    },
    async archiveTasksHandler () {
      try {
        if (!this.selected.length) {
          return;
        }
        this.showConfirmationDialog = false;
        this.archivingLoading = true;

        await taskService.archive({ keys: this.selected });

        const archivedTasks = intersectionWith(this.tasks, this.selected, (task, key) => task._key === key);
        const tasksText = this.$tc(this.LocalizationEnum.task, archivedTasks.length);
        const wasText = this.$tc(this.LocalizationEnum.was, archivedTasks.length);

        this.addNotification({
          title: 'Success',
          status: 200,
          message: `
            ${tasksText} ${archivedTasks.map(task => task.title).join(', ')} ${wasText} archived.
          `,
        });
        this.getTasks();
        this.selected = [];
      } catch (error) {
        this.addNotification(error);
      } finally {
        this.archivingLoading = false;
      }
    }
  },
};
</script>
