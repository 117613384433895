<template>
  <div class="import-from-vcard">
    <Upload
      v-model="uploadDialog"
      :options="uploadOptions"
      skip-upload
      @fileUploaded="uploadSuccess"
    >
      <v-btn @click="uploadDialog = true">{{ btnName }}</v-btn>
    </Upload>
    <v-dialog v-model="importVCardDialog" max-width="900" scrollable>
      <VCardImportDialog
        v-if="importVCardDialog"
        :uploaded-file="uploadedFile"
        @close="closeHandler"
        @save="saveHandler"
      />
    </v-dialog>
  </div>
</template>

<script>
import Upload from '@/bundles/Upload/components/Upload';
import VCardImportDialog from '@/bundles/Contact/components/vcard/VCardImportDialog';

export default {
  name: 'ImportFromVcard',

  components: {
    VCardImportDialog,
    Upload,
  },

  props: {
    btnName: {
      type: String,
      default: 'Import from VCard',
    },
  },

  data: () => ({
    uploadDialog: false,
    uploadedFile: null,
    importVCardDialog: false,
  }),

  computed: {
    uploadOptions () {
      return { accept: '*' };
    }
  },

  methods: {
    uploadSuccess ({ originalFiles }) {
      if (
        originalFiles[0].type.match(/text.vcard/) ||
        originalFiles[0].name.indexOf('.vcf') === originalFiles[0].name.length - '.vcf'.length
      ) {
        this.uploadedFile = originalFiles[0];
        this.importVCardDialog = true;
      } else {
        alert('File not supported')
      }
    },
    saveHandler (parsedData) {
      this.$emit('parsingFinished', parsedData);
      this.importVCardDialog = false;
    },
    closeHandler () {
      this.uploadedFile = null;
      this.importVCardDialog = false;
    },
  },
}
</script>
